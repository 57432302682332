import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import dropshipTime from '../../../queries/dropshipTime.graphql';
import convertToWeeks from '../../../util/convertToWeeks';

interface DropshipTimeProps {
    sku: string;
    hideNoStockInfo?: boolean;
}

const DropshipTime = (props: DropshipTimeProps) => {
    const { sku, hideNoStockInfo } = props;

    const [getDropShipTime, { loading, data, error }] = useLazyQuery(
        dropshipTime,
        {
            variables: { sku: sku }
        }
    );

    useEffect(() => {
        getDropShipTime({ variables: { sku: sku } });
    }, [sku]);

    if (error) {
        return <span />;
    }

    if (loading) {
        return <span />;
    }

    const renderTime = () => {
        if (Number(data.dropshipTime.deliverytime) > 28) {
            return 'Mehr als 4 Wochen';
        } else if (Number(data.dropshipTime.deliverytime) < 8) {
            return `Auf Lager - ${data.dropshipTime.deliverytime} Werktage`;
        } else {
            return `Auf Lager - Innerhalb ${convertToWeeks(
                data.dropshipTime.deliverytime
            )}`;
        }
    };

    const stock = data ? data.dropshipTime.stock : null;
    const defaultDeliveryTime = data ? data.dropshipTime.deliverytime : null;

    return stock && stock >= 3 ? (
        <span>
            <span className="text-skin-green">Sofort Verfügbar</span>
        </span>
    ) : stock && stock < 3 ? (
        <span>
            <span className="text-skin-orange">Wenige verfügbar</span>
        </span>
    ) : !stock && defaultDeliveryTime && !hideNoStockInfo ? (
        <span>
            <span className="text-skin-green">{renderTime()}</span>
        </span>
    ) : null;
};

export default DropshipTime;
