import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../../store/store';
import { CookiesProvider } from 'react-cookie';
import SessionMonitoring from '../SessionMonitoring';
import SideCart from '../SideCart';
import { useQuery } from '@apollo/client';
import APP_QUERY from '~/queries/App.graphql';
import NextNprogress from 'nextjs-progressbar';
import Head from 'next/head';
import Header from '../Header/Header';
import SearchBarMobile from '../Header/SearchBarMobile';
import Footer from '../Footer';
import NewsletterFooterBlock from '../NewsletterFooterBlock/NewsletterFooterBlock';
import { useRouter } from 'next/router';
import GetCartDetails from './GetCartDetails';
import HeaderList from '../HeaderList';
import CmsBlock from '../CmsBlock';
import CookiesSetState from '../CookiesSetState';
// import RenderFacebookPixel from './RenderFacebookPixel';
import { globalSiteMessageTemplate } from '../LoadingContent/loadingTemplates';
import getBuildId from '../../lib/getBuildId';
import { copyCode } from '../../util/copyCode';
import { isIOS } from 'react-device-detect';
import KitchenTesterHeader from '../Header/KitchenTesterHeader';
import KitchenTesterFooter from '../Footer/KitchenTesterFooter';
interface AppProps {
    children: JSX.Element;
}

export const App = ({ children }: AppProps) => {
    const { data, loading } = useQuery(APP_QUERY);
    const router = useRouter();
    const storeConfig = data?.storeConfig;
    const isKitchenTesterPage = router?.pathname.startsWith('/kuechentester');

    const categoryUrlSuffix = storeConfig?.category_url_suffix ?? '';

    const categories = data?.categoryList[0].children;

    const [mobileSearch, setMobileSearch] = useState(false);
    const [buildId, setBuildId] = useState('');
    const toggleMobileSearch = () => {
        setMobileSearch(!mobileSearch);
    };

    // Refresh if there is a new deployment and the build ID is new
    useEffect(() => {
        const savedBuildId = localStorage.getItem('buildId')
            ? localStorage.getItem('buildId')
            : null;

        !buildId
            ? getBuildId().then((res: any) => {
                  setBuildId(res?.data?.buildId ? res.data.buildId : null);
              })
            : null;

        if (!savedBuildId) {
            localStorage.setItem('buildId', buildId);
        }

        if (buildId && savedBuildId && buildId !== savedBuildId) {
            localStorage.setItem('buildId', buildId);
            router.reload();
        }
    }, [buildId]);

    //Global message copy functionality
    if (typeof window !== 'undefined') {
        const copyText = document.getElementById('code') as HTMLButtonElement;
        const copyText2 = document.getElementById('code2') as HTMLButtonElement;
        const copyText3 = document.getElementById('code3') as HTMLButtonElement;

        copyCode(copyText, 'promoCode');
        copyCode(copyText2, 'promoCode2');
        copyCode(copyText3, 'promoCode3');
    }
    //END message copy functionality

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <React.Fragment>
                    <CookiesProvider>
                        <div className={`${isIOS ? 'ios' : ''}`}>
                            <SideCart />
                            <div className="bg-skin-site-bg min-h-screen flex flex-col justify-between ">
                                <Head>
                                    <title>{storeConfig?.default_title}</title>
                                    {storeConfig?.default_description ? (
                                        <meta
                                            name="description"
                                            content={
                                                storeConfig?.default_description
                                            }
                                        ></meta>
                                    ) : null}
                                </Head>
                                <NextNprogress
                                    startPosition={0.4}
                                    stopDelayMs={200}
                                    height={3}
                                    options={{
                                        showSpinner: false,
                                        easing: 'ease'
                                    }}
                                    color="#e30613"
                                />
                                {!isKitchenTesterPage ? (
                                    <div className="hidden lg:block">
                                        <HeaderList />
                                    </div>
                                ) : null}
                                {!isKitchenTesterPage ? (
                                    <Header
                                        categories={categories}
                                        loadingNavi={loading}
                                        categoryUrlSuffix={categoryUrlSuffix}
                                        storeConfig={storeConfig}
                                        toggleMobileSearch={toggleMobileSearch}
                                    />
                                ) : (
                                    <KitchenTesterHeader />
                                )}

                                {mobileSearch && (
                                    <SearchBarMobile
                                        toggleMobileSearch={toggleMobileSearch}
                                    />
                                )}

                                <div className="flex-1 flex items-stretch flex-col">
                                    {!isKitchenTesterPage ? (
                                        <CmsBlock
                                            identifiers={[
                                                'global_site_message'
                                            ]}
                                            loadingTemplate={
                                                globalSiteMessageTemplate
                                            }
                                        />
                                    ) : null}
                                    {children}
                                </div>

                                {!router.pathname.includes('account') &&
                                !router.asPath.includes('/newsletter') &&
                                !isKitchenTesterPage ? (
                                    <NewsletterFooterBlock />
                                ) : null}
                                {/* {!isKitchenTesterPage ? (
                                    <CmsBlock
                                        identifiers={['exception-block-oms']}
                                    />
                                ) : null} */}
                                {!isKitchenTesterPage ? (
                                    <Footer />
                                ) : (
                                    <KitchenTesterFooter />
                                )}
                            </div>
                            <SessionMonitoring />
                            <GetCartDetails />
                            <CookiesSetState />
                            {/* <RenderFacebookPixel router={router} /> */}
                        </div>
                    </CookiesProvider>
                </React.Fragment>
            </PersistGate>
        </Provider>
    );
};
