import React from 'react';
import { connect } from 'react-redux';
import isJsonString from '../../../../util/checkStringIsJsonValid';

interface PickupTimePlain {
    deliveryTime: number;
    selectedQuantity: number;
    productState?: string;
    productMhsStock: string;
    pickupInfo: any;
    deliveryType: number;
    selectedStore?: string;
}

const PickupTimePlain = (props: PickupTimePlain) => {
    const {
        deliveryTime,
        selectedQuantity,
        productState,
        productMhsStock,
        pickupInfo,
        deliveryType,
        selectedStore
    } = props;

    const isSpedition =
        deliveryType == 28227 || deliveryType == 28228 ? true : false;

    const isJson = isJsonString(productMhsStock);
    const handledMhsStorePacket =
        productMhsStock && isJson
            ? JSON.parse(productMhsStock).store_stock
            : null;

    const selectedMhsInfoPickup = handledMhsStorePacket[pickupInfo]
        ? handledMhsStorePacket[pickupInfo]
        : null;

    const pickupStock =
        selectedMhsInfoPickup && selectedMhsInfoPickup.id
            ? selectedMhsInfoPickup.stock
            : 0;

    const convertToWeeks = (days: number) => {
        if (days < 7) {
            return Math.ceil(days) + ' Tage';
        } else {
            return Math.ceil(days / 7) + ' Wochen';
        }
    };

    const renderTime = (time: number) => {
        if (time > 28) {
            return 'Mehr als 4 Wochen';
        } else {
            return `Lieferbar in ${convertToWeeks(time)}`;
        }
    };

    const pickupTime = () => {
        if (isSpedition) {
            if (pickupStock >= selectedQuantity) {
                return (
                    <span className="text-skin-green font-semibold">
                        Sofort Abholbereit
                    </span>
                );
            } else {
                return (
                    <span
                        className={
                            Number(deliveryTime) > 14
                                ? 'text-skin-orange font-semibold'
                                : 'text-skin-green font-semibold'
                        }
                    >
                        {renderTime(Number(deliveryTime))}
                    </span>
                );
            }
        } else {
            if (pickupStock >= selectedQuantity) {
                return (
                    <span className="text-skin-green font-semibold">
                        Sofort Abholbereit
                    </span>
                );
            } else {
                return deliveryTime ? (
                    <span
                        className={
                            Number(deliveryTime) > 14
                                ? 'text-skin-orange font-semibold'
                                : 'text-skin-green font-semibold'
                        }
                    >
                        {renderTime(Number(deliveryTime))}
                    </span>
                ) : (
                    <span
                        className={
                            Number(deliveryTime) > 14
                                ? 'text-skin-orange font-semibold'
                                : 'text-skin-green font-semibold'
                        }
                    >
                        {'Abholbereit in ' + convertToWeeks(20)}
                    </span>
                );
            }
        }
    };

    return (
        <div>
            {selectedStore ? (
                <div>
                    {productState == 'I' ? (
                        // Product is only available ONLINE
                        <div className="flex-row text-center items-center justify-start flex-wrap">
                            <span className="text-skin-orange font-semibold">
                                Dieser Artikel ist nur Online bestellbar.
                            </span>
                        </div>
                    ) : (
                        <div>
                            <div>
                                {(productState == '4' ||
                                    productState == '8' ||
                                    productState == 'L' ||
                                    productState == '9' ||
                                    productState == 'S') &&
                                pickupStock <= 0
                                    ? null
                                    : pickupTime()}
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = ({ pickupAndDelivery }: { pickupAndDelivery: any }) => {
    return {
        deliveryInfo: pickupAndDelivery.deliveryInfo,
        pickupInfo: pickupAndDelivery.pickupInfo
    };
};

export default connect(mapStateToProps)(PickupTimePlain);
