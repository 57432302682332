import Link from 'next/link';
import React from 'react';

const KitchenTesterFooter = () => {
    const date = new Date();
    const currentYear = date.getFullYear();

    return (
        <footer className="body-font mt-8">
            <div className="py-2 lg:py-6 bg-skin-red-accent text-white">
                <div className="flex flex-col-reverse items-center justify-center content-container w-full h-full sm:flex-row sm:justify-between">
                    <span className="text-xs sm:mt-0 sm:text-sm">
                        © {currentYear} Opti-Wohnwelt Föst GmbH & Co. KG
                    </span>
                    <nav className="list-none">
                        <ul className="styled-list-secondary flex justify-content items-center no-icons">
                            <li>
                                <Link href="/impressum">
                                    <a className="text-white hover:text-white">
                                        Impressum
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/datenschutz">
                                    <a className="text-white hover:text-white">
                                        Datenschutz
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </footer>
    );
};

export default KitchenTesterFooter;
