import React, { useState } from 'react';
import KlarnaIcon from '../../images/payment_klarna.svg';
import PPIcon from '../../images/payment_pp.svg';
// import DPDIcon from '../../images/dpd.svg';
import DHLIcon from '../../images/dhl.svg';
import FBIcon from '../../images/facebook.svg';
import InstagramIcon from '../../images/instagram.svg';
import PinterestIcon from '../../images/pinterest.svg';
import YTIcon from '../../images/youtube.svg';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline';
import CmsBlock from '../CmsBlock/cmsBlock';
import OptiTeam from '../../images/versand_optiteam.png';
import Image from 'next/image';
import AwardImage2 from './img/award_2.jpg';
import AwardImage4 from './img/award_4.jpg';
import AwardImage5 from './img/award_5.jpg';
import AwardImage6 from './img/award_6.jpg';
import Link from 'next/link';

const Footer = () => {
    const [isOpen, setIsOpen] = useState('services');

    const date = new Date();
    const currentYear = date.getFullYear();

    return (
        <footer className="body-font">
            {/* Desktop */}
            <div className="hidden content-container pt-12 mx-auto lg:flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                <div className="flex-grow flex flex-wrap md:text-left text-center justify-between">
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2>Services</h2>
                        <CmsBlock identifiers={['footer_services_links']} />
                    </div>

                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2>Topkategorien</h2>

                        <CmsBlock identifiers={['footer_categories_links']} />
                    </div>

                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <div className="mb-8">
                            <h2>Unternehmen</h2>

                            <CmsBlock
                                identifiers={['footer_links_unternehmen']}
                            />
                        </div>

                        <div>
                            <h2>Karriere</h2>
                            <nav className="list-none">
                                <ul className="styled-list-secondary">
                                    <li>
                                        <Link href="/ausbildung">
                                            Ausbildung
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/stellenangebote">
                                            Stellenangebote
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2>Kontakt</h2>
                        <CmsBlock identifiers={['footer_links_kontakt']} />
                    </div>
                    <div className="w-full h-[2px] my-6 bg-skin-primary-border" />
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2>Versand</h2>
                        <div className="flex flex-start justify-start flex-wrap items-center">
                            {/* <DPDIcon className="w-14 mb-4 mr-4" /> */}
                            <DHLIcon className="w-14 h-10 p-1 mr-2 mb-2 border border-solid border-skin-primary" />
                            <div className="relative w-14 h-10 mb-2 border border-solid border-skin-primary">
                                <Image
                                    src={OptiTeam}
                                    layout="fill"
                                    alt="Opti Team"
                                    className="!p-1"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 className="hyphensAuto">Zahlungsmöglichkeiten</h2>
                        <div className="flex flex-start justify-start flex-wrap items-center">
                            <KlarnaIcon
                                className={
                                    'w-14 h-10 p-1 mb-2 mr-2 border border-solid border-skin-primary'
                                }
                            />
                            <PPIcon
                                className={
                                    'w-14 h-10 p-1 mb-2 mr-2 border border-solid border-skin-primary'
                                }
                            />
                            <span className="flex items-center justify-center w-14 h-10 p-1 mr-2 mb-2 text-[10px] font-bold border border-solid border-skin-primary">
                                Vorkasse
                            </span>
                        </div>
                    </div>
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 className="">Auszeichnungen</h2>

                        <div className="flex space-x-2">
                            <Link href="https://www.opti-wohnwelt.de/auszeichnungen">
                                <a>
                                    <div>
                                        <Image
                                            src={AwardImage2}
                                            height={280}
                                            width={210}
                                        />
                                    </div>
                                </a>
                            </Link>

                            <Link href="https://www.opti-wohnwelt.de/auszeichnungen">
                                <a>
                                    <div>
                                        <Image
                                            src={AwardImage4}
                                            height={280}
                                            width={210}
                                        />
                                    </div>
                                </a>
                            </Link>
                            <Link
                                href="https://www.ekomi.de/bewertungen-opti-wohnweltde.html"
                                passHref
                            >
                                <a target={'_blank'}>
                                    <div>
                                        <Image
                                            src={AwardImage5}
                                            height={280}
                                            width={210}
                                        />
                                    </div>
                                </a>
                            </Link>
                            <Link href="https://www.opti-wohnwelt.de/auszeichnungen">
                                <a>
                                    <div>
                                        <Image
                                            src={AwardImage6}
                                            height={280}
                                            width={210}
                                        />
                                    </div>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="block lg:hidden container-block py-12">
                <div>
                    <Disclosure
                        as="div"
                        key={'footer_services'}
                        onClick={() =>
                            isOpen == 'services'
                                ? setIsOpen('')
                                : setIsOpen('services')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Services{' '}
                                    {isOpen == 'services' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'services' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <CmsBlock
                                        identifiers={['footer_services_links']}
                                    />
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_topkategorien'}
                        onClick={() =>
                            isOpen == 'topkategorien'
                                ? setIsOpen('')
                                : setIsOpen('topkategorien')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Topkategorien{' '}
                                    {isOpen == 'topkategorien' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'topkategorien' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <CmsBlock
                                        identifiers={[
                                            'footer_categories_links'
                                        ]}
                                    />
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_karriere'}
                        onClick={() =>
                            isOpen == 'karriere'
                                ? setIsOpen('')
                                : setIsOpen('karriere')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Karriere{' '}
                                    {isOpen == 'karriere' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'karriere' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <nav className="list-none">
                                        <ul className="styled-list-secondary">
                                            <li>
                                                <Link href="/ausbildung">
                                                    Ausbildung
                                                </Link>
                                            </li>
                                            <li>
                                                <Link href="/stellenangebote">
                                                    Stellenangebote
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_unternehmen'}
                        onClick={() =>
                            isOpen == 'unternehmen'
                                ? setIsOpen('')
                                : setIsOpen('unternehmen')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Unternehmen{' '}
                                    {isOpen == 'unternehmen' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'unternehmen' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <CmsBlock
                                        identifiers={[
                                            'footer_links_unternehmen'
                                        ]}
                                    />
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_kontakt'}
                        onClick={() =>
                            isOpen == 'kontakt'
                                ? setIsOpen('')
                                : setIsOpen('kontakt')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Kontakt{' '}
                                    {isOpen == 'kontakt' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'kontakt' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <CmsBlock
                                        identifiers={['footer_links_kontakt']}
                                    />
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_versand'}
                        onClick={() =>
                            isOpen == 'versand'
                                ? setIsOpen('')
                                : setIsOpen('versand')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Versand{' '}
                                    {isOpen == 'versand' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'versand' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <div className="flex flex-start justify-start flex-wrap items-center">
                                        <DHLIcon className="w-14 mb-4 mr-4" />
                                        <div className="relative w-14 h-10 mb-4">
                                            <Image
                                                src={OptiTeam}
                                                layout="fill"
                                                alt="Opti Team"
                                            />
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_zahlungsmöglichkeiten'}
                        onClick={() =>
                            isOpen == 'zahlungsmöglichkeiten'
                                ? setIsOpen('')
                                : setIsOpen('zahlungsmöglichkeiten')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Zahlungsmöglichkeiten{' '}
                                    {isOpen == 'zahlungsmöglichkeiten' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'zahlungsmöglichkeiten' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <div className="flex flex-start justify-start flex-wrap items-center">
                                        <div className="flex flex-start justify-start flex-wrap items-center">
                                            <KlarnaIcon
                                                className={'w-14 mb-4 mr-4'}
                                            />
                                            <PPIcon
                                                className={'w-14 mb-4 mr-4'}
                                            />
                                            <span className="flex justify-center mt-1 font-bold mr-4 mb-4">
                                                Vorkasse
                                            </span>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_auszeichnungen'}
                        onClick={() =>
                            isOpen == 'auszeichnungen'
                                ? setIsOpen('')
                                : setIsOpen('auszeichnungen')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Auszeichnungen{' '}
                                    {isOpen == 'auszeichnungen' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'auszeichnungen' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <div className="flex flex-wrap space-x-2 justify-center m-auto">
                                        <div>
                                            <Link href="https://www.opti-wohnwelt.de/auszeichnungen">
                                                <Image
                                                    height={'100%'}
                                                    width={'100%'}
                                                    src={AwardImage2}
                                                    objectFit="contain"
                                                />
                                            </Link>
                                        </div>

                                        <div>
                                            <Link href="https://www.opti-wohnwelt.de/auszeichnungen">
                                                <Image
                                                    height={'100%'}
                                                    width={'100%'}
                                                    src={AwardImage4}
                                                    objectFit="contain"
                                                />
                                            </Link>
                                        </div>
                                        <div>
                                            <Link
                                                href="https://www.ekomi.de/bewertungen-opti-wohnweltde.html"
                                                passHref
                                            >
                                                <a target={'_blank'}>
                                                    <Image
                                                        height={'100%'}
                                                        width={'100%'}
                                                        src={AwardImage5}
                                                        objectFit="contain"
                                                    />
                                                </a>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link href="https://www.opti-wohnwelt.de/auszeichnungen">
                                                <Image
                                                    height={'100%'}
                                                    width={'100%'}
                                                    src={AwardImage6}
                                                    objectFit="contain"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                </div>
            </div>
            <div className="mb-12">
                <CmsBlock identifiers={['exception-block-oms']} />
            </div>
            <div className="py-2 lg:py-6 bg-skin-red-accent text-white">
                <div className="flex flex-col-reverse items-center justify-center content-container w-full h-full sm:flex-row sm:justify-between">
                    <div>
                        <span className="text-xs mt-4 sm:mt-0 sm:text-sm">
                            © {currentYear} Opti-Wohnwelt Föst GmbH & Co. KG
                        </span>
                        <span className="text-xs block sm:text-sm lg:inline lg:ml-6">
                            *Unverbindliche Preisempfehlung des Herstellers
                        </span>
                    </div>
                    <div className="flex flex-center justify-center items-center px-4">
                        <a
                            aria-label="Facebook"
                            href="https://www.facebook.com/opti.megastore/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FBIcon
                                className={
                                    'w-6 lg:w-8 h-6 lg:h-8 mr-4 cursor-pointer'
                                }
                            />
                        </a>
                        <a
                            aria-label="Instagram"
                            href="https://www.instagram.com/opti.megastore/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <InstagramIcon
                                className={
                                    'w-6 lg:w-8 h-6 lg:h-8 mr-4 cursor-pointer'
                                }
                            />
                        </a>
                        <a
                            aria-label="Pinterest"
                            href="https://www.pinterest.de/optiwohnwelt/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <PinterestIcon
                                className={
                                    'w-6 lg:w-8 h-6 lg:h-8 mr-4 cursor-pointer'
                                }
                            />
                        </a>
                        <a
                            aria-label="Youtube"
                            href="https://www.youtube.com/user/OptiWohnwelt"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <YTIcon
                                className={
                                    'w-6 lg:w-8 h-6 lg:h-8 cursor-pointer'
                                }
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
