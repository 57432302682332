'use client';
import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { usePickUpAndDelivery } from '../../talons/usePickUpAndDelivery';
import AvailableFilialsCard from './availableFilialsCard';
import { getDistance } from 'geolib';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../store/actions/app';

interface AvailableFilials {
    stores: any[];
    gFieldLat?: string | null;
    gFieldLng?: string | null;
    geolocationEnabled?: boolean;
    nearestStore?: number;
    myLatitude?: string;
    myLongitude?: string;
}
interface Store {
    pickup_location_code: string;
    name: string;
    description: string;
    street: string;
    postcode: string;
    city: string;
    distance?: string;
}

const AvailableFilials: React.FC<AvailableFilials> = (props) => {
    const {
        stores,
        gFieldLat,
        gFieldLng,
        geolocationEnabled,
        nearestStore,
        myLatitude,
        myLongitude
    } = props;

    const [selected, setSelected] = useState<Store>();
    const pickupAndDelivery = useSelector(
        (state: any) => state.pickupAndDelivery
    );
    const pickupInfo = pickupAndDelivery.pickupInfo;

    useEffect(() => {
        stores.forEach((store: Store) => {
            if (store.pickup_location_code == pickupInfo) {
                setSelected(store);
            }
        });
    }, []);
    const { addPickUpInfo } = usePickUpAndDelivery();

    const handleSubmit = ({ values }: { values: string }) => {
        if (values != '') {
            addPickUpInfo(values);
        } else {
            return;
        }
    };

    const dispatch = useDispatch();
    const toggleInfobox = (payload: string) => dispatch(toggleDrawer(payload));
    const handleSelected = (selected: Store) => {
        handleSubmit({ values: selected.pickup_location_code });
        setSelected(selected);
        toggleInfobox('availableFilials');
    };

    const handleStores = () => {
        if ((stores && gFieldLat) || (stores && myLatitude)) {
            const handledData: any = [];
            // Here we sort our array
            let sortedArray = null;
            let distance = 0;

            stores.map((currentStore: any) => {
                if (
                    gFieldLat != null &&
                    gFieldLng != null &&
                    nearestStore == 0
                ) {
                    if (gFieldLat != '' && gFieldLng != '') {
                        distance = getDistance(
                            {
                                latitude: gFieldLat,
                                longitude: gFieldLng
                            },
                            {
                                latitude: currentStore.latitude,
                                longitude: currentStore.longitude
                            }
                        );
                        handledData.push({ ...currentStore, distance }); //Here we add the distance to the array
                    }
                } else {
                    if (myLatitude != '' && myLongitude != '') {
                        if (
                            myLatitude != 'err-latitude' &&
                            myLongitude != 'err-longitude'
                        ) {
                            distance = getDistance(
                                //@ts-ignore
                                {
                                    latitude: myLatitude,
                                    longitude: myLongitude
                                },
                                {
                                    latitude: currentStore.latitude.toString(),
                                    longitude: currentStore.longitude.toString()
                                }
                            );
                            handledData.push({ ...currentStore, distance }); //Here we add the distance to the array
                        }
                    }
                }
            });
            const handleStoreName = (name: string) => {
                return name.includes('MegaStore')
                    ? name.replace('MegaStore ', '') + ' MegaStore'
                    : name;
            };

            if (geolocationEnabled) {
                sortedArray =
                    handledData.length == 0
                        ? stores
                        : handledData
                              .slice()
                              .sort((a: any, b: any) =>
                                  a.distance > b.distance ? 1 : -1
                              );
            } else {
                if (gFieldLat == null && gFieldLng == null) {
                    sortedArray = handledData
                        .slice()
                        .sort((a: { name: string }, b: { name: string }) =>
                            handleStoreName(a.name) > handleStoreName(b.name)
                                ? 1
                                : -1
                        );
                } else {
                    sortedArray = handledData
                        .slice()
                        .sort((a: any, b: any) =>
                            a.distance > b.distance ? 1 : -1
                        );
                }
            }

            return sortedArray.map((store: any) => {
                return (
                    <AvailableFilialsCard
                        key={store.name}
                        store={store}
                        geolocationEnabled={geolocationEnabled}
                        gFieldLat={gFieldLat}
                        gFieldLng={gFieldLng}
                        showNearestShop={nearestStore}
                        long={store.longitude}
                        lat={store.latitude}
                    />
                );
            });
        } else {
            return stores.map((store: any) => {
                return (
                    <AvailableFilialsCard
                        key={store.name}
                        store={store}
                        geolocationEnabled={geolocationEnabled}
                        gFieldLat={gFieldLat}
                        gFieldLng={gFieldLng}
                        showNearestShop={nearestStore}
                        long={store.longitude}
                        lat={store.latitude}
                    />
                );
            });
        }
    };

    return (
        <RadioGroup value={selected} by="name" onChange={handleSelected}>
            <RadioGroup.Label className="sr-only">
                Available Filials
            </RadioGroup.Label>
            <div className="-space-y-px bg-white">{handleStores()}</div>
        </RadioGroup>
    );
};

export default AvailableFilials;
