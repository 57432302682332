import { FieldState } from 'informed';
import { useRouter } from 'next/router';
import React from 'react';

interface InputErrorMessageProps {
    children?: JSX.Element;
    fieldState: FieldState<{}>;
}

const Message = (props: InputErrorMessageProps) => {
    const { children, fieldState } = props;
    const { error } = fieldState;
    const errorMessage = error;
    const router = useRouter();
    const url = router.asPath;
    const isCheckoutPage = url?.startsWith('/checkout');

    return (
        <div
            className={
                isCheckoutPage
                    ? 'relative mb-10 lg:mb-8'
                    : 'relative mb-14 lg:mb-10'
            }
        >
            <p
                className={
                    'flex absolute overflow-hidden text-ellipsis items-center font-medium text-left text-red-500 text-xs m-1 ml-0'
                }
            >
                {(errorMessage as React.ReactNode) || children}
            </p>
        </div>
    );
};

export default Message;
