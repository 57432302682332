import Image from 'next/image';
import LoadingContent from '../LoadingContent';

export const sidebarLoadingTemplate = (
    <div className="mt-4 border-t border-skin-primary hidden lg:block ">
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
    </div>
);

export const selectLoadingTemplate = (
    <LoadingContent tailWindCssString="h-[38px] w-full sm:w-1/3 mb-4" />
);

export const prospectsLoadingTemplates = (
    <div className="mt-12">
        <div className="grid grid-cols-12 gap-y-8 sm:gap-16">
            <div className="col-span-12 sm:col-span-6 xl:col-span-3">
                <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                    <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                    <div className="py-[11px]">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={885}
                                width={625}
                                src={
                                    'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                        </LoadingContent>
                    </div>
                    <div className="mt-4 space-y-4">
                        <LoadingContent tailWindCssString="w-full h-[46px]" />
                        <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                    </div>
                </div>
            </div>
            <div className="col-span-12 sm:col-span-6 xl:col-span-3">
                <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                    <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                    <div className="py-[11px]">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={885}
                                width={625}
                                src={
                                    'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                        </LoadingContent>
                    </div>
                    <div className="mt-4 space-y-4">
                        <LoadingContent tailWindCssString="w-full h-[46px]" />
                        <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                    </div>
                </div>
            </div>
            <div className="col-span-12 sm:col-span-6 xl:col-span-3">
                <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                    <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                    <div className="py-[11px]">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={885}
                                width={625}
                                src={
                                    'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                        </LoadingContent>
                    </div>
                    <div className="mt-4 space-y-4">
                        <LoadingContent tailWindCssString="w-full h-[46px]" />
                        <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                    </div>
                </div>
            </div>
            <div className="col-span-12 sm:col-span-6 xl:col-span-3">
                <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                    <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                    <div className="py-[11px]">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={885}
                                width={625}
                                src={
                                    'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                        </LoadingContent>
                    </div>
                    <div className="mt-4 space-y-4">
                        <LoadingContent tailWindCssString="w-full h-[46px]" />
                        <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export const departmentsTemplate = (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 col-span-2">
        <LoadingContent tailWindCssString="w-full h-14" />
        <LoadingContent tailWindCssString="w-full h-14" />
        <LoadingContent tailWindCssString="w-full h-14" />
        <LoadingContent tailWindCssString="w-full h-14" />
        <LoadingContent tailWindCssString="w-full h-14" />
        <LoadingContent tailWindCssString="w-full h-14" />
    </div>
);

export const departmentsSelectTemplate = (
    <div className="my-4">
        <LoadingContent tailWindCssString="w-full h-[38px]" />
    </div>
);

export const categoryLoadingContentTemplate = (
    <>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
    </>
);

export const homepageProductsLoadingTemplate = (
    <>
        <div className="flex -ml-3 sm:-mx-3 overflow-x-hidden">
            <div className="flex-[0_0_auto] w-2/3 px-3 sm:w-1/2 md:flex-auto">
                <LoadingContent tailWindCssString="">
                    <div className="w-full aspect-w-1 aspect-h-1" />
                    <div className="pb-4">
                        <div className="h-[55px] w-full" />
                        <div className="h-[50.25px] w-full" />
                    </div>
                </LoadingContent>
            </div>
            <div className="flex-[0_0_auto] w-2/3 px-3 sm:w-1/2 md:flex-auto">
                <LoadingContent tailWindCssString="">
                    <div className="w-full aspect-w-1 aspect-h-1" />
                    <div className="pb-4">
                        <div className="h-[55px] w-full" />
                        <div className="h-[50.25px] w-full" />
                    </div>
                </LoadingContent>
            </div>
            <div className="hidden px-3 w-2/3 sm:w-1/2 md:block">
                <LoadingContent tailWindCssString="">
                    <div className="w-full aspect-w-1 aspect-h-1" />
                    <div className="pb-4">
                        <div className="h-[55px] w-full" />
                        <div className="h-[50.25px] w-full" />
                    </div>
                </LoadingContent>
            </div>
            <div className="hidden px-3 w-2/3 sm:w-1/2 lg:block">
                <LoadingContent tailWindCssString="">
                    <div className="w-full aspect-w-1 aspect-h-1" />
                    <div className="pb-4">
                        <div className="h-[55px] w-full" />
                        <div className="h-[50.25px] w-full" />
                    </div>
                </LoadingContent>
            </div>
        </div>
        <LoadingContent tailWindCssString="h-[3px] w-full mt-6 md:hidden" />
    </>
);

export const itemInCartLoadingContentTemplate = (
    <>
        <div className="flex flex-wrap justify-start mt-8">
            <LoadingContent tailWindCssString="block w-24 h-24 mr-2" />
            <div className="w-32 mb-8">
                <LoadingContent tailWindCssString="w-full h-4 mb-2" />
                <LoadingContent tailWindCssString="w-1/2 h-4 mb-8" />
                <div className="flex flex-wrap justify-start">
                    <LoadingContent tailWindCssString="w-1/2 h-6 mb-2 mr-2" />
                    <LoadingContent tailWindCssString="w-6 h-6" />
                </div>
            </div>
        </div>
    </>
);

export const wishlistLoadingContentTemplate = () => (
    <>
        <div className="sm:hidden">
            <LoadingContent tailWindCssString="w-full h-80 mb-2" />
            <LoadingContent tailWindCssString="w-full h-80 mb-2" />
            <LoadingContent tailWindCssString="w-full h-80 mb-2" />
            <LoadingContent tailWindCssString="w-full h-80 mb-2" />
        </div>
        <div className="hidden sm:block lg:hidden">
            <LoadingContent tailWindCssString="w-full h-44 mb-2" />
            <LoadingContent tailWindCssString="w-full h-44 mb-2" />
            <LoadingContent tailWindCssString="w-full h-44 mb-2" />
            <LoadingContent tailWindCssString="w-full h-44 mb-2" />
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full h-11 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
        </div>
    </>
);

export const filtersBoxLoadingContentTemplate = (
    <LoadingContent tailWindCssString="bg-gray-200 row-span-3 hidden lg:block h-full w-full" />
);

export const bannerSliderLoadingContentTemplate = (
    aspectRatio?: string,
    additionaClass?: string
) => (
    <LoadingContent
        tailWindCssString={
            'bg-gray-200 row-span-3 block w-full ' + additionaClass
        }
        aspectRatio={aspectRatio}
    />
);

export const browserNavigationBarTemplate = (
    <div className="items-center w-full hidden h-[37.5px] lg:flex">
        <LoadingContent
            tailWindCssString={'h-[22.5px] py-[12px] w-full h-full'}
        />
    </div>
);

export const variantsLoadingTemplate = (
    <div className="flex flex-wrap justify-start mt-8">
        <LoadingContent tailWindCssString="block w-full h-12" />
    </div>
);

export const variantsColorsLoadingTemplate = (
    <div className="flex flex-wrap justify-start mt-8">
        <LoadingContent tailWindCssString="block w-full h-12 w-12 mr-6 rounded-full" />
    </div>
);

export const filterHeadlineTemplate = (
    <div className="flex flex-wrap justify-start mb-8">
        <LoadingContent tailWindCssString="block w-full h-[69px] lg:h-[105px]" />
    </div>
);

export const blogNaviTemplate = (
    <>
        <div className="content-container w-full flex-col items-center">
            <LoadingContent tailWindCssString="block mx-auto w-1/2 h-7 mb-4 lg:h-11 lg:mb-7" />

            <div className="flex justify-between w-full mb-4 lg:mb-8">
                <div className="flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="hidden xs:flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="hidden sm:flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="hidden sm:flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="hidden md:flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="hidden md:flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
                <div className="hidden md:flex flex-col items-center">
                    <LoadingContent tailWindCssString="h-[46px] w-[46px] lg:h-[58px] lg:w-[58px] mb-2 rounded-full" />
                    <LoadingContent tailWindCssString="h-5 lg:h-6 w-14" />
                </div>
            </div>
        </div>
    </>
);

export const blogPostsTemplate2 = (
    <div className="flex flex-col my-6">
        <div className="grid grid-cols-12 gap-y-8 w-full sm:gap-8">
            <LoadingContent tailWindCssString="col-span-12 h-[301px] sm:col-span-6 lg:col-span-4 lg:h-[338px]" />
            <LoadingContent tailWindCssString="hidden sm:block col-span-12 h-[301px] sm:col-span-6 lg:col-span-4 lg:h-[338px]" />
            <LoadingContent tailWindCssString="lg:col-span-4 h-[301px] lg:h-[338px] hidden lg:block" />
        </div>
    </div>
);

export const blogPostsTemplate = (
    <div className="flex flex-col my-6">
        <div className="grid grid-cols-12 gap-y-8 w-full sm:gap-8">
            <LoadingContent tailWindCssString="col-span-12 h-[301px] sm:col-span-6 lg:col-span-4 lg:h-[338px]" />
            <LoadingContent tailWindCssString="hidden sm:block col-span-12 h-[301px] sm:col-span-6 lg:col-span-4 lg:h-[338px]" />
            <LoadingContent tailWindCssString="lg:col-span-4 h-[301px] lg:h-[338px] hidden lg:block" />
            <LoadingContent tailWindCssString="col-span-12 h-[301px] sm:col-span-6 lg:col-span-4 lg:h-[338px]" />
            <LoadingContent tailWindCssString="hidden sm:block col-span-12 h-[301px] sm:col-span-6 lg:col-span-4 lg:h-[338px]" />
            <LoadingContent tailWindCssString="lg:col-span-4 h-[301px] lg:h-[338px] hidden lg:block" />
        </div>
    </div>
);

export const blogProductsTemplate = (
    <div className="flex flex-col my-6">
        <div className="grid grid-cols-12 gap-y-8 w-full sm:gap-8">
            <LoadingContent tailWindCssString="col-span-12 h-36 sm:col-span-6 lg:col-span-3 lg:h-[470px]" />
            <LoadingContent tailWindCssString="col-span-12 h-36 sm:col-span-6 lg:col-span-3 lg:h-[470px]" />
            <LoadingContent tailWindCssString="col-span-12 h-36 sm:col-span-6 lg:col-span-3 lg:h-[470px]" />
            <LoadingContent tailWindCssString="col-span-12 h-36 sm:col-span-6 lg:col-span-3 lg:h-[470px]" />
        </div>
    </div>
);

export const authorsTemplates = (
    <div className="flex flex-col my-6">
        <LoadingContent tailWindCssString="block w-1/2 h-7 mb-4 lg:h-11 lg:mb-7" />
        <div className="grid grid-cols-12 gap-y-8 w-full sm:gap-8">
            <LoadingContent tailWindCssString="col-span-12 h-36 sm:col-span-6 lg:col-span-3 lg:h-[386px]" />
            <LoadingContent tailWindCssString="col-span-12 h-36 sm:col-span-6 lg:col-span-3 lg:h-[386px]" />
            <LoadingContent tailWindCssString="col-span-12 h-36 sm:col-span-6 lg:col-span-3 lg:h-[386px]" />
            <LoadingContent tailWindCssString="col-span-12 h-36 sm:col-span-6 lg:col-span-3 lg:h-[386px]" />
        </div>
    </div>
);

export const jobsStoreSelectionTemplate = (
    <>
        <LoadingContent tailWindCssString="h-8 mb-4" />
        <div className="grid gap-x-4 grid-cols-2 md:grid-cols-3 md:gap-x-4 lg:gap-x-8 xl:gap-x-20">
            <LoadingContent tailWindCssString="col-span-2 xs:col-span-1 h-[38px] mb-4" />
            <LoadingContent tailWindCssString="col-span-2 xs:col-span-1 h-[38px] mb-4" />
            <LoadingContent tailWindCssString="col-span-2 md:col-span-1 h-[38px]" />
        </div>
    </>
);

export const jobsListTemplate = (
    <div className="space-y-1">
        <LoadingContent tailWindCssString="h-6 w-80 m-auto !my-4" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />
        <LoadingContent tailWindCssString="h-32 md:h-10" />

        <LoadingContent tailWindCssString="w-32 h-8 m-auto !mt-6 " />
    </div>
);

export const authorsTemplate = (
    <div className="content-container flex mt-6 sm:space-x-10">
        <LoadingContent tailWindCssString="w-full h-96 xl:h-[600px]" />
        <LoadingContent tailWindCssString="hidden w-full h-96 xl:h-[600px] sm:block" />
        <LoadingContent tailWindCssString="hidden w-full h-[600px] xl:block" />
        <LoadingContent tailWindCssString="hidden w-full h-[600px] xl:block" />
    </div>
);

export const allStoresSelectTemplate = (
    <LoadingContent tailWindCssString="mb-4 w-full h-9 xs:mb-0" />
);

export const filialenStoreSelectionTemplate = (
    <>
        <div className="flex flex-col">
            <div className="flex flex-col mt-12 lg:flex-col lg:space-x-3">
                <LoadingContent tailWindCssString="h-8 mb-3 sm:mb-2 md:mb-3 lg:mb-4 lg:w-1/2" />
                <div className="flex flex-col items-start justify-start !ml-0">
                    <LoadingContent tailWindCssString="h-4 w-full lg:w-1/2 mb-1" />
                    <div className="flex flex-col justify-center items-center w-full md:items-end md:flex-row">
                        <LoadingContent tailWindCssString="h-10 w-full" />
                        <div className="flex items-center flex-col w-full md:flex-row lg:justify-end xl:w-4/6">
                            <span className="hidden mx-4 md:block">oder</span>
                            <span className="my-2 md:hidden">oder</span>
                            <LoadingContent tailWindCssString="h-10 w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-12 gap-y-8 mt-12 sm:gap-8">
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />

            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />

            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />

            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />

            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />

            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />

            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
            <LoadingContent tailWindCssString="h-[95.75px] col-span-12 md:col-span-6 xl:col-span-4" />
        </div>
    </>
);

export const filialsTemplate = (
    <>
        <LoadingContent tailWindCssString="h-[37.5px] w-full" />
        <div className="content-container mt-12">
            <LoadingContent tailWindCssString="mb-4 m-auto max-w-[900px] h-28 mb-4 xs:h-20 sm:h-[30px] md:h-[37.5px] lg:h-12 lg:mb-7" />
            <LoadingContent tailWindCssString="mb-12">
                <Image
                    height={405}
                    width={1081}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
            </LoadingContent>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12 md:gap-8">
                <LoadingContent tailWindCssString="h-[146.25px] mb-4" />
                <LoadingContent tailWindCssString="h-[146.25px] mb-4" />
            </div>
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-y-8 sm:gap-8">
                    <div className="col-span-12 md:col-span-6 xl:col-span-3">
                        <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                            <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                            <div className="py-[13px] xl:py-[11px]">
                                <LoadingContent tailWindCssString="">
                                    <Image
                                        layout="responsive"
                                        height={870}
                                        width={625}
                                        src={
                                            '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                        }
                                        alt=""
                                        className="opacity-0"
                                    />
                                </LoadingContent>
                            </div>
                            <div className="mt-4 space-y-4">
                                <LoadingContent tailWindCssString="w-full h-[46px]" />
                                <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                            </div>
                        </div>
                    </div>
                    <div className="hidden col-span-6 md:block xl:col-span-3">
                        <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                            <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                            <div className="py-[13px] xl:py-[11px]">
                                <LoadingContent tailWindCssString="">
                                    <Image
                                        layout="responsive"
                                        height={870}
                                        width={625}
                                        src={
                                            '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                        }
                                        alt=""
                                        className="opacity-0"
                                    />
                                </LoadingContent>
                            </div>
                            <div className="mt-4 space-y-4">
                                <LoadingContent tailWindCssString="w-full h-[46px]" />
                                <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                            </div>
                        </div>
                    </div>
                </div>
                <LoadingContent tailWindCssString="h-1 w-16 mt-5 m-auto md:hidden" />
            </div>
            <div>
                <LoadingContent tailWindCssString="h-[26.25px] max-w-[450px] mb-3 sm:mb-2 md:mb-3 lg:mb-4 sm:h-[30px]" />
                <div className="flex -ml-4 overflow-hidden xs:-mx-4">
                    <div className="px-4 w-2/3 xs:w-1/2 block h-full flex-[0_0_auto] md:flex-auto">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={400}
                                width={400}
                                src={
                                    '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                            <div className="h-[60px] w-full" />
                        </LoadingContent>
                    </div>
                    <div className="px-4 w-2/3 xs:w-1/2 block h-full flex-[0_0_auto] md:flex-auto">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={400}
                                width={400}
                                src={
                                    '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                            <div className="h-[60px] w-full" />
                        </LoadingContent>
                    </div>
                    <div className="hidden px-4 w-2/3 xs:w-1/2 h-full md:block">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={400}
                                width={400}
                                src={
                                    '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                            <div className="h-[60px] w-full" />
                        </LoadingContent>
                    </div>
                    <div className="hidden px-4 w-2/3 xs:w-1/2 h-full lg:block">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={400}
                                width={400}
                                src={
                                    '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                            <div className="h-[60px] w-full" />
                        </LoadingContent>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export const restaurantTemplate = (
    <div className="content-container">
        <div className="py-8">
            <div className="flex flex-col">
                <LoadingContent tailWindCssString="w-1/2 h-10 m-auto mb-4" />
                <LoadingContent tailWindCssString="">
                    <Image
                        layout="responsive"
                        width={1260}
                        height={450}
                        src={
                            'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                        }
                        alt=""
                        className="opacity-0"
                    />
                </LoadingContent>
            </div>
        </div>
        <div className="py-8">
            <LoadingContent tailWindCssString="w-1/2 m-auto h-8 mb-4" />
            <LoadingContent tailWindCssString="h-[400px] w-full mb-2" />
            <LoadingContent tailWindCssString="h-[400px] w-full xl:hidden" />
        </div>
        <div className="py-8">
            <div className="flex flex-col justify-center space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
                <div className="flex flex-col items-center">
                    <LoadingContent tailWindCssString="w-1/2 h-16 mb-8" />
                    <LoadingContent tailWindCssString="w-fit mb-2">
                        <Image
                            height={543}
                            width={384}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <LoadingContent tailWindCssString="h-8 w-48" />
                </div>
                <div className="flex flex-col items-center">
                    <LoadingContent tailWindCssString="w-1/2 h-16 mb-8" />
                    <LoadingContent tailWindCssString="w-fit mb-2">
                        <Image
                            height={543}
                            width={384}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <LoadingContent tailWindCssString="h-8 w-48" />
                </div>
            </div>
        </div>
    </div>
);

export const restaurantInfoTemplate = (
    <div className="flex flex-col">
        <LoadingContent tailWindCssString="h-8 w-1/2 m-auto mb-4" />
        <LoadingContent tailWindCssString="flex flex-col w-full lg:flex-row">
            <Image
                src={
                    'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                }
                width={610}
                height={480}
                className="opacity-0"
                alt=""
            />
            <Image
                src={
                    'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                }
                width={610}
                height={480}
                className="opacity-0"
                alt=""
            />
        </LoadingContent>
    </div>
);

export const generatedAttributesTemplate = (
    <div className="grid grid-cols-12 gap-8">
        <LoadingContent tailWindCssString="h-60 col-span-10 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 col-start-1 md:col-start-7 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 col-start-1 md:col-start-7 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 col-start-1 md:col-start-7 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 col-start-1 md:col-start-7 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 col-start-1 md:col-start-7 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 md:col-span-5" />
        <LoadingContent tailWindCssString="h-60 col-span-10 col-start-1 md:col-start-7 md:col-span-5" />
    </div>
);

export const accountPageTemplate = (
    <div className="grid grid-cols-12 col-span-12 gap-3 md:col-span-9 content-start">
        <div className="col-span-12 xl:col-span-6">
            <LoadingContent tailWindCssString="w-72 h-6 mb-3" />
            <LoadingContent tailWindCssString="col-span-12 h-20" />
        </div>

        <div className="col-span-12 xl:col-span-6">
            <LoadingContent tailWindCssString="w-72 h-6 mb-3" />
            <LoadingContent tailWindCssString="col-span-12 h-20" />
        </div>

        <LoadingContent tailWindCssString="col-span-12 w-72 h-6" />
        <LoadingContent tailWindCssString="col-span-12 h-60 sm:col-span-6" />
        <LoadingContent tailWindCssString="col-span-12 h-60 sm:col-span-6" />

        <LoadingContent tailWindCssString="col-span-12 w-72 h-6" />
        <LoadingContent tailWindCssString="col-span-12 h-48 sm:col-span-6" />
        <LoadingContent tailWindCssString="col-span-12 h-48 sm:col-span-6" />

        <LoadingContent tailWindCssString="col-span-12 w-72 h-6" />
        <LoadingContent tailWindCssString="col-span-12 h-[500px]" />
    </div>
);

export const autocompleteWindowTemplate = (
    <div className="flex flex-col p-5">
        <LoadingContent tailWindCssString="h-[30px] w-[200px]" />
        <div className="grid grid-cols-5 gap-8">
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-10 w-full" />
            </LoadingContent>
        </div>
    </div>
);

export const prospectsTemplate = (
    <div className="grid grid-cols-12 gap-y-8 sm:gap-8">
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="flex flex-col justify-between h-full">
                <div className="p-2">
                    <LoadingContent tailWindCssString="h-[60px]" />
                </div>
                <div className="p-8">
                    <LoadingContent tailWindCssString="">
                        <Image
                            height={885}
                            width={625}
                            src={
                                'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="h-[58px]" />
                    <LoadingContent tailWindCssString="h-[58px]" />
                </div>
            </div>
        </div>
    </div>
);

export const prospectProducts = (
    <>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
        </div>
    </>
);

export const prospectDetailsTemplate = (
    <div className="my-12">
        <LoadingContent tailWindCssString="h-28 w-full xs:h-14 sm:h-20 lg:h-12 lg:w-5/6" />
        <div className="flex flex-col m-auto max-w-[300px] space-y-2 my-12">
            <LoadingContent tailWindCssString="flex">
                <Image
                    height={885}
                    width={625}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-9" />
        </div>
        <LoadingContent tailWindCssString="h-28 w-full mb-4 xs:h-14 sm:h-16 sm:w-5/6 lg:h-8" />
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
            <LoadingContent tailWindCssString="pb-4 mx-4">
                <Image
                    layout="responsive"
                    height={400}
                    width={400}
                    src={
                        'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />

                <div className="mt-2 lg:min-h-[120px] md:min-h-[115px] sm:min-h-[100px] xs:min-h-[100px] min-h-[100px]"></div>
            </LoadingContent>
        </div>
    </div>
);

export const prospekteSectionTemplate = (
    <div className="flex flex-col items-center lg:flex-row">
        {/* <LoadingContent tailWindCssString="h-full w-full mb-8 lg:mr-8 lg:mb-0">
            <Image
                layout="responsive"
                height={856}
                width={1228}
                src={
                    'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                }
                alt=""
                className="opacity-0"
            />
        </LoadingContent> */}
        <div className="flex w-full space-x-8">
            <div className="w-full h-full">
                <LoadingContent tailWindCssString="h-[84px] mb-2" />
                <LoadingContent tailWindCssString="inline-block w-full my-2">
                    <Image
                        layout="responsive"
                        height={466}
                        width={329}
                        src={
                            'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                        }
                        alt=""
                        className="opacity-0"
                    />
                </LoadingContent>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="p-2 h-[42px]" />
                    <LoadingContent tailWindCssString="p-2 h-[42px]" />
                </div>
                <div className="flex justify-center space-x-[5px] mt-5 xl:hidden">
                    <LoadingContent tailWindCssString="h-1 w-5" />
                    <LoadingContent tailWindCssString="h-1 w-5" />
                </div>
            </div>
            <div className="hidden w-full h-full xl:block">
                <LoadingContent tailWindCssString="h-[84px] mb-2" />
                <LoadingContent tailWindCssString="inline-block w-full my-2">
                    <Image
                        layout="responsive"
                        height={466}
                        width={329}
                        src={
                            'https://bilder.opti-wohnwelt.de/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                        }
                        alt=""
                        className="opacity-0"
                    />
                </LoadingContent>
                <div className="mt-4 space-y-4">
                    <LoadingContent tailWindCssString="p-2 h-[42px]" />
                    <LoadingContent tailWindCssString="p-2 h-[42px]" />
                </div>
            </div>
        </div>
    </div>
);

export const zipSearchTemplate = (
    <div className="flex">
        <LoadingContent tailWindCssString="min-h-[35.75px] w-full p-2 mb-4" />
        <LoadingContent tailWindCssString="w-16 h-[37px] ml-2 p-1 px-2 text-transparent">
            Prüfen
        </LoadingContent>
    </div>
);

export const storePickupTemplate = (
    <div className="py-3 px-5">
        <LoadingContent tailWindCssString="h-[18.75px] w-28 mb-4 m-auto" />
        <div className="flex">
            <LoadingContent tailWindCssString="min-h-[35.75px] w-full p-2 mb-4" />
            <LoadingContent tailWindCssString="w-16 h-[37px] ml-2 p-1 px-2 text-transparent">
                Prüfen
            </LoadingContent>
        </div>
    </div>
);

export const holidaysTemplate = (
    <LoadingContent tailWindCssString="w-1/2 h-[14px] md:h-[22.5px]" />
);

export const setStoreProspectsTemplate = (
    <div className="mt-12">
        <LoadingContent tailWindCssString="h-[30px] mb-4" />
        <div className="grid grid-cols-12 gap-y-8 sm:gap-8">
            <div className="storeProspectsGridCol col-span-12 xl:col-span-3">
                <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                    <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                    <div className="py-[13px] xl:py-[11px]">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={870}
                                width={625}
                                src={
                                    '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                        </LoadingContent>
                    </div>
                    <div className="mt-4 space-y-4">
                        <LoadingContent tailWindCssString="w-full h-[46px]" />
                        <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                    </div>
                </div>
            </div>
            <div className="hidden storeProspectsGridCol col-span-12 xl:block xl:col-span-3">
                <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                    <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                    <div className="py-[13px] xl:py-[11px]">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={870}
                                width={625}
                                src={
                                    '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                        </LoadingContent>
                    </div>
                    <div className="mt-4 space-y-4">
                        <LoadingContent tailWindCssString="w-full h-[46px]" />
                        <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                    </div>
                </div>
            </div>
            <div className="hidden col-span-12 xl:block xl:col-span-3">
                <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                    <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                    <div className="py-[13px] xl:py-[11px]">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={870}
                                width={625}
                                src={
                                    '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                        </LoadingContent>
                    </div>
                    <div className="mt-4 space-y-4">
                        <LoadingContent tailWindCssString="w-full h-[46px]" />
                        <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                    </div>
                </div>
            </div>
            <div className="hidden col-span-12 xl:block xl:col-span-3">
                <div className="flex flex-col justify-between h-full mx-4 md:mx-0">
                    <LoadingContent tailWindCssString="relative flex flex-col text-center items-center justify-center p-2 bg-white h-[84px]" />
                    <div className="py-[13px] xl:py-[11px]">
                        <LoadingContent tailWindCssString="">
                            <Image
                                layout="responsive"
                                height={870}
                                width={625}
                                src={
                                    '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                }
                                alt=""
                                className="opacity-0"
                            />
                        </LoadingContent>
                    </div>
                    <div className="mt-4 space-y-4">
                        <LoadingContent tailWindCssString="w-full h-[46px]" />
                        <LoadingContent tailWindCssString="w-full h-[46px] mt-4" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export const globalSiteMessageTemplate = (
    <LoadingContent tailWindCssString="h-[70px] xs:h-[41.25px] w-full" />
);

export const jobPageBannerTemplate = (
    <LoadingContent tailWindCssString="">
        <Image
            layout="responsive"
            height={405}
            width={1081}
            src={
                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
            }
            alt=""
            className="opacity-0"
        />
    </LoadingContent>
);

export const trainingPageTrainingTextTemplate = (
    <div className="content-container mb-12">
        <LoadingContent tailWindCssString="h-[500px] xs:h-[400px] sm:h-[300px] md:h-[200px]" />
    </div>
);

export const jobPageTrainingTextTemplate = (
    <div className="mb-12">
        <LoadingContent tailWindCssString="h-[500px] xs:h-[400px] sm:h-[300px] md:h-[200px]" />
    </div>
);

export const jobsRequirementsTemplate = (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
        <div>
            <div className="h-[70px] w-[70px] md:h-[85px] md:w-[85px] m-auto">
                <LoadingContent tailWindCssString="h-[70px] w-[70px]" />
            </div>
            <LoadingContent tailWindCssString="h-[45px] w-full" />
        </div>
        <div>
            <div className="h-[70px] w-[70px] md:h-[85px] md:w-[85px] m-auto">
                <LoadingContent tailWindCssString="h-[70px] w-[70px]" />
            </div>
            <LoadingContent tailWindCssString="h-[45px] w-full" />
        </div>
        <div>
            <div className="h-[70px] w-[70px] md:h-[85px] md:w-[85px] m-auto">
                <LoadingContent tailWindCssString="h-[70px] w-[70px]" />
            </div>
            <LoadingContent tailWindCssString="h-[45px] w-full" />
        </div>
        <div>
            <div className="h-[70px] w-[70px] md:h-[85px] md:w-[85px] m-auto">
                <LoadingContent tailWindCssString="h-[70px] w-[70px]" />
            </div>
            <LoadingContent tailWindCssString="h-[45px] w-full" />
        </div>
    </div>
);

export const careerFirstBlockTemplate = (
    <div className="content-container mb-12">
        <div className="mb-12">
            <LoadingContent tailWindCssString="h-[26.25px] max-w-[250px] mb-3 sm:mb-2 md:mb-3 lg:mb-4" />
            <div className="flex justify-center -mx-4 sm:justify-between">
                <div className="px-4 mb-1 sm:w-1/2 md:w-fit">
                    <div className="flex-[0_0_auto] w-full text-center md:px-0">
                        <div className="w-36 h-36 md:w-48 md:h-48 m-auto mb-4 border border-solid rounded-full overflow-hidden">
                            <LoadingContent tailWindCssString="rounded-full">
                                <Image
                                    layout="responsive"
                                    height={500}
                                    width={500}
                                    src={
                                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                    }
                                    alt=""
                                    className="opacity-0"
                                />
                            </LoadingContent>
                        </div>
                        <LoadingContent tailWindCssString="h-[38.5px] w-60 m-auto xs:h-4 lg:max-w-[80px] xl:max-w-[300px]" />
                    </div>
                </div>
                <div className="hidden px-4 mb-1 sm:w-1/2 md:w-fit sm:block">
                    <div className="flex-[0_0_auto] w-full text-center md:px-0">
                        <div className="w-36 h-36 md:w-48 md:h-48 m-auto mb-4 border border-solid rounded-full overflow-hidden">
                            <LoadingContent tailWindCssString="rounded-full">
                                <Image
                                    layout="responsive"
                                    height={500}
                                    width={500}
                                    src={
                                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                    }
                                    alt=""
                                    className="opacity-0"
                                />
                            </LoadingContent>
                        </div>
                        <LoadingContent tailWindCssString="h-[38.5px] w-60 m-auto xs:h-4 lg:max-w-[80px] xl:max-w-[300px]" />
                    </div>
                </div>
                <div className="hidden px-4 mb-1 sm:w-1/2 md:w-fit md:block">
                    <div className="flex-[0_0_auto] w-full text-center md:px-0">
                        <div className="w-36 h-36 md:w-48 md:h-48 m-auto mb-4 border border-solid rounded-full overflow-hidden">
                            <LoadingContent tailWindCssString="rounded-full">
                                <Image
                                    layout="responsive"
                                    height={500}
                                    width={500}
                                    src={
                                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                    }
                                    alt=""
                                    className="opacity-0"
                                />
                            </LoadingContent>
                        </div>
                        <LoadingContent tailWindCssString="h-[38.5px] w-60 m-auto xs:h-4 lg:max-w-[80px] xl:max-w-[300px]" />
                    </div>
                </div>
                <div className="hidden px-4 mb-1 sm:w-1/2 md:w-fit lg:block">
                    <div className="flex-[0_0_auto] w-full text-center md:px-0">
                        <div className="w-36 h-36 md:w-48 md:h-48 m-auto mb-4 border border-solid rounded-full overflow-hidden">
                            <LoadingContent tailWindCssString="rounded-full">
                                <Image
                                    layout="responsive"
                                    height={500}
                                    width={500}
                                    src={
                                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                    }
                                    alt=""
                                    className="opacity-0"
                                />
                            </LoadingContent>
                        </div>
                        <LoadingContent tailWindCssString="h-[38.5px] w-60 m-auto xs:h-4 lg:max-w-[80px] xl:max-w-[300px]" />
                    </div>
                </div>
                <div className="hidden px-4 mb-1 sm:w-1/2 md:w-fit lg:block">
                    <div className="flex-[0_0_auto] w-full text-center md:px-0">
                        <div className="w-36 h-36 md:w-48 md:h-48 m-auto mb-4 border border-solid rounded-full overflow-hidden">
                            <LoadingContent tailWindCssString="rounded-full">
                                <Image
                                    layout="responsive"
                                    height={500}
                                    width={500}
                                    src={
                                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                                    }
                                    alt=""
                                    className="opacity-0"
                                />
                            </LoadingContent>
                        </div>
                        <LoadingContent tailWindCssString="h-[38.5px] w-60 m-auto xs:h-4 lg:max-w-[80px] xl:max-w-[300px]" />
                    </div>
                </div>
            </div>
            <LoadingContent tailWindCssString="h-[3px] w-full mt-5 md:hidden" />
        </div>
        <div className="mb-12">
            <LoadingContent tailWindCssString="h-[26.25px] max-w-[300px] mb-3 sm:mb-2 md:mb-3 lg:mb-4 sm:h-[30px]" />

            <LoadingContent tailWindCssString="">
                <div className="w-full h-[48.75px] md:h-[52.5px]" />
                <div className="w-full h-[48.75px] md:h-[52.5px]" />
                <div className="w-full h-[48.75px] md:h-[52.5px]" />
                <div className="w-full h-[48.75px] md:h-[52.5px]" />
                <div className="w-full h-[48.75px] md:h-[52.5px]" />
                <div className="w-full h-[48.75px] md:h-[52.5px]" />
            </LoadingContent>
        </div>
        <div className="mb-12">
            <LoadingContent tailWindCssString="h-[26.25px] max-w-[400px] mb-3 sm:mb-2 md:mb-3 lg:mb-4 sm:h-[30px]" />
            <div className="flex -mx-4">
                <div className="h-full w-full px-4">
                    <LoadingContent tailWindCssString="!h-full !w-full">
                        <Image
                            layout="responsive"
                            height={693}
                            width={1155}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="hidden h-full w-full px-4 xs:block">
                    <LoadingContent tailWindCssString="!h-full !w-full">
                        <Image
                            layout="responsive"
                            height={693}
                            width={1155}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
                <div className="hidden h-full w-full px-4 sm:block">
                    <LoadingContent tailWindCssString="!h-full !w-full">
                        <Image
                            layout="responsive"
                            height={693}
                            width={1155}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                </div>
            </div>
            <LoadingContent tailWindCssString="h-1 max-w-[200px] m-auto mt-5" />
        </div>
        <div className="mb-12">
            <div className="w-full md:grid md:grid-cols-2 md:gap-8 inline-block flex-[0_0_auto]">
                <LoadingContent tailWindCssString="mb-6">
                    <Image
                        layout="responsive"
                        height={364.5}
                        width={648}
                        src={
                            '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                        }
                        alt=""
                        className="opacity-0"
                    />
                </LoadingContent>
                <div>
                    <LoadingContent tailWindCssString="w-full h-[60px] xs:h-[30px]" />
                    <LoadingContent tailWindCssString="mt-6 h-56" />
                </div>
            </div>
            <div className="hidden w-full flex-[0_0_auto] md:grid md:grid-cols-2 md:gap-8 md:mt-12">
                <div>
                    <LoadingContent tailWindCssString="w-full h-[60px] xs:h-[30px]" />
                    <LoadingContent tailWindCssString="mt-6 h-56" />
                </div>
                <LoadingContent tailWindCssString="mb-6 block">
                    <Image
                        layout="responsive"
                        height={364.5}
                        width={648}
                        src={
                            '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                        }
                        alt=""
                        className="opacity-0"
                    />
                </LoadingContent>
            </div>
            <div className="hidden w-full flex-[0_0_auto] md:grid md:grid-cols-2 md:gap-8 md:mt-12">
                <LoadingContent tailWindCssString="mb-6 block">
                    <Image
                        layout="responsive"
                        height={364.5}
                        width={648}
                        src={
                            '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                        }
                        alt=""
                        className="opacity-0"
                    />
                </LoadingContent>
                <div>
                    <LoadingContent tailWindCssString="w-full h-[60px] xs:h-[30px]" />
                    <LoadingContent tailWindCssString="mt-6 h-56" />
                </div>
            </div>
        </div>
    </div>
);

export const careersSecondBlock = (
    <div className="content-container mt-12">
        <div>
            <LoadingContent tailWindCssString="h-[26.25px] max-w-[300px] mb-3 sm:mb-2 md:mb-3 lg:mb-4 sm:h-[30px]" />
            <div className="flex -mx-4">
                <div className="w-full h-full px-4 mb-1">
                    <LoadingContent tailWindCssString="mb-6 block">
                        <Image
                            layout="responsive"
                            height={270}
                            width={284}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <div className="flex flex-col items-center">
                        <LoadingContent tailWindCssString="w-48 h-[20px]" />
                        <LoadingContent tailWindCssString="w-full max-w-[240px] h-[30px] mt-[15px] mb-[15px]" />
                        <LoadingContent tailWindCssString="w-full h-32 mt-3 mb-4 xs:h-48 lg:h-32" />
                    </div>
                </div>
                <div className="hidden w-full h-full px-4 mb-1 xs:block">
                    <LoadingContent tailWindCssString="mb-6 block">
                        <Image
                            layout="responsive"
                            height={270}
                            width={284}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <div className="flex flex-col items-center">
                        <LoadingContent tailWindCssString="w-48 h-[20px]" />
                        <LoadingContent tailWindCssString="w-full max-w-[240px] h-[30px] mt-[15px] mb-[15px]" />
                        <LoadingContent tailWindCssString="w-full h-32 mt-3 mb-4 xs:h-48 lg:h-32" />
                    </div>
                </div>
                <div className="hidden w-full h-full px-4 mb-1 sm:block">
                    <LoadingContent tailWindCssString="mb-6 block">
                        <Image
                            layout="responsive"
                            height={270}
                            width={284}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <div className="flex flex-col items-center">
                        <LoadingContent tailWindCssString="w-48 h-[20px]" />
                        <LoadingContent tailWindCssString="w-full max-w-[240px] h-[30px] mt-[15px] mb-[15px]" />
                        <LoadingContent tailWindCssString="w-full h-32 mt-3 mb-4 xs:h-48 lg:h-32" />
                    </div>
                </div>
            </div>
            <LoadingContent tailWindCssString="h-1 w-full mt-5 xs:hidden" />
        </div>
        <div className="mt-12">
            <LoadingContent tailWindCssString="h-[26.25px] max-w-[400px] mb-3 sm:mb-2 md:mb-3 lg:mb-4 sm:h-[30px]" />
            <div className="flex -mx-4">
                <div className="w-full px-4">
                    <LoadingContent tailWindCssString="rounded-full mb-4">
                        <Image
                            layout="responsive"
                            height={245}
                            width={245}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <div className="flex flex-col items-center">
                        <LoadingContent tailWindCssString="w-48 h-[20px]" />
                        <LoadingContent tailWindCssString="w-full max-w-[240px] h-[30px] mt-[15px] mb-[15px]" />
                        <LoadingContent tailWindCssString="mt-8 h-[450px] w-full" />
                    </div>
                </div>
                <div className="hidden w-full px-4 xs:block">
                    <LoadingContent tailWindCssString="rounded-full mb-4">
                        <Image
                            layout="responsive"
                            height={245}
                            width={245}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <div className="flex flex-col items-center">
                        <LoadingContent tailWindCssString="w-48 h-[20px]" />
                        <LoadingContent tailWindCssString="w-full max-w-[240px] h-[30px] mt-[15px] mb-[15px]" />
                        <LoadingContent tailWindCssString="mt-8 h-[450px] w-full" />
                    </div>
                </div>
                <div className="hidden w-full px-4 sm:block">
                    <LoadingContent tailWindCssString="rounded-full mb-4">
                        <Image
                            layout="responsive"
                            height={245}
                            width={245}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <div className="flex flex-col items-center">
                        <LoadingContent tailWindCssString="w-48 h-[20px]" />
                        <LoadingContent tailWindCssString="w-full max-w-[240px] h-[30px] mt-[15px] mb-[15px]" />
                        <LoadingContent tailWindCssString="mt-8 h-[450px] w-full" />
                    </div>
                </div>
                <div className="hidden w-full px-4 lg:block">
                    <LoadingContent tailWindCssString="rounded-full mb-4">
                        <Image
                            layout="responsive"
                            height={245}
                            width={245}
                            src={
                                '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                            }
                            alt=""
                            className="opacity-0"
                        />
                    </LoadingContent>
                    <div className="flex flex-col items-center">
                        <LoadingContent tailWindCssString="w-48 h-[20px]" />
                        <LoadingContent tailWindCssString="w-full max-w-[240px] h-[30px] mt-[15px] mb-[15px]" />
                        <LoadingContent tailWindCssString="mt-8 h-[450px] w-full" />
                    </div>
                </div>
            </div>
            <LoadingContent tailWindCssString="h-1 max-w-[200px] mt-5 m-auto xs:hidden md:block lg:hidden" />
        </div>
    </div>
);

export const jobsList = (
    <>
        <div className="flex-wrap hidden md:flex -mx-2">
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
            <div className="flex w-full sm:w-1/2 flex-[0_0_auto] px-2">
                <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full">
                    <LoadingContent tailWindCssString="mr-8 h-[45px] w-full max-w-[47.94px]" />
                    <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                </div>
            </div>
        </div>

        <div className="md:hidden">
            <div className="flex -mx-2">
                <div className="w-full flex-[0_0_auto] px-2 sm:w-1/2">
                    <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full max-w-[300px]">
                        <LoadingContent tailWindCssString="mr-8 h-[45px] w-[47.94px]" />
                        <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                    </div>
                </div>
                <div className="hidden w-full flex-[0_0_auto] px-2 sm:flex sm:w-1/2">
                    <div className="flex items-start flex-col md:flex-row md:items-end md:mb-8 w-full max-w-[300px]">
                        <LoadingContent tailWindCssString="mr-8 h-[45px] w-[47.94px]" />
                        <LoadingContent tailWindCssString="h-[22.5px] w-full md:h-[30px]" />
                    </div>
                </div>
            </div>
            <LoadingContent tailWindCssString="h-[3px] md:hidden mt-8" />
        </div>
    </>
);

export const jobsAusbildungBlock = (
    <div className="flex flex-col md:flex-row -mx-4">
        <div className="w-full h-full mb-8 px-4">
            <LoadingContent tailWindCssString="h-[26.25px] max-w-[200px] mb-3 sm:mb-2 md:mb-3 lg:mb-4" />
            <LoadingContent tailWindCssString="h-[550px] w-full mb-3 sm:mb-3 xs:h-[400px] md:h-[550px] lg:h-[300px] xl:h-[250px]" />
            <LoadingContent tailWindCssString="hidden h-[49px] w-[195.05px] mt-10 sm:block" />
        </div>
        <div className="w-full h-full px-4">
            <LoadingContent tailWindCssString="">
                <Image
                    layout="responsive"
                    height={296}
                    width={695}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-[44.5px] w-[190.55px] mt-10 sm:hidden" />
        </div>
    </div>
);

export const ekomiReviewsTemplate = (
    <>
        <LoadingContent tailWindCssString="h-[116px] w-full" />
        <LoadingContent tailWindCssString="h-[37.5px] w-full mt-4" />
    </>
);

export const newsletterPageTemplate = (
    <div className="content-container mt-12">
        <div className="flex flex-col">
            <div className="pb-12">
                <LoadingContent tailWindCssString="h-[26.25px] w-64 mb-4 sm:h-[30px] md:h-[37.5px] lg:h-12 lg:mb-7" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
                <LoadingContent tailWindCssString="h-[500px] col-span-1/2 xs:h-[400px] sm:h-[300px] md:h-[400px] lg:h-[300px] xl:h-[250px]" />
                <LoadingContent tailWindCssString="h-[500px] col-span-1/2 md:h-[400px] lg:h-[350px]" />
            </div>
            <LoadingContent tailWindCssString="w-60 h-6 mt-12 m-auto" />
        </div>
    </div>
);
