import React, { useState } from 'react';
import { func } from 'prop-types';
import { useRouter } from 'next/router';

const MobileSearchBar = (props) => {
    const preventDefault = (f) => (e) => {
        e.preventDefault();
        f(e);
    };
    const router = useRouter();
    const [searchQuery, setSearchQuery] = useState(null);

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSubmit = preventDefault(() => {
        router.push({
            pathname: '/search',
            query: { query: searchQuery }
        });
    });

    return (
        <form
            className="p-4 flex items-center bg-skin-site-bg-alternate"
            onSubmit={handleSubmit}
        >
            <div className="relative items-center pl-1 flex w-full  sm:pr-2 sm:ml-0">
                <div className="container relative left-0 flex h-auto h-full">
                    <div className="relative flex items-center w-full  h-full group">
                        <div className="absolute flex items-center justify-center block w-auto h-10 p-3 pr-2 text-sm text-gray-500 uppercase cursor-pointer">
                            <svg
                                className="absolute left-0 w-4 h-4 ml-4 text-gray-500 pointer-events-none fill-current group-hover:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                            </svg>
                        </div>

                        <input
                            type="text"
                            onChange={handleInputChange}
                            className="block w-full py-1.5 pl-10 pr-4 leading-normal focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-gray-400 aa-input opacity-100"
                            placeholder="Search"
                        />

                        <button
                            disabled={!searchQuery}
                            className="absolute right-0 hover:pointer h-auto px-2 py-1.5 mr-1 text-xs text-white border md:block bg-skin-secondary"
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="p-1.5" onClick={props.toggleMobileSearch}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-7 w-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </div>
        </form>
    );
};

MobileSearchBar.propTypes = {
    toggleMobileSearch: func
};

export default MobileSearchBar;
