import { RadioGroup } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { getDistance } from 'geolib';

function classNames(...classes: (string | undefined)[]): string {
    return classes.filter(Boolean).join(' ');
}

const AvailableFilialsCard = ({
    store,
    showNearestShop,
    gFieldLng,
    gFieldLat,
    lat,
    long,
    geolocationEnabled
}: any) => {
    //Location logic
    const [myLatitude, setMyLatitude] = useState(
        window.myLat != undefined ? window.myLat : ''
    );
    const [myLongitude, setMyLongitude] = useState(
        window.myLong != undefined ? window.myLong : ''
    );

    let distance: any = '';

    if (gFieldLat != null && gFieldLng != null && showNearestShop == 0) {
        if (gFieldLat != '' && gFieldLng != '') {
            distance = getDistance(
                { latitude: gFieldLat, longitude: gFieldLng },
                { lat: lat ? lat : '', lng: long ? long : '' }
            );
        }
    } else {
        if (myLatitude != '' && myLongitude != '' && lat && long) {
            if (
                myLatitude != 'err-latitude' &&
                myLongitude != 'err-longitude'
            ) {
                distance = getDistance(
                    { latitude: myLatitude, longitude: myLongitude },
                    { latitude: lat, longitude: long }
                );
            }
        }
    }

    const getMyLocation = () => {
        const location = window.navigator && window.navigator.geolocation;
        const options = {
            enableHighAccuracy: true,
            timeout: 50000,
            maximumAge: 0
        };

        if (location) {
            location.getCurrentPosition(
                (position) => {
                    setMyLatitude(position.coords.latitude);
                    setMyLongitude(position.coords.longitude);
                    window.myLat = position.coords.latitude;
                    window.myLong = position.coords.longitude;
                },
                (error) => {
                    // this.setState({ myLatitude: 'err-latitude', myLongitude: 'err-longitude' });
                    console.log(error);
                },
                options
            );
        }
    };

    useEffect(() => {
        if (geolocationEnabled) {
            getMyLocation();
        }
    }, [geolocationEnabled]);

    const handleDescription = (description: string) => {
        let replaceParagraphTag = '';
        let splitBrTag: Array<string> = [];
        replaceParagraphTag = description
            ? description.replace('<p>', '').replace('</p>', '')
            : '';
        splitBrTag = replaceParagraphTag.split('<br />');
        return splitBrTag.map((element: string) => {
            if (element.includes('Öffnungszeiten:')) {
                return null;
            } else {
                return element;
            }
        });
    };

    return (
        <RadioGroup.Option
            value={store}
            className={
                'relative flex w-full cursor-pointer items-start border-b border-solid p-2 text-left focus:outline-none'
            }
        >
            {({ active, checked }) => (
                <>
                    <span
                        className={classNames(
                            checked
                                ? 'border-transparent bg-skin-red-bg'
                                : 'border border-solid border-gray-300 bg-white',
                            active ? 'rounded-full bg-skin-red-bg' : '',
                            'mt-0.5 flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-full border'
                        )}
                        aria-hidden="true"
                    >
                        <span className="flex h-5 w-5 items-center justify-center rounded-full bg-white">
                            {checked ? (
                                <span className="h-3 w-3 rounded-full bg-skin-red-bg" />
                            ) : null}
                        </span>
                    </span>
                    <span className="ml-3 flex w-full flex-col">
                        <RadioGroup.Label
                            as="span"
                            className={classNames(
                                checked ? 'text-black' : 'text-gray-900',
                                'mt-[2px] flex flex-row justify-between text-base font-medium'
                            )}
                        >
                            {store.name}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                            as="span"
                            className={classNames('mt-1 block text-sm')}
                        >
                            <div className="flex flex-col items-start">
                                <div className="mb-1 mr-auto w-auto">
                                    <span className="flex w-full flex-col items-center text-center text-sm text-green-600">
                                        {distance != 0
                                            ? Math.round(
                                                  (distance / 1000) * 100
                                              ) /
                                                  100 +
                                              ' km'
                                            : ''}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {store.street} , {store.postcode}{' '}
                                        {store.city}
                                    </span>
                                    <p className="my-0 flex text-sm">
                                        {handleDescription(store.description)}
                                    </p>
                                </div>
                            </div>
                        </RadioGroup.Description>
                    </span>
                </>
            )}
        </RadioGroup.Option>
    );
};

export default AvailableFilialsCard;
