import Link from 'next/link';
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Popover, Tab, Transition } from '@headlessui/react';
import Image from 'next/image';
import {
    MenuIcon,
    SearchIcon,
    ShoppingBagIcon,
    LoginIcon,
    LogoutIcon,
    ChevronRightIcon,
    ArrowLeftIcon
} from '@heroicons/react/outline';
import CloseIcon from '../../images/close.svg';
import FurnitureIcon from './icons/095-furniture.svg';
import LivingRoomIcon from './icons/living room.svg';
import SchlafzimmerIcon from './icons/schlafzimmer.svg';
import EsszimmerIcon from './icons/esszimmer.svg';
import FlurmobelIcon from './icons/flurmöbel.svg';
import BuroMobelIcon from './icons/büromöbel.svg';
import BunkBedIcon from './icons/bunk-bed.svg';
import KitchenIcon from './icons/kitchen.svg';
import KucheIcon from './icons/kuche.svg';
import KucheBrandIcon from './icons/kuche_brand.svg';
import OvenIcon from './icons/056-oven.svg';
import SpuleIcon from './icons/spule.svg';
import HausDekorationIcon from './icons/haus-dekoration.svg';
import VaseIcon from './icons/021-vase.svg';
import RugIcon from './icons/100-rug.svg';
import PillowsIcon from './icons/027-pillows.svg';
import BettIcon from './icons/bett.svg';
import PictureIcon from './icons/032-picture.svg';
import TowelIcon from './icons/036-towel.svg';
import TopfIcon from './icons/topf.svg';
import GeschirrIcon from './icons/geschirr.svg';
import KettleIcon from './icons/066-kettle.svg';
import LunchboxIcon from './icons/lunchbox.svg';
import CutleryIcon from './icons/061-cutlery.svg';
import ShampooIcon from './icons/shampoo.svg';
import BackenIcon from './icons/backen.svg';
import Lamp1Icon from './icons/078-lamp-1.svg';
import LampIcon from './icons/079-lamp.svg';
import LampPostIcon from './icons/lamppost.svg';
import DeskLampIcon from './icons/023-desk-lamp.svg';
import LedLichtIcon from './icons/led-licht.svg';
import BookIcon from './icons/book.svg';
import TableIcon from './icons/table.svg';
import GardenChairIcon from './icons/garden_chair.svg';
import GardenTableIcon from './icons/garden_table.svg';
import LoungeChairIcon from './icons/lounge-chair.svg';
import UmbrellaIcon from './icons/umbrella.svg';
import AngeboteIcon from './icons/angebote.svg';
import FilialschnappchenIcon from './icons/filialschnäppchen.svg';
import SaleIcon from './icons/sale.svg';
import LiegeIcon from './icons/liege.svg';
import ScrewdriverIcon from './icons/screwdriver.svg';

const MobileNavigation = (props) => {
    const {
        categories,
        categoryUrlSuffix,
        toggleCart,
        toggleNavi,
        isSignedIn,
        drawer,
        isOpen,
        staticItems,
        featuredCategories,
        handledSignOut
    } = props;

    const [menuData, setMenuData] = useState([]);
    const [level, setLevel] = useState(1);
    const activeCategory = menuData[menuData?.length - 1];

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    const renderTitle = () => {
        switch (level) {
            case 1:
                return 'Menü';
            default:
                return activeCategory.name;
        }
    };

    const categories_copy = categories
        ? JSON.parse(JSON.stringify(categories))
        : [];

    const sortedCategories =
        categories_copy &&
        categories_copy.sort((a, b) => (a.position < b.position ? -1 : 1));

    const goBack = () => {
        const handledMenuItem = menuData.filter(
            (f) => f.id !== activeCategory.id
        );
        setLevel((prevState) => (prevState -= 1));
        setMenuData(handledMenuItem);
    };

    const renderCategoryIcon = (id) => {
        switch (id) {
            case 390:
                return <FurnitureIcon className="h-7 w-7 mr-4" />;
            case 398:
                return <LivingRoomIcon className="h-7 w-7 mr-4" />;
            case 399:
                return <SchlafzimmerIcon className="h-7 w-7 mr-4" />;
            case 400:
                return <EsszimmerIcon className="h-7 w-7 mr-4" />;
            case 401:
                return <FlurmobelIcon className="h-7 w-7 mr-4" />;
            case 403:
                return <BuroMobelIcon className="h-7 w-7 mr-4" />;
            case 402:
                return <BunkBedIcon className="h-7 w-7 mr-4" />;
            case 392:
                return <KucheIcon className="h-7 w-7 mr-4" />;
            case 395:
                return <HausDekorationIcon className="h-7 w-7 mr-4" />;
            case 498:
                return <HausDekorationIcon className="h-7 w-7 mr-4" />;
            case 499:
                return <PillowsIcon className="h-7 w-7 mr-4" />;
            case 500:
                return <BettIcon className="h-7 w-7 mr-4" />;
            case 477:
                return <CutleryIcon className="h-7 w-7 mr-4" />;
            case 464:
                return <TopfIcon className="h-7 w-7 mr-4" />;
            case 470:
                return <GeschirrIcon className="h-7 w-7 mr-4" />;
            case 476:
                return <KettleIcon className="h-7 w-7 mr-4" />;
            case 2388:
                return <ShampooIcon className="h-7 w-7 mr-4" />;
            case 478:
                return <BackenIcon className="h-7 w-7 mr-4" />;
            case 479:
                return <LunchboxIcon className="h-7 w-7 mr-4" />;
            case 501:
                return <PictureIcon className="h-7 w-7 mr-4" />;
            case 393:
                return <Lamp1Icon className="h-7 w-7 mr-4" />;
            case 445:
                return <LampIcon className="h-7 w-7 mr-4" />;
            case 446:
                return <LampPostIcon className="h-7 w-7 mr-4" />;
            case 1545:
                return <DeskLampIcon className="h-7 w-7 mr-4" />;
            case 1558:
                return <LedLichtIcon className="h-7 w-7 mr-4" />;
            case 945:
                return <RugIcon className="h-7 w-7 mr-4" />;
            case 902:
                return <ScrewdriverIcon className="h-7 w-7 mr-4" />;
            case 621:
                return <TableIcon className="h-7 w-7 mr-4" />;
            case 2438:
                return <TableIcon className="h-7 w-7 mr-4" />;
            case 624:
                return <UmbrellaIcon className="h-7 w-7 mr-4" />;
            case 2423:
                return <AngeboteIcon className="h-7 w-7 mr-4" />;
            default:
                return renderRedDot();
        }
    };

    const renderRedDot = () => {
        return (
            <div className="flex items-center h-7 w-7">
                <div className="bg-skin-red-accent h-[7px] w-[7px]" />
            </div>
        );
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 flex z-40 mt-[53.5px] lg:hidden"
                onClose={toggleNavi}
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25 mt-[53.5px]" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <div className="relative w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto sm:max-w-sm">
                        <div className="flex justify-center items-center">
                            {activeCategory && level > 1 && (
                                <ArrowLeftIcon
                                    className="w-14 h-14 p-4 cursor-pointer"
                                    onClick={goBack}
                                />
                            )}
                            <h3
                                className={`text-lg ${
                                    activeCategory && level > 1
                                        ? 'ml-0 cursor-pointer'
                                        : 'ml-4'
                                } mr-auto`}
                                onClick={level > 1 ? goBack : null}
                            >
                                {renderTitle()}
                            </h3>
                            <button className="p-4" onClick={toggleNavi}>
                                <span className="sr-only">Close menu</span>
                                <CloseIcon />
                            </button>
                        </div>

                        {sortedCategories &&
                        (!activeCategory || activeCategory.length == 0) ? (
                            <ul className="mb-4">
                                {sortedCategories.map((category) => {
                                    {
                                        return category.include_in_menu ? (
                                            <li
                                                className="flex items-center justify-between border-b border-solid text-base cursor-pointer last:border-none"
                                                key={category.id}
                                            >
                                                {category.children.length >
                                                0 ? (
                                                    <>
                                                        <div
                                                            className="flex justify-between items-center w-full p-4"
                                                            onClick={() => {
                                                                const category_copy =
                                                                    category.children
                                                                        ? JSON.parse(
                                                                              JSON.stringify(
                                                                                  category.children
                                                                              )
                                                                          )
                                                                        : [];

                                                                const sortedCategory =
                                                                    category_copy &&
                                                                    category_copy.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.position <
                                                                            b.position
                                                                                ? -1
                                                                                : 1
                                                                    );
                                                                setLevel(
                                                                    level + 1
                                                                );
                                                                setMenuData([
                                                                    ...menuData,
                                                                    {
                                                                        ...category,
                                                                        children:
                                                                            sortedCategory
                                                                    }
                                                                ]);
                                                            }}
                                                        >
                                                            {renderCategoryIcon(
                                                                category.id
                                                            )}
                                                            <span className="mr-auto">
                                                                {category.name}
                                                            </span>
                                                            <ChevronRightIcon className="h-7 w-7" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <Link
                                                        key={category.id}
                                                        passHref
                                                        href={
                                                            '/' +
                                                            category.url_path +
                                                            categoryUrlSuffix
                                                        }
                                                    >
                                                        <div
                                                            className="flex items-center justify-between w-full p-4 border-b border-solid text-base last:border-none cursor-pointer"
                                                            onClick={() => {
                                                                toggleNavi();
                                                                localStorage.removeItem(
                                                                    'categoryScrolledIndex'
                                                                );
                                                            }}
                                                        >
                                                            {renderCategoryIcon(
                                                                category.id
                                                            )}
                                                            <span className="mr-auto">
                                                                {category.name}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                )}
                                            </li>
                                        ) : null;
                                    }
                                })}
                            </ul>
                        ) : activeCategory?.children ? (
                            <ul className="mb-4">
                                <Link
                                    passHref
                                    href={
                                        '/' +
                                        activeCategory.url_path +
                                        categoryUrlSuffix
                                    }
                                >
                                    <li className="flex items-center justify-between border-b border-solid text-base cursor-pointer font-bold last:border-none">
                                        <div
                                            className="flex justify-between items-center w-full p-4"
                                            onClick={() => {
                                                toggleNavi();
                                                localStorage.removeItem(
                                                    'categoryScrolledIndex'
                                                );
                                            }}
                                        >
                                            Gesamte Kategorie entdecken
                                            <ChevronRightIcon className="h-7 w-7" />
                                        </div>
                                    </li>
                                </Link>
                                {activeCategory?.children.map((category) => {
                                    {
                                        return category.include_in_menu ? (
                                            <li
                                                className="flex items-center justify-between border-b border-solid text-base cursor-pointer last:border-none"
                                                key={category.id}
                                            >
                                                {category?.children?.length >
                                                0 ? (
                                                    <>
                                                        <div
                                                            className="flex justify-between items-center w-full p-4"
                                                            onClick={() => {
                                                                const category_copy =
                                                                    category.children
                                                                        ? JSON.parse(
                                                                              JSON.stringify(
                                                                                  category.children
                                                                              )
                                                                          )
                                                                        : [];

                                                                const sortedCategory =
                                                                    category_copy &&
                                                                    category_copy.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.position <
                                                                            b.position
                                                                                ? -1
                                                                                : 1
                                                                    );
                                                                setLevel(
                                                                    level + 1
                                                                );
                                                                setMenuData([
                                                                    ...menuData,
                                                                    {
                                                                        ...category,
                                                                        children:
                                                                            sortedCategory
                                                                    }
                                                                ]);
                                                            }}
                                                        >
                                                            {renderCategoryIcon(
                                                                category.id
                                                            )}
                                                            <span className="mr-auto">
                                                                {category.name}
                                                            </span>
                                                            <ChevronRightIcon className="h-7 w-7" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <Link
                                                        key={category.id}
                                                        passHref
                                                        href={
                                                            '/' +
                                                            category.url_path +
                                                            categoryUrlSuffix
                                                        }
                                                    >
                                                        <div
                                                            className="flex justify-between items-center w-full p-4 border-b border-solid text-base last:border-none cursor-pointer"
                                                            onClick={() => {
                                                                toggleNavi();
                                                                localStorage.removeItem(
                                                                    'categoryScrolledIndex'
                                                                );
                                                            }}
                                                        >
                                                            {renderCategoryIcon(
                                                                category.id
                                                            )}
                                                            <span className="mr-auto">
                                                                {category.name}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                )}
                                            </li>
                                        ) : null;
                                    }
                                })}
                            </ul>
                        ) : null}

                        <h3 className="pl-4">Kundencenter</h3>
                        <ul>
                            {isSignedIn ? (
                                <li
                                    className="flex justify-between p-4 border-b border-solid text-base cursor-pointer"
                                    onClick={() => {
                                        if (isSignedIn) {
                                            handledSignOut();
                                        }

                                        toggleNavi();
                                    }}
                                >
                                    Abmelden
                                </li>
                            ) : (
                                <Link passHref href={'/login'}>
                                    <li
                                        className="flex justify-between p-4 text-base cursor-pointer"
                                        onClick={() => {
                                            toggleNavi();
                                        }}
                                    >
                                        Anmelden
                                    </li>
                                </Link>
                            )}
                            {isSignedIn && (
                                <Link passHref href={'/account/overview'}>
                                    <li
                                        className="flex justify-between p-4 border-b border-solid text-base cursor-pointer"
                                        onClick={toggleNavi}
                                    >
                                        Mein Konto
                                    </li>
                                </Link>
                            )}
                        </ul>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    );
};

export default MobileNavigation;
