import React, { Fragment, useEffect, useState } from 'react';
import Link from 'next/link';
import { Dialog, Popover, Tab, Transition } from '@headlessui/react';
import TopBanner from '../TopBanner';
import Image from 'next/image';
import {
    MenuIcon,
    SearchIcon,
    ShoppingBagIcon,
    XIcon,
    ArrowCircleRightIcon
} from '@heroicons/react/outline';
import SaleIcon from '../../images/sale.svg';
import { isMobile } from 'react-device-detect';
import { browserNavigationBarTemplate } from '../LoadingContent/loadingTemplates';
import CmsBlock from '../CmsBlock/cmsBlock';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import categoryRedirect from '../../util/categoryRedirect.tsx';

const DesktopNavigation = (props) => {
    const [hoverCategory, setHoverCategory] = useState(null);
    const {
        categories,
        categoryUrlSuffix,
        staticItems,
        featuredCategories,
        loading
    } = props;

    const router = useRouter();

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    if (loading) {
        return browserNavigationBarTemplate;
    }

    // deep copy to avoid directly mutating state
    const categories_copy = categories
        ? JSON.parse(JSON.stringify(categories))
        : [];

    const sortedCategories =
        categories_copy &&
        categories_copy.sort((a, b) => (a.position < b.position ? -1 : 1));

    const checkForNumbersRegex = (url) => {
        // Url path has a number at the end most of the time, like 'kerzen-kerzenstaender-503', but sometimes it doesn't.
        // We check for this case in this function and omit it so that we can easily determine which url is active and apply the appropriate styles further down

        const patternWithNum = /^.*?(?=-\d)/;

        if (patternWithNum.test(url)) {
            // return string we have received but omit '-number' part with regex
            return patternWithNum.exec(url)[0];
        } else {
            // return string we have received but omit query part
            return url.split('?')[0];
        }
    };

    return (
        <Popover.Group className="hidden lg:flex justify-left w-full z-30">
            <div className="h-full w-full flex justify-between z-20">
                {sortedCategories
                    ? sortedCategories.map((category) =>
                          category.include_in_menu ? (
                              category.children.length > 0 ? (
                                  <div
                                      key={category.name}
                                      className="flex items-center"
                                  >
                                      <Popover
                                          className="flex"
                                          onMouseEnter={() => {
                                              !isMobile &&
                                                  setHoverCategory(
                                                      category.name
                                                  );
                                          }}
                                          onMouseLeave={() => {
                                              !isMobile &&
                                                  setHoverCategory(null);
                                          }}
                                          onClick={() =>
                                              !isMobile &&
                                              setHoverCategory(null)
                                          }
                                      >
                                          {({ open, close }) => (
                                              <>
                                                  {!isMobile ? (
                                                      <Link
                                                          passHref
                                                          href={categoryRedirect(
                                                              '/' +
                                                                  category.url_path +
                                                                  categoryUrlSuffix
                                                          )}
                                                      >
                                                          <a
                                                              className="flex items-center"
                                                              onClick={() =>
                                                                  localStorage.removeItem(
                                                                      'categoryScrolledIndex'
                                                                  )
                                                              }
                                                          >
                                                              <div className="relative flex">
                                                                  <Popover.Button
                                                                      className={classNames(
                                                                          `relative z-10 flex items-center py-2 px-4 transition-colors ease-out duration-200 text-md font-bold uppercase text-white ${
                                                                              hoverCategory ==
                                                                                  category.name &&
                                                                              '!text-black bg-skin-megastore-yellow'
                                                                          } hover:!text-black hover:bg-skin-megastore-yellow ${
                                                                              checkForNumbersRegex(
                                                                                  router.asPath
                                                                                      .split(
                                                                                          '/'
                                                                                      )[1]
                                                                                      .replace(
                                                                                          '.html',
                                                                                          ''
                                                                                      )
                                                                              ) ==
                                                                                  checkForNumbersRegex(
                                                                                      category.url_path
                                                                                  ) &&
                                                                              '!text-black bg-skin-megastore-yellow'
                                                                          }`
                                                                      )}
                                                                  >
                                                                      {
                                                                          category.name
                                                                      }
                                                                  </Popover.Button>
                                                              </div>
                                                          </a>
                                                      </Link>
                                                  ) : (
                                                      <div className="relative flex">
                                                          <Popover.Button
                                                              className={classNames(
                                                                  `relative z-10 flex items-center py-2 px-4 transition-colors ease-out duration-200 text-md !font-bold uppercase text-white hover:!text-black hover:bg-skin-megastore-yellow ${
                                                                      checkForNumbersRegex(
                                                                          router.asPath
                                                                              .split(
                                                                                  '/'
                                                                              )[1]
                                                                              .replace(
                                                                                  '.html',
                                                                                  ''
                                                                              )
                                                                      ) ==
                                                                          checkForNumbersRegex(
                                                                              category.url_path
                                                                          ) &&
                                                                      '!text-black bg-skin-megastore-yellow'
                                                                  }`
                                                              )}
                                                          >
                                                              {category.name}
                                                          </Popover.Button>
                                                      </div>
                                                  )}

                                                  <Transition
                                                      show={
                                                          !isMobile
                                                              ? hoverCategory ==
                                                                category.name
                                                                  ? true
                                                                  : false
                                                              : open
                                                      }
                                                      as={Fragment}
                                                      enter="transition ease-out duration-200"
                                                      enterFrom="opacity-0"
                                                      enterTo="opacity-100"
                                                      leave="transition ease-in duration-150"
                                                      leaveFrom="opacity-100"
                                                      leaveTo="opacity-0"
                                                  >
                                                      <Popover.Panel className="absolute top-full inset-x-0 text-md text-skin-muted  bg-white z-10 shadow-lg">
                                                          <div className="grid grid-cols-4 xl:grid-cols-5 gap-y-10 gap-x-8 py-6 px-12">
                                                              <div className="col-span-3 xl:col-span-4">
                                                                  <div
                                                                      className={`grid grid-cols-3 ${
                                                                          // added check for Angebote category so it's not on multiple rows, as requested
                                                                          category.name ==
                                                                          'Angebote'
                                                                              ? 'lg'
                                                                              : 'xl'
                                                                      }:grid-cols-4 gap-y-10 gap-x-8`}
                                                                  >
                                                                      {category.children
                                                                          .sort(
                                                                              (
                                                                                  a,
                                                                                  b
                                                                              ) =>
                                                                                  a.position <
                                                                                  b.position
                                                                                      ? -1
                                                                                      : 1
                                                                          )
                                                                          .slice()
                                                                          .map(
                                                                              (
                                                                                  level2
                                                                              ) => (
                                                                                  <div
                                                                                      key={
                                                                                          level2.name
                                                                                      }
                                                                                      className="relative"
                                                                                  >
                                                                                      <Link
                                                                                          href={categoryRedirect(
                                                                                              '/' +
                                                                                                  level2.url_path +
                                                                                                  categoryUrlSuffix
                                                                                          )}
                                                                                      >
                                                                                          <a
                                                                                              id={`${level2.name}-heading`}
                                                                                              className={`${
                                                                                                  category.name ==
                                                                                                  'Angebote'
                                                                                                      ? 'flex flex-col !items-start'
                                                                                                      : ''
                                                                                              } leading-5 text-skin-base text-base ${
                                                                                                  level2
                                                                                                      .children
                                                                                                      .length >
                                                                                                  0
                                                                                                      ? `font-bold text-skin-red  ${
                                                                                                            checkForNumbersRegex(
                                                                                                                router.asPath
                                                                                                                    .split(
                                                                                                                        '/'
                                                                                                                    )
                                                                                                                    .pop()
                                                                                                                    .replace(
                                                                                                                        '.html',
                                                                                                                        ''
                                                                                                                    )
                                                                                                            ) ==
                                                                                                                checkForNumbersRegex(
                                                                                                                    level2.url_key
                                                                                                                ) &&
                                                                                                            'underline'
                                                                                                        }`
                                                                                                      : `!text-sm pl-6 flex items-center ${
                                                                                                            checkForNumbersRegex(
                                                                                                                router.asPath
                                                                                                                    .split(
                                                                                                                        '/'
                                                                                                                    )
                                                                                                                    .pop()
                                                                                                            ) ==
                                                                                                                checkForNumbersRegex(
                                                                                                                    level2.url_key
                                                                                                                ) &&
                                                                                                            'text-skin-red'
                                                                                                        }`
                                                                                              } `}
                                                                                              onClick={() => {
                                                                                                  close();
                                                                                                  localStorage.removeItem(
                                                                                                      'categoryScrolledIndex'
                                                                                                  );
                                                                                              }}
                                                                                          >
                                                                                              {level2
                                                                                                  .children
                                                                                                  .length ==
                                                                                                  0 && (
                                                                                                  <ArrowCircleRightIcon className="h-4 w-4 absolute top-0.5 left-0 text-skin-primary" />
                                                                                              )}
                                                                                              <span className="mb-2">
                                                                                                  {
                                                                                                      level2.name
                                                                                                  }
                                                                                              </span>
                                                                                              {category.name ==
                                                                                                  'Angebote' && (
                                                                                                  <div className="relative w-full pb-[50%]">
                                                                                                      <Image
                                                                                                          alt={
                                                                                                              level2.name
                                                                                                          }
                                                                                                          src={
                                                                                                              level2.image
                                                                                                                  ? '/pub' +
                                                                                                                    new URL(
                                                                                                                        level2.image
                                                                                                                    )
                                                                                                                        ?.pathname
                                                                                                                  : '/pub/media/catalog/product/placeholder/default/placeholder.jpg'
                                                                                                          }
                                                                                                          layout="fill"
                                                                                                          objectFit="contain"
                                                                                                          objectPosition={
                                                                                                              'left'
                                                                                                          }
                                                                                                      />
                                                                                                  </div>
                                                                                              )}
                                                                                          </a>
                                                                                      </Link>
                                                                                      <ul
                                                                                          role="list"
                                                                                          aria-labelledby={`${level2.name}-heading`}
                                                                                          className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                                                      >
                                                                                          {level2.children
                                                                                              .sort(
                                                                                                  (
                                                                                                      a,
                                                                                                      b
                                                                                                  ) =>
                                                                                                      a.position <
                                                                                                      b.position
                                                                                                          ? -1
                                                                                                          : 1
                                                                                              )
                                                                                              .map(
                                                                                                  (
                                                                                                      level3
                                                                                                  ) => (
                                                                                                      <li
                                                                                                          key={
                                                                                                              level3.name
                                                                                                          }
                                                                                                          className="relative pl-6 flex items-center"
                                                                                                      >
                                                                                                          <Link
                                                                                                              href={categoryRedirect(
                                                                                                                  {
                                                                                                                      pathname:
                                                                                                                          '/[...pageUrl]',
                                                                                                                      query: {
                                                                                                                          pageUrl:
                                                                                                                              encodeURIComponent(
                                                                                                                                  level3.url_path +
                                                                                                                                      categoryUrlSuffix
                                                                                                                              )
                                                                                                                      }
                                                                                                                  }
                                                                                                              )}
                                                                                                              as={`/${
                                                                                                                  level3.url_path +
                                                                                                                  categoryUrlSuffix
                                                                                                              }`}
                                                                                                          >
                                                                                                              <a
                                                                                                                  className={`text-sm leading-5 font-normal normal-case text-skin-base ${
                                                                                                                      checkForNumbersRegex(
                                                                                                                          router.asPath
                                                                                                                              .split(
                                                                                                                                  '/'
                                                                                                                              )
                                                                                                                              .pop()
                                                                                                                              .replace(
                                                                                                                                  '.html',
                                                                                                                                  ''
                                                                                                                              )
                                                                                                                      ) ==
                                                                                                                          checkForNumbersRegex(
                                                                                                                              level3.url_key
                                                                                                                          ) &&
                                                                                                                      'text-skin-red'
                                                                                                                  }`}
                                                                                                                  onClick={() => {
                                                                                                                      close();
                                                                                                                      localStorage.removeItem(
                                                                                                                          'categoryScrolledIndex'
                                                                                                                      );
                                                                                                                  }}
                                                                                                              >
                                                                                                                  <ArrowCircleRightIcon className="h-4 w-4 absolute top-0.5 left-0 text-skin-primary" />
                                                                                                                  {
                                                                                                                      level3.name
                                                                                                                  }
                                                                                                              </a>
                                                                                                          </Link>
                                                                                                      </li>
                                                                                                  )
                                                                                              )}
                                                                                      </ul>
                                                                                  </div>
                                                                              )
                                                                          )}
                                                                  </div>
                                                              </div>
                                                              <div
                                                                  className={
                                                                      'flex items-center'
                                                                  }
                                                              >
                                                                  <CmsBlock
                                                                      identifiers={[
                                                                          'category_trend_' +
                                                                              category.id
                                                                      ]}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <Link
                                                              passHref
                                                              href={categoryRedirect(
                                                                  `/${
                                                                      category.url_path +
                                                                      categoryUrlSuffix
                                                                  }`
                                                              )}
                                                          >
                                                              <div
                                                                  className="p-2 text-center text-skin-base bg-skin-site-bg-alternate cursor-pointer"
                                                                  onClick={() => {
                                                                      close();
                                                                      localStorage.removeItem(
                                                                          'categoryScrolledIndex'
                                                                      );
                                                                  }}
                                                              >
                                                                  <ul>
                                                                      <li className="flex items-center justify-center font-bold">
                                                                          <ArrowCircleRightIcon className="h-4 w-4 mr-2" />{' '}
                                                                          Gesamte
                                                                          Kategorie
                                                                          entdecken
                                                                      </li>
                                                                  </ul>
                                                              </div>
                                                          </Link>
                                                      </Popover.Panel>
                                                  </Transition>
                                              </>
                                          )}
                                      </Popover>
                                  </div>
                              ) : (
                                  <Link
                                      key={category.name}
                                      href={categoryRedirect(
                                          `/${
                                              category.url_path +
                                              categoryUrlSuffix
                                          }`
                                      )}
                                  >
                                      <a
                                          className={
                                              category.name == 'Sale'
                                                  ? `flex items-center uppercase py-2 px-4 text-white font-bold hover:text-black hover:bg-skin-megastore-yellow ${
                                                        checkForNumbersRegex(
                                                            router.asPath
                                                                .split('/')
                                                                .pop()
                                                                .replace(
                                                                    '.html',
                                                                    ''
                                                                )
                                                        ) ==
                                                            checkForNumbersRegex(
                                                                category.url_path
                                                            ) &&
                                                        '!text-black bg-skin-megastore-yellow'
                                                    }`
                                                  : `flex items-center text-white font-bold uppercase px-4 hover:text-black hover:bg-skin-megastore-yellow ${
                                                        checkForNumbersRegex(
                                                            router.asPath
                                                                .split('/')
                                                                .pop()
                                                                .replace(
                                                                    '.html',
                                                                    ''
                                                                )
                                                        ) ==
                                                            checkForNumbersRegex(
                                                                category.url_path
                                                            ) &&
                                                        '!text-black bg-skin-megastore-yellow'
                                                    }`
                                          }
                                          onClick={() => {
                                              localStorage.removeItem(
                                                  'categoryScrolledIndex'
                                              );
                                          }}
                                      >
                                          {category.name}
                                      </a>
                                  </Link>
                              )
                          ) : null
                      )
                    : null}

                {staticItems.pages.map((page) => (
                    <Link key={page.name} href={categoryRedirect(page.href)}>
                        <a
                            className="flex items-center text-base uppercase px-5 py-2 text-skin-base hover:text-skin-primary-hover hover:bg-skin-dirt-white-bg font-semibold"
                            onClick={close}
                        >
                            {page.name}
                        </a>
                    </Link>
                ))}
            </div>
        </Popover.Group>
    );
};

export default DesktopNavigation;
