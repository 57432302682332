'use client';
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toggleDrawer } from '../../store/actions/app';
import { useDispatch } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';

interface AvailableFilialsInfobox {
    children: React.ReactNode;
    narrowMode?: boolean;
}

const AvailableFilialsInfobox: React.FC<AvailableFilialsInfobox> = (
    props: AvailableFilialsInfobox
) => {
    const { narrowMode, children } = props;
    const dispatch = useDispatch();
    const app = useSelector((state: any) => state.app);
    const drawer = app.drawer;
    const isOpen = drawer == 'availableFilials';
    const toggleInfobox = (payload: string) => dispatch(toggleDrawer(payload));

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-30 mt-[57px] overflow-hidden lg:mt-0"
                onClose={() => {
                    toggleInfobox('availableFilials');
                }}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div
                        className={`fixed inset-y-0 right-0 flex w-full ${
                            narrowMode ? 'max-w-md' : 'max-w-xl'
                        } mt-[57px] lg:mt-0`}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="relative w-full">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                    <div className="py-6">
                                        <div className="flex items-center justify-between px-4 pb-6">
                                            <Dialog.Title className="mb-0 text-lg font-semibold lg:text-3xl">
                                                Filialen
                                            </Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="rounded-full border-2 border-solid border-black p-1 text-black"
                                                    onClick={() =>
                                                        toggleInfobox(
                                                            'availableFilials'
                                                        )
                                                    }
                                                >
                                                    <span className="sr-only">
                                                        Close panel
                                                    </span>

                                                    <XIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-4">{children}</div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default AvailableFilialsInfobox;
