const log = store => next => action => {
    const result = next(action);
    switch (action.type) {
        case 'ADD_STOREZONE':
            localStorage.setItem('storeZone', action.payload);
            return result;
        case 'ADD_SHIPPINGZONE':
            localStorage.setItem('shippingZone', action.payload);
            return result;
        case 'ADD_STOREPICKUP':
            localStorage.setItem('storePickup', action.payload);
            return result;
        case 'ADD_GROUPEDORDER':
            localStorage.setItem('groupedOrder', action.payload);
            return result;
        case 'ADD_SHIPPINGSTORE':
            localStorage.setItem('shippingStore', action.payload);
            return result;
        case 'ADD_SELECTEDPICKUP':
            if (action.payload) {
                localStorage.setItem('selectedPickup', action.payload);
            } else {
                localStorage.setItem('selectedPickup', action.payload);
                store.dispatch({
                    type: 'ADD_PICKUP_ADDRESS',
                    payload: {}
                });
                store.dispatch({ type: 'CHECKOUT/RESET', payload: {} });
            }
            return result;
        default:
            return result;
    }
};

export default log;
