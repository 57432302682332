import React from 'react';
import { shape, string } from 'prop-types';

const FieldIcons = (props) => {
    const { after, before, children } = props;

    const style = {
        '--iconsBefore': before ? 1 : 0,
        '--iconsAfter': after ? 1 : 0
    };

    return (
        <span className="w-full block" style={style}>
            <span>{children}</span>
            <span>{before}</span>
            <span>{after}</span>
        </span>
    );
};

FieldIcons.propTypes = {
    styles: shape({
        after: string,
        before: string,
        root: string
    })
};

export default FieldIcons;
