import Link from 'next/link';
import React from 'react';
import HomeIcon from './images/home.svg';
import EuroIcon from './images/euro.png';
import CalendarIcon from './images/calendar.svg';
import ToolIcon from './images/tool.svg';
import TruckIcon from './images/truck.svg';
import BrochureIcon from './images/brochure.svg';
import Image from 'next/image';
import { useRouter } from 'next/router';

const HeaderList = () => {
    const router = useRouter();
    const url = router.asPath;
    const isCheckoutPage =
        url?.startsWith('/checkout') && !url?.startsWith('/checkout/paypal/');

    return (
        <>
            {isCheckoutPage ? null : (
                <div className="py-4 bg-[#eeeeee] font-semibold">
                    <ul className="content-container items-center justify-between w-full flex">
                        <li className="flex items-center cursor-pointer hover:text-skin-megastore-yellow transition-colors duration-150 text-xs">
                            <Link passHref href="/moebeldiscounter">
                                <div className="flex items-center">
                                    <HomeIcon className="mr-2" />
                                    13x in Deutschland
                                </div>
                            </Link>
                        </li>
                        <li className="flex items-center cursor-pointer hover:text-skin-megastore-yellow transition-colors duration-150 text-xs">
                            <Link passHref href="/service">
                                <div className="flex items-center">
                                    <div className="flex mr-2">
                                        <Image
                                            src={EuroIcon}
                                            width={24}
                                            height={24}
                                            alt="Euro sign"
                                        />
                                    </div>
                                    Bestpreisgarantie
                                </div>
                            </Link>
                        </li>
                        <li className="flex items-center cursor-pointer hover:text-skin-megastore-yellow transition-colors duration-150 text-xs">
                            <Link passHref href="/beratungstermin">
                                <div className="flex items-center">
                                    <CalendarIcon className="mr-2" />{' '}
                                    <span className="hidden 2xl:block">
                                        Wunschtermin online vereinbaren
                                    </span>
                                    <span className="block 2xl:hidden">
                                        Wunschtermin
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className="flex items-center cursor-pointer hover:text-skin-megastore-yellow transition-colors duration-150 text-xs">
                            <Link passHref href="/service">
                                <div className="flex items-center">
                                    <ToolIcon className="mr-2" />
                                    <span className="hidden 2xl:block">
                                        Professionelle Montage möglich
                                    </span>
                                    <span className="block 2xl:hidden">
                                        Montage
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className="flex items-center cursor-pointer hover:text-skin-megastore-yellow transition-colors duration-150 text-xs">
                            <Link passHref href="/service">
                                <div className="flex items-center">
                                    <TruckIcon className="mr-2" />
                                    <span className="hidden 2xl:block">
                                        Lieferung bis in die Wohnung
                                    </span>
                                    <span className="block 2xl:hidden">
                                        Lieferung
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className="flex items-center cursor-pointer hover:text-skin-megastore-yellow transition-colors duration-150 text-xs">
                            <Link passHref href="/prospekte">
                                <div className="flex items-center">
                                    <BrochureIcon className="mr-2" />
                                    Prospekte
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default HeaderList;
