import React from 'react';
import Link from 'next/link';

import Logo from '../../images/logo.svg';

const KitchenTesterHeader = () => {
    return (
        <div className="bg-white">
            {/* Mobile menu */}

            <header className="relative bg-white">
                <div className="border-solid border-slate-200">
                    <div>
                        <div
                            aria-label="Top"
                            className="content-container top-0 fixed w-full z-30 bg-white border-b border-solid border-skin-primary lg:hidden"
                        >
                            {/* Mobile Links */}
                            <div className="flex justify-between align-center lg:hidden">
                                <div className="flex justify-between items-center align-center lg:hidden">
                                    {/* Mobile logo */}
                                    <div className="block">
                                        <Link href="/" passHref>
                                            <a aria-label="Opti-Wohnwelt.de">
                                                <Logo className={'my-3 h-8'} />
                                            </a>
                                        </Link>
                                    </div>
                                    {/* End Mobile logo */}
                                </div>
                            </div>
                        </div>
                        <div className="content-container border-t border-solid border-skin-primary lg:border-none px-0 lg:px-5 xl:px-12 mt-[52.5px] lg:mt-0">
                            {/* Browser links */}
                            <div className="hidden lg:block">
                                <div className="flex items-center">
                                    {/* Browser logo */}
                                    <div className="ml-4 flex lg:ml-0 hidden lg:block">
                                        <Link href="/" passHref>
                                            <a aria-label="Opti-Wohnwelt.de">
                                                <Logo className="lg:h-14 my-5 cursor-pointer" />
                                            </a>
                                        </Link>
                                    </div>
                                    {/* End Browser logo */}
                                </div>
                            </div>
                            {/* End Browser Links */}
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default KitchenTesterHeader;
