import React, { useRef, useEffect, useState } from 'react';
import classes from './autocompleteWindow.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Highlight from 'react-highlighter';
import axios from 'axios';
import SearchIcon from './img/search_icon_red.svg';
import CloseIcon from '../../images/close.svg';
import { TrashIcon, XIcon, ClockIcon } from '@heroicons/react/outline';
import { autocompleteWindowTemplate } from '../LoadingContent/loadingTemplates';
import { CookieSetOptions } from 'universal-cookie';
import { Form } from 'informed';

const _ = require('lodash');

const AutocompleteWindow = (props: AutocompleteWindowProps) => {
    const {
        handleSubmit,
        handleClose,
        isOpen,
        autocompleteData,
        setAutocompleteData,
        searchHistory,
        setCookie,
        removeCookie
    } = props;

    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState<string | string[]>('');

    const textInputRef = useRef<HTMLInputElement>(null);

    const router = useRouter();

    const textSuggestions =
        autocompleteData && autocompleteData.textSuggestions;
    const attributeSuggestions =
        autocompleteData && autocompleteData.attributeSuggestions;
    const products = autocompleteData && autocompleteData.products;
    const landingPage = autocompleteData && autocompleteData?.landingPage;
    const promotion = autocompleteData && autocompleteData?.promotion;

    useEffect(() => {
        router.query.query
            ? setInputValue(router.query.query)
            : setInputValue('');

        setAutocompleteData(null);
    }, [router]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';

            setLoading(true);

            axios
                .get('/findologic', {
                    params: {
                        query: '',
                        outputAttrib: ['cat', 'product', 'suggest', 'vendor'],
                        count: 8,
                        type: 'result_v4'
                    }
                })
                .then((response) => {
                    setAutocompleteData({
                        attributeSuggestions:
                            inputValue && response.data.attributeSuggestions,
                        products: response.data.products,
                        textSuggestions:
                            inputValue && response.data.textSuggestions,
                        landingPage: inputValue && response.data.landingpage,
                        promotion: inputValue && response.data.promotion
                    });

                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            document.body.style.overflow = 'visible';
        }

        const inputTimeout = setTimeout(() => {
            textInputRef?.current?.focus();
        }, 40);

        return () => {
            clearTimeout(inputTimeout);
        };
    }, [isOpen, setAutocompleteData]);

    const getResults = (e: { target: { value: string } }) => {
        setLoading(true);

        axios
            .get('/findologic', {
                params: {
                    query: e.target.value,
                    outputAttrib: ['cat', 'product', 'suggest', 'vendor'],
                    count: 8,
                    type: 'result_v4'
                }
            })
            .then((response) => {
                setAutocompleteData({
                    attributeSuggestions: response.data.attributeSuggestions,
                    products: response.data.products,
                    textSuggestions: response.data.textSuggestions,
                    landingPage: response.data.landingpage,
                    promotion: response.data.promotion
                });

                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const debounceResults = _.debounce(getResults, 200);

    const hasResults =
        (textSuggestions && textSuggestions.length > 0) ||
        (attributeSuggestions && attributeSuggestions.vendor) ||
        (attributeSuggestions && attributeSuggestions.cat) ||
        landingPage;

    return (
        <div
            className={`fixed top-0 left-0 flex justify-center items-center h-full w-full bg-black ${
                isOpen ? 'visible bg-opacity-25' : 'invisible bg-opacity-0'
            } z-40 transition-all duration-150 ease-in-out`}
            onClick={handleClose}
        >
            <div
                className={
                    isOpen
                        ? 'absolute top-4 left-2/4 -translate-x-2/4 flex flex-col justify-self-start w-900 bg-white opacity-1 visible scale-100 transition-all origin-top text-sm z-50 w-[900px]'
                        : 'absolute top-4 left-2/4 -translate-x-2/4 flex flex-col justify-self-start w-900 bg-white opacity-0 invisible scale-90 transition-all origin-top text-sm w-[900px]'
                }
                onClick={(e) => e.stopPropagation()}
            >
                <div className="relative py-[17px] px-44">
                    <form
                        autoComplete="off"
                        action="/suche"
                        onSubmit={(e) =>
                            handleSubmit(
                                e,
                                textInputRef.current?.value as string
                            )
                        }
                        id="searchForm"
                        className="flex justify-center"
                    >
                        <input
                            id="autocompleteSearch"
                            type="text"
                            onChange={(e) => {
                                debounceResults(e);
                                setInputValue(e.target.value);
                            }}
                            ref={textInputRef}
                            className="max-w-64 mr-2.5 p-1.5 text-base border-solid border-b border-skin-red-accent outline-none placeholder:text-skin-muted"
                            onClick={(e) => e.stopPropagation()}
                            value={inputValue}
                        />

                        <button type="submit">
                            <SearchIcon className="h-7" />
                        </button>
                    </form>
                    <button
                        onClick={handleClose}
                        className="absolute top-4 right-4 w-10 h-10"
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className="flex border-solid border-t-1 border-t border-skin-primary">
                    {/* left column */}
                    {!hasResults && searchHistory && (
                        <div className="w-[300px] border-solid border-r border-skin-primary">
                            <Form className="h-full">
                                <div className="flex flex-col h-full p-4">
                                    <h3>Deine Suchhistorie</h3>

                                    <ul className="space-y-1">
                                        {searchHistory.map((term: string) => (
                                            <li
                                                className="flex items-center"
                                                key={term}
                                            >
                                                <ClockIcon className="h-4 w-4 mr-2" />
                                                <Link
                                                    passHref
                                                    href={`/suche?query=${term}`}
                                                >
                                                    <a
                                                        className="w-full shortenText text-base font-medium transition-all"
                                                        onClick={handleClose}
                                                    >
                                                        {term}
                                                    </a>
                                                </Link>

                                                <button
                                                    className="ml-auto"
                                                    onClick={() => {
                                                        const filtered =
                                                            searchHistory.filter(
                                                                (
                                                                    item: string
                                                                ) =>
                                                                    item !==
                                                                    term
                                                            );

                                                        setCookie(
                                                            'search_history',
                                                            filtered.join(','),
                                                            {
                                                                path: '/'
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <XIcon className="h-4 w-4 text-skin-red" />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                    <button
                                        className="flex items-center mt-auto text-skin-red text-sm"
                                        onClick={() => {
                                            removeCookie('search_history', {
                                                path: '/'
                                            });
                                        }}
                                    >
                                        <TrashIcon className="h-5 w-5 mr-2" />
                                        <span>Alle löschen</span>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    )}
                    {hasResults && (
                        <div className="w-[300px] border-solid border-r border-skin-primary">
                            {textSuggestions && textSuggestions.length > 0 && (
                                <div className="p-4">
                                    <h3>Suchvorschläge</h3>
                                    <ul>
                                        {textSuggestions.map(
                                            (
                                                ts: { text: string },
                                                index: number
                                            ) => (
                                                <li key={index}>
                                                    <Link
                                                        passHref
                                                        href={`/suche?query=${ts.text}`}
                                                    >
                                                        <a
                                                            className="font-medium transition-all"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            <Highlight
                                                                search={
                                                                    router.query
                                                                        .query
                                                                        ? router
                                                                              .query
                                                                              .query
                                                                        : inputValue
                                                                        ? inputValue
                                                                        : ''
                                                                }
                                                                matchClass={
                                                                    classes.highlight
                                                                }
                                                            >
                                                                {ts.text
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                    ts.text.slice(
                                                                        1
                                                                    )}
                                                            </Highlight>
                                                        </a>
                                                    </Link>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            )}

                            {attributeSuggestions &&
                                attributeSuggestions.vendor && (
                                    <div className="p-4">
                                        <h3>Hersteller</h3>
                                        <ul>
                                            {attributeSuggestions.vendor.map(
                                                (
                                                    v: { text: string },
                                                    index: number
                                                ) => (
                                                    <li key={index}>
                                                        <Link
                                                            passHref
                                                            href={`/suche?query=&vendor=${encodeURIComponent(
                                                                v.text
                                                            )}`}
                                                        >
                                                            <a
                                                                className="transition-all"
                                                                onClick={
                                                                    handleClose
                                                                }
                                                            >
                                                                <Highlight
                                                                    search={
                                                                        router
                                                                            .query
                                                                            .query
                                                                            ? router
                                                                                  .query
                                                                                  .query
                                                                            : inputValue
                                                                            ? inputValue
                                                                            : ''
                                                                    }
                                                                    matchClass={
                                                                        classes.highlight
                                                                    }
                                                                >
                                                                    {v.text}
                                                                </Highlight>
                                                            </a>
                                                        </Link>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}

                            {attributeSuggestions && attributeSuggestions.cat && (
                                <div className="p-4">
                                    <h3>Kategorie</h3>
                                    <ul>
                                        {attributeSuggestions.cat.map(
                                            (
                                                c: { text: string },
                                                index: number
                                            ) => (
                                                <li key={index}>
                                                    <Link
                                                        passHref
                                                        href={`/suche?query=&cat=${encodeURIComponent(
                                                            c.text
                                                        )}`}
                                                    >
                                                        <a
                                                            className="font-medium transition-all"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            <Highlight
                                                                search={
                                                                    router.query
                                                                        .query
                                                                        ? router
                                                                              .query
                                                                              .query
                                                                        : inputValue
                                                                        ? inputValue
                                                                        : ''
                                                                }
                                                                matchClass={
                                                                    classes.highlight
                                                                }
                                                            >
                                                                {c.text}
                                                            </Highlight>
                                                        </a>
                                                    </Link>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            )}

                            {landingPage && (
                                <div className="p-4">
                                    <h3>Inhalte</h3>
                                    <Link passHref href={landingPage.url}>
                                        <a
                                            className="transition-all"
                                            onClick={handleClose}
                                        >
                                            <Highlight
                                                search={
                                                    router.query.query
                                                        ? router.query.query
                                                        : inputValue
                                                        ? inputValue
                                                        : ''
                                                }
                                                matchClass={classes.highlight}
                                            >
                                                {landingPage.name}
                                            </Highlight>
                                        </a>
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}

                    {/* right column */}
                    {products && products.length > 0 ? (
                        <div className="p-4 w-full">
                            <h3>Produkte</h3>
                            <ul className="grid grid-cols-4 gap-8">
                                {products.map((p: Product) => {
                                    let origin = new URL(p.url).origin;
                                    const thumbnailUrl = p.imageUrl
                                        ? new URL(p.imageUrl)?.pathname
                                        : '/pub/media/catalog/product/placeholder/default/placeholder.jpg';

                                    return (
                                        <li key={p.id}>
                                            <Link
                                                href={p.url.replace(origin, '')}
                                                passHref
                                            >
                                                <a
                                                    className="cursor-pointer transition-all hover:text-skin-primary-hover"
                                                    onClick={handleClose}
                                                >
                                                    <Image
                                                        src={thumbnailUrl}
                                                        height={100}
                                                        width={150}
                                                        alt={p.text}
                                                        className="object-scale-down m-2.5"
                                                        placeholder="blur"
                                                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkkJOrBwABHwC9LJRitAAAAABJRU5ErkJggg=="
                                                    />
                                                    <div
                                                        className={
                                                            classes.productName
                                                        }
                                                    >
                                                        <Highlight
                                                            search={
                                                                router.query
                                                                    .query
                                                                    ? router
                                                                          .query
                                                                          .query
                                                                    : inputValue
                                                                    ? inputValue
                                                                    : ''
                                                            }
                                                            matchClass={
                                                                classes.highlight
                                                            }
                                                        >
                                                            {p.text}
                                                        </Highlight>
                                                    </div>
                                                </a>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : inputValue && !loading ? (
                        <div className="p-4">
                            <h3>
                                Für Ihre Suche &quot;{inputValue}&quot; wurden
                                keine Ergebnisse gefunden
                            </h3>
                        </div>
                    ) : (
                        autocompleteWindowTemplate
                    )}
                </div>

                {inputValue && products && products.length > 0 && (
                    <div className="flex flex-col p-5 border-solid border-t border-skin-primary">
                        {promotion && (
                            <div className="relative">
                                <Link
                                    href={promotion.url.replace(
                                        'https://www.opti-wohnwelt.de',
                                        ''
                                    )}
                                >
                                    <a
                                        className="grid justify-items-center"
                                        onClick={handleClose}
                                    >
                                        <img
                                            src={promotion.imageUrl}
                                            alt={promotion.name}
                                            className="mb-4"
                                        />
                                    </a>
                                </Link>
                            </div>
                        )}

                        <Link href={`/suche?query=${inputValue}`}>
                            <a
                                className="ml-auto font-medium cursor-pointer shortenText max-w-full"
                                onClick={handleClose}
                            >
                                Alle Ergebnisse anzeigen für:&nbsp;
                                <Highlight
                                    search={inputValue ? inputValue : ''}
                                    matchClass={classes.highlight}
                                >
                                    {inputValue ? inputValue : ''}
                                </Highlight>
                            </a>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

interface AutocompleteWindowProps {
    handleSubmit: (e: React.FormEvent<HTMLFormElement>, value: string) => void;
    handleClose: () => void;
    isOpen: boolean;
    setAutocompleteData: React.Dispatch<
        React.SetStateAction<AutocompleteData | null>
    >;
    autocompleteData: AutocompleteData | null;
    searchHistory: string[];
    setCookie: (
        name: 'search_history',
        value: any,
        options?: CookieSetOptions | undefined
    ) => void;
    removeCookie: (
        name: 'search_history',
        options?: CookieSetOptions | undefined
    ) => void;
}

interface AutocompleteData {
    products?: [];
    textSuggestions?: [];
    attributeSuggestions?: { vendor: []; cat: [] };
    landingPage?: { name: string; url: string };
    promotion?: { name: string; url: string; imageUrl: string };
}

interface Product {
    id: number;
    url: string;
    imageUrl: string;
    text: string;
}

export default AutocompleteWindow;
