import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from './img/search-interface-symbol.svg';
import axios from 'axios';
import router from 'next/router';
import { Form, Text } from 'informed';
import { ClockIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import { useCookies } from 'react-cookie';
import Link from 'next/link';
const _ = require('lodash');

const SearchBarMobile = () => {
    const [isOpen, setIsOpen] = useState<'suggestions' | 'history' | boolean>(
        false
    );
    const [autocompleteData, setAutocompleteData] = useState({
        textSuggestions: []
    });

    const [cookies, setCookie, removeCookie] = useCookies(['search_history']);

    const wrapperRef = useRef<HTMLDivElement>(null);
    useClickOutsideDetecter(wrapperRef);

    function useClickOutsideDetecter(ref: React.RefObject<HTMLDivElement>) {
        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (
                    ref.current &&
                    !ref.current.contains(event.target as Node)
                ) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    const handleSubmit = (values: { search?: string }) => {
        const searchStr = values.search;

        if (searchStr != null && searchStr.trim().length > 0) {
            router.push(`/suche?query=${searchStr}`);

            if (cookies.search_history) {
                const searchHistoryList = cookies.search_history.split(',');

                if (!searchHistoryList.includes(values.search)) {
                    if (searchHistoryList.length == 5) {
                        searchHistoryList.shift();
                        searchHistoryList.push(values.search);
                    } else {
                        searchHistoryList.push(values.search);
                    }
                }

                setCookie('search_history', searchHistoryList.join(','), {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 14 // lasts for 14 days
                });
            } else {
                setCookie('search_history', values.search, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 14
                });
            }

            setAutocompleteData({ textSuggestions: [] });
            setIsOpen(false);
        }
    };

    const RenderTextSuggestions = ({
        clearSearch
    }: {
        clearSearch: Function;
    }) => (
        <>
            <ul id="searchMenu">
                {autocompleteData.textSuggestions?.map(
                    (ts: { text: string }, index) => {
                        return (
                            <li
                                className="p-4 border-b border-solid cursor-pointer"
                                key={index}
                                onClick={() => {
                                    router.push(`/suche?query=${ts.text}`);
                                    clearSearch();
                                    setAutocompleteData({
                                        textSuggestions: []
                                    });
                                }}
                                tabIndex={0}
                            >
                                {ts.text}
                            </li>
                        );
                    }
                )}
            </ul>
        </>
    );

    const inputText = () => {
        return router.query.query
            ? (router.query.query as string)
            : 'Suchbegriff eingeben';
    };

    const searchHistory =
        cookies.search_history && cookies.search_history.split(',');

    return (
        <Form
            onSubmit={(
                values: { search?: string } & { [key: string]: boolean }
            ) => handleSubmit(values)}
            autoComplete="off"
        >
            {({ formApi }) => {
                return (
                    <div
                        ref={wrapperRef}
                        className="relative flex justify-between py-2"
                    >
                        <Text
                            field="search"
                            type="text"
                            className="truncate flex items-center text-sm w-full pl-4 border-none rounded-none outline-none placeholder:text-skin-muted"
                            placeholder={inputText()}
                            onChange={(e) => {
                                if (e.target.value) {
                                    _.debounce(() => {
                                        axios
                                            .get('/findologic', {
                                                params: {
                                                    query: e.target.value,
                                                    outputAttrib: [
                                                        'cat',
                                                        'product',
                                                        'suggest',
                                                        'vendor'
                                                    ],
                                                    count: 8,
                                                    type: 'result_v4'
                                                }
                                            })
                                            .then((response) => {
                                                if (
                                                    response.data
                                                        .textSuggestions
                                                        .length > 0
                                                ) {
                                                    setIsOpen('suggestions');
                                                } else if (searchHistory) {
                                                    setIsOpen('history');
                                                } else {
                                                    setIsOpen(false);
                                                }

                                                setAutocompleteData({
                                                    textSuggestions:
                                                        response.data
                                                            .textSuggestions
                                                });
                                            });
                                    }, 200)();
                                } else {
                                    searchHistory && setIsOpen('history');

                                    setAutocompleteData({
                                        textSuggestions: []
                                    });
                                }
                            }}
                            onFocus={() =>
                                searchHistory
                                    ? setIsOpen('history')
                                    : autocompleteData.textSuggestions.length >
                                          0 && setIsOpen('suggestions')
                            }
                            autoComplete="off"
                        />
                        <button
                            aria-label="Suche"
                            id="searchButton"
                            type="submit"
                            className="flex items-center py-2 px-2 bg-skin-red-accent"
                            tabIndex={0}
                        >
                            <SearchIcon className="w-4 h-4" />
                        </button>
                        <div className="absolute top-full w-full bg-white z-20 border-t border-solid border-skin-primary shadow-md">
                            {isOpen == 'suggestions' ? (
                                <RenderTextSuggestions
                                    clearSearch={() =>
                                        formApi.setValue('search', '')
                                    }
                                />
                            ) : (
                                isOpen == 'history' &&
                                searchHistory && (
                                    <div>
                                        <div className="flex items-center justify-between p-4">
                                            <span className="text-skin-muted">
                                                Deine Suchhistorie
                                            </span>
                                        </div>
                                        <ul>
                                            {searchHistory.map(
                                                (term: string) => (
                                                    <li
                                                        className="flex items-center p-4 border-b border-solid cursor-pointer"
                                                        key={term}
                                                    >
                                                        <Link
                                                            href={`/suche?query=${term}`}
                                                            passHref
                                                        >
                                                            <>
                                                                <ClockIcon className="h-4 w-4 mr-4" />
                                                                <a
                                                                    className="shortenText w-full"
                                                                    onClick={() =>
                                                                        setIsOpen(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {term}
                                                                </a>
                                                            </>
                                                        </Link>
                                                        <button
                                                            onClick={() => {
                                                                const filtered =
                                                                    searchHistory.filter(
                                                                        (
                                                                            item: string
                                                                        ) =>
                                                                            item !==
                                                                            term
                                                                    );

                                                                setCookie(
                                                                    'search_history',
                                                                    filtered.join(
                                                                        ','
                                                                    ),
                                                                    {
                                                                        path: '/'
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <XIcon className="h-6 w-6 text-skin-red" />
                                                        </button>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                        <button
                                            className="flex items-center p-4 text-skin-red text-sm"
                                            onClick={() => {
                                                removeCookie('search_history', {
                                                    path: '/'
                                                });
                                            }}
                                        >
                                            <TrashIcon className="h-5 w-5 mr-2" />
                                            <span>Alle löschen</span>
                                        </button>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                );
            }}
        </Form>
    );
};

export default SearchBarMobile;
