import {
    addDeliveryInfo,
    addPickUpInfo,
    setCartPickupAndDeliveryState
} from '../store/actions/pickupAndDelivery';
import { useDispatch } from 'react-redux';

export const usePickUpAndDelivery = () => {
    const dispatch = useDispatch();

    const addDeliveryInfoHandler = storePickup => {
        dispatch(addDeliveryInfo(storePickup))
    };

    const addPickUpInfoHandler = storezone => {
        dispatch(addPickUpInfo(storezone))
    };

    const setCartPickupAndDeliveryStateHandler = state => {
        dispatch(setCartPickupAndDeliveryState(state))
    }

    return {
        addDeliveryInfo: addDeliveryInfoHandler,
        addPickUpInfo: addPickUpInfoHandler,
        setCartPickupAndDeliveryState: setCartPickupAndDeliveryStateHandler

    };
};
