import React from 'react';

export const Price = (props) => {
    const {
        priceRange,
        showSaved,
        quantity,
        elPacketPrice,
        priceTag,
        opticardSpecialPriceDisplay,
        cartTotalDesktop
    } = props;
    const priceClass = props.priceClass || '';
    const handledQuantity = quantity != undefined ? Number(quantity) : 1;
    const amountOffClass = props.amountOffClass || 'text-base';
    const discountClass = props.discountClass || '';
    const currency =
        priceRange?.minimum_price?.regular_price?.currency || 'EUR';
    const price = (
        quantity
            ? elPacketPrice
                ? priceRange?.minimum_price?.final_price.value + elPacketPrice
                : priceRange?.minimum_price?.final_price.value
            : elPacketPrice
                ? priceRange?.minimum_price?.final_price.value + elPacketPrice
                : priceRange?.minimum_price?.final_price.value
    ).toLocaleString('de-DE', {
        style: 'currency',
        currency
    });

    const amountOff = priceRange?.minimum_price?.discount.amount_off
        ? priceRange?.minimum_price?.discount.amount_off
        : null;
    const discountOff = priceRange?.minimum_price?.discount.amount_off
        ? priceRange.minimum_price.discount.amount_off.toLocaleString('de-DE', {
            style: 'currency',
            currency
        })
        : null;
    // const discountPercent = priceRange?.minimum_price?.discount.percent_off
    //     ? Math.round(priceRange?.minimum_price.discount.percent_off)
    //     : null;
    return (
        <span className={priceClass}>
            {(discountOff && !opticardSpecialPriceDisplay) ||
                (opticardSpecialPriceDisplay && priceTag) ? (
                <span className="flex-col flex items-end min-h-[16px] lg:min-h-[24px]">
                    {discountOff && !opticardSpecialPriceDisplay ? (
                        <span className='flex flex-col items-end'>
                            {priceTag ?
                                <span className={discountClass + ' no-underline mr-0 !text-xs'}>{priceTag}</span> : null}
                            <span className={discountClass}>
                                {cartTotalDesktop
                                    ? Number(
                                        elPacketPrice
                                            ? priceRange.minimum_price
                                                .regular_price?.value *
                                            quantity +
                                            elPacketPrice
                                            : priceRange.minimum_price
                                                .regular_price?.value *
                                            quantity
                                    ).toLocaleString('de-DE', {
                                        style: 'currency',
                                        currency
                                    })
                                    : Number(
                                        elPacketPrice
                                            ? priceRange.minimum_price
                                                .regular_price?.value +
                                            elPacketPrice
                                            : priceRange.minimum_price
                                                .regular_price?.value
                                    ).toLocaleString('de-DE', {
                                        style: 'currency',
                                        currency
                                    })}
                            </span>
                        </span>
                    ) : null}

                    {opticardSpecialPriceDisplay && priceTag ? (
                        <span className="flex flex-col items-end">
                            {priceTag ? (
                                <span
                                    className={
                                        discountClass +
                                        ' no-underline !text-xs mr-0 text-right'
                                    }
                                >
                                    {priceTag}
                                </span>
                            ) : null}
                            <span className={discountClass}>{price}</span>
                        </span>
                    ) : null}
                </span>
            ) : null}
            <span className="flex flex-col items-end">
                <span className="flex text-skin-red-accent">
                    {opticardSpecialPriceDisplay && priceTag
                        ? Number(opticardSpecialPriceDisplay).toLocaleString(
                            'de-DE',
                            {
                                style: 'currency',
                                currency
                            }
                        )
                        : price}
                </span>
                {amountOff && showSaved ? (
                    <span className={amountOffClass}>
                        Sie sparen{' '}
                        {amountOff.toLocaleString('de-DE', {
                            style: 'currency',
                            currency
                        })}
                    </span>
                ) : null}
            </span>
        </span>
    );
};
