const categoryRedirect = (url: string) => {
    const redirects = [
        { source: '/angebote-2423/prospekte', destination: '/prospekte' },
        {
            source: '/angebote-2423/sofort-verfugbar',
            destination: '/suche?query=&fast_delivery=Ja'
        }
    ];

    //CUSTOM REDIRECTS
    const customRedirect = redirects.filter((f) => f.source === url);

    if (customRedirect.length > 0) {
        return customRedirect[0].destination;
    } else {
        return url;
    }
    //END CUSTOM REDIRECTS
};

export default categoryRedirect;
