import React, { Suspense, useState } from 'react';
import parse, { domToReact } from 'html-react-parser';
import Link from 'next/link';
import { isMobile } from 'react-device-detect';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Tab, Disclosure } from '@headlessui/react';
import dynamic from 'next/dynamic';
import isJsonString from '../../util/checkStringIsJsonValid';
// import PagebuilderPagination from '../PagebuilderPagination/PagebuilderPagination';

//const BannerSlider = React.lazy(() => import('../BannerSlider'));
const BannerSlider = dynamic(() => import('../BannerSlider'));
const SliderModule = dynamic(() => import('../SliderModule'));
const TopSellerProducts = dynamic(() => import('../TopSellerProducts'));
const SeoText = dynamic(() => import('../SeoText'));
const Slider = dynamic(() => import('react-slick'));
const ScrollBox = dynamic(() => import('../ScrollBox'));
const StoreCmsInfo = dynamic(() => import('../Store/storeCmsInfo'));
const Image = dynamic(() => import('next/image'));
const PagebuilderProductBlock = dynamic(() =>
    import('../PagebuilderProductBlock')
);

const RichContent = (props) => {
    const { html } = props;
    const [readmorebtnState, setReadmorebtnState] = useState('closed');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const options = {
        replace: function (domNode) {
            if (
                domNode.name == 'ol' &&
                domNode.attribs.class.includes('product-items')
            ) {
                const productsSkus = domNode.children.map((x) => {
                    if (x?.attribs?.['data-product-sku']) {
                        return x.attribs['data-product-sku'];
                    }
                });
                const fileteredSkus = productsSkus.filter(
                    (x) => x !== undefined
                );
                return (
                    <PagebuilderProductBlock
                        productsSkus={fileteredSkus}
                        classes={domNode.attribs.class}
                    />
                );
            }
            // if (
            //     domNode.type == 'tag' &&
            //     domNode?.attribs?.class?.includes('products-grid')
            // ) {
            //     return (
            //         <div>
            //             <PagebuilderPagination
            //                 productsClass={domNode.attribs.class}
            //             >
            //                 {domToReact(domNode.children, options)}
            //             </PagebuilderPagination>
            //         </div>
            //     );
            // }
            if (
                domNode.type == 'tag' &&
                domNode.name === 'redirect' &&
                domNode.attribs.url
            ) {
                return (
                    <Suspense fallback={null}>
                        {(window.location.href = domNode.attribs.url)}
                    </Suspense>
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.name === 'a' &&
                domNode.attribs.href &&
                domNode.attribs.target != '_blank' &&
                domNode.attribs.type != 'anchor'
            ) {
                return (
                    <Link
                        id={domNode.attribs.id}
                        href={domNode.attribs.href}
                        passHref
                    >
                        <a className={domNode.attribs.class}>
                            {domToReact(domNode.children, options)}
                        </a>
                    </Link>
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.name === 'bannerslider' &&
                domNode.attribs.id
            ) {
                return (
                    <Suspense fallback={null}>
                        <BannerSlider
                            id={Number(domNode.attribs.id)}
                            aspectRatio={domNode.attribs.aspectratio}
                        />
                    </Suspense>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'carousel') {
                const dotsCativeClass =
                    domNode.attribs.dots == undefined
                        ? 'activeDots'
                        : domNode.attribs.dots == 'false'
                        ? ''
                        : 'activeDots';
                const sliderSettings = {
                    dots:
                        domNode.attribs.dots == undefined
                            ? true
                            : domNode.attribs.dots == 'false'
                            ? false
                            : true,
                    arrows:
                        domNode.attribs.arrows == undefined
                            ? false
                            : domNode.attribs.arrows == 'true'
                            ? true
                            : false,
                    speed: 500,
                    slidesToShow:
                        domNode.attribs.maxitemstoshow == undefined
                            ? 4
                            : Number(domNode.attribs.maxitemstoshow),
                    slidesToScroll:
                        domNode.attribs.maxitemstoshow == undefined
                            ? 4
                            : Number(domNode.attribs.maxitemstoshow),
                    infinite: false,
                    className:
                        domNode.attribs.mobiledots == 'true'
                            ? 'activeDots carousel topArrowsStyle'
                            : 'carousel topArrowsStyle',
                    draggable: false,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                dots:
                                    domNode.attribs.dots == undefined
                                        ? true
                                        : domNode.attribs.dots == 'false'
                                        ? false
                                        : true,
                                arrows:
                                    domNode.attribs.arrows == undefined
                                        ? false
                                        : domNode.attribs.arrows == 'true'
                                        ? true
                                        : false,
                                infinite: true,
                                centerMode: false,
                                speed: 500,
                                slidesToShow:
                                    Number(domNode.attribs.maxitemstoshow) < 3
                                        ? Number(domNode.attribs.maxitemstoshow)
                                        : 3,
                                slidesToScroll:
                                    Number(domNode.attribs.maxitemstoshow) < 3
                                        ? Number(domNode.attribs.maxitemstoshow)
                                        : 3,
                                className:
                                    domNode.attribs.mobiledots == 'true'
                                        ? 'activeDots carousel topArrowsStyle'
                                        : 'carousel topArrowsStyle',
                                draggable: true
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                dots:
                                    domNode.attribs.mobiledots == 'true'
                                        ? true
                                        : false,
                                arrows:
                                    domNode.attribs.mobiledots == 'true'
                                        ? false
                                        : true,
                                infinite: true,
                                centerMode: false,
                                speed: 500,
                                slidesToShow:
                                    Number(domNode.attribs.maxitemstoshow) < 3
                                        ? Number(domNode.attribs.maxitemstoshow)
                                        : 3,
                                slidesToScroll:
                                    Number(domNode.attribs.maxitemstoshow) < 3
                                        ? Number(domNode.attribs.maxitemstoshow)
                                        : 3,
                                className:
                                    domNode.attribs.mobiledots == 'true'
                                        ? 'activeDots carousel topArrowsStyle'
                                        : 'carousel topArrowsStyle',
                                draggable: true
                            }
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                dots:
                                    domNode.attribs.mobiledots == 'true'
                                        ? true
                                        : false,
                                arrows:
                                    domNode.attribs.mobiledots == 'true'
                                        ? false
                                        : true,
                                infinite: true,
                                centerMode:
                                    Number(domNode.attribs.maxitemstoshow) ==
                                        1 ||
                                    domNode.attribs.disablecentermode == 'true'
                                        ? false
                                        : true,
                                speed: 500,
                                slidesToShow:
                                    Number(domNode.attribs.maxitemstoshow) < 2
                                        ? Number(domNode.attribs.maxitemstoshow)
                                        : 2,
                                slidesToScroll:
                                    Number(domNode.attribs.maxitemstoshow) < 2
                                        ? Number(domNode.attribs.maxitemstoshow)
                                        : 2,
                                className:
                                    domNode.attribs.mobiledots == 'true'
                                        ? 'activeDots carousel topArrowsStyle'
                                        : `carousel topArrowsStyle ${
                                              Number(
                                                  domNode.attribs.maxitemstoshow
                                              ) == 1
                                                  ? ''
                                                  : null
                                          }`,
                                draggable: true
                            }
                        },
                        {
                            breakpoint: 478,
                            settings: {
                                dots: true,
                                arrows: false,
                                infinite: true,
                                centerMode:
                                    Number(domNode.attribs.maxitemstoshow) ==
                                        1 ||
                                    domNode.attribs.disablecentermode == 'true'
                                        ? false
                                        : true,
                                speed: 500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                className:
                                    domNode.attribs.mobiledots == 'true'
                                        ? 'activeDots carousel topArrowsStyle'
                                        : `carousel topArrowsStyle ${
                                              Number(
                                                  domNode.attribs.maxitemstoshow
                                              ) == 1
                                                  ? ''
                                                  : null
                                          }`,
                                draggable: true
                            }
                        },
                        {
                            breakpoint: 420,
                            settings: {
                                dots: true,
                                arrows: false,
                                infinite: true,
                                centerMode:
                                    Number(domNode.attribs.maxitemstoshow) ==
                                        1 ||
                                    domNode.attribs.disablecentermode == 'true'
                                        ? false
                                        : true,
                                speed: 500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                className:
                                    domNode.attribs.mobiledots == 'true'
                                        ? 'activeDots carousel topArrowsStyle'
                                        : `carousel topArrowsStyle ${
                                              Number(
                                                  domNode.attribs.maxitemstoshow
                                              ) == 1
                                                  ? ''
                                                  : null
                                          }`,
                                draggable: true
                            }
                        }
                    ]
                };
                return (
                    <div
                        className={
                            domNode.attribs.sidewide == 'true'
                                ? 'content-container overflow-hidden md:overflow-auto !px-0 md:!px-5 lg:!px-12'
                                : 'content-container'
                        }
                    >
                        {domNode.attribs.headline ? (
                            <h2 className="px-4 md:px-0">
                                {domNode.attribs.headline}
                            </h2>
                        ) : null}
                        <Suspense fallback={null}>
                            <div className="relative">
                                <SliderModule
                                    settings={sliderSettings}
                                    isMobileScrollBox={
                                        domNode.attribs.ismobilescrollbox ==
                                        'true'
                                    }
                                    adjustEdgesClass={'-mx-3'}
                                >
                                    {domToReact(domNode.children, options)}
                                </SliderModule>
                            </div>
                        </Suspense>
                    </div>
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.name === 'topsellerproducts' &&
                domNode.attribs.id
            ) {
                return (
                    <Suspense fallback={null}>
                        <TopSellerProducts
                            id={domNode.attribs.id}
                            mobileColumns={
                                !domNode.attribs.mobilecolumns
                                    ? domNode.attribs.mobilecolumns
                                    : 2
                            }
                            pagination={
                                domNode.attribs.pagination == 'true'
                                    ? domNode.attribs.pagination
                                    : null
                            }
                            noHeadline={true}
                            pright={domNode.attribs.pright}
                            pleft={domNode.attribs.pleft}
                            sliderModeOnlyArrows={true}
                        />
                    </Suspense>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'ceocmscontent') {
                return (
                    <SeoText>{domToReact(domNode.children, options)}</SeoText>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'tabgroup') {
                return (
                    <Tab.Group
                        as="div"
                        selectedIndex={selectedIndex}
                        onChange={setSelectedIndex}
                    >
                        {domToReact(domNode.children, options)}
                    </Tab.Group>
                );
            }
            if (domNode.type == 'tag' && domNode.name === 'tablist') {
                return (
                    <Tab.List as="div" className={domNode.attribs.class}>
                        {domToReact(domNode.children, options)}
                    </Tab.List>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'tab') {
                return (
                    <Tab as="button" className={domNode.attribs.class}>
                        {domToReact(domNode.children, options)}
                    </Tab>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'tabpanels') {
                return (
                    <Tab.Panels>
                        {domToReact(domNode.children, options)}
                    </Tab.Panels>
                );
            }
            if (domNode.type == 'tag' && domNode.name === 'tabpanel') {
                return (
                    <Tab.Panel>
                        {domToReact(domNode.children, options)}
                    </Tab.Panel>
                );
            }
            if (domNode.type == 'tag' && domNode.name === 'disclosure') {
                return (
                    <Disclosure
                        defaultOpen={
                            domNode.attribs.defaultopen == 'true' ? true : false
                        }
                    >
                        {({ open }) => (
                            <div className="relative">
                                {domToReact(domNode.children, options)}
                                {open ? (
                                    <svg
                                        className="absolute pointer-events-none top-5 md:top-6 right-6 w-[12px] h-[8px] md:w-[18px] md:h-[10px]"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 17.74 10.112"
                                    >
                                        <path
                                            id="Path_572"
                                            data-name="Path 572"
                                            d="M8.87,10.112a1.239,1.239,0,0,1-.878-.364L.364,2.121A1.242,1.242,0,0,1,2.121.364L8.87,7.113,15.619.364a1.242,1.242,0,1,1,1.757,1.757L9.748,9.749A1.239,1.239,0,0,1,8.87,10.112Z"
                                            transform="translate(17.74 10.112) rotate(180)"
                                            fill="#353535"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="absolute pointer-events-none top-5 md:top-6 right-6 w-[12px] h-[8px] md:w-[18px] md:h-[10px]"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 17.74 10.112"
                                    >
                                        <path
                                            id="Path_571"
                                            data-name="Path 571"
                                            d="M8.87,10.112a1.239,1.239,0,0,1-.878-.364L.364,2.121A1.242,1.242,0,0,1,2.121.364L8.87,7.113,15.619.364a1.242,1.242,0,1,1,1.757,1.757L9.748,9.749A1.239,1.239,0,0,1,8.87,10.112Z"
                                            fill="#353535"
                                        />
                                    </svg>
                                )}
                            </div>
                        )}
                    </Disclosure>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'scrollbox') {
                return (
                    <ScrollBox disableOnDesktop={true} adjustEdgesClass="-mx-3">
                        {domToReact(domNode.children, options)}
                    </ScrollBox>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'disclosurebutton') {
                return (
                    <Disclosure.Button
                        as="button"
                        className={domNode.attribs.class}
                    >
                        {domToReact(domNode.children, options)}
                    </Disclosure.Button>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'disclosurepanel') {
                return (
                    <Disclosure.Panel
                        as="div"
                        className={domNode.attribs.class}
                    >
                        {domToReact(domNode.children, options)}
                    </Disclosure.Panel>
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.attribs.class === 'pagebuilder-slide-wrapper'
            ) {
                const substringBetween = (s, a, b) => {
                    let p = s.indexOf(a) + a.length;
                    return s.substring(p, s.indexOf(b, p));
                };
                const str = domNode.attribs['data-background-images'];
                const browserImage = substringBetween(
                    str,
                    `\\"desktop_image\\":\\"`,
                    `\\"`
                );
                const mobileImage =
                    str.indexOf('mobile_image') !== -1
                        ? substringBetween(str, `\\"mobile_image\\":\\"`, `\\"`)
                        : null;
                const linkHref = domNode.parent.attribs;

                return (
                    <div className="relative">
                        {isMobile && mobileImage ? (
                            linkHref.href ? (
                                <div className="lg:!hidden w-full">
                                    <Link
                                        href={linkHref.href}
                                        passHref
                                        target={linkHref.target}
                                    >
                                        <a target={linkHref.target}>
                                            <img
                                                src={mobileImage}
                                                alt=""
                                                // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Uw8AAj0BXe1cD0IAAAAASUVORK5CYII="
                                                // layout="responsive"
                                                // width={70}
                                                // height={80}
                                                // priority
                                                // quality={60}
                                            />
                                        </a>
                                    </Link>
                                </div>
                            ) : (
                                <div className="lg:!hidden w-full">
                                    <img
                                        src={mobileImage}
                                        alt=""
                                        // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Uw8AAj0BXe1cD0IAAAAASUVORK5CYII="
                                        // layout="responsive"
                                        // width={70}
                                        // height={80}
                                        // priority
                                        // quality={60}
                                    />
                                </div>
                            )
                        ) : linkHref.href ? (
                            <Link href={linkHref.href} passHref>
                                <a target={linkHref.target}>
                                    <img
                                        src={browserImage}
                                        // alt=""
                                        // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Uw8AAj0BXe1cD0IAAAAASUVORK5CYII="
                                        // layout="responsive"
                                        // width={160}
                                        // height={60}
                                        // priority
                                        // quality={60}
                                    />
                                </a>
                            </Link>
                        ) : (
                            <img
                                src={browserImage}
                                alt=""
                                // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Uw8AAj0BXe1cD0IAAAAASUVORK5CYII="
                                // layout="responsive"
                                // width={160}
                                // height={60}
                                // quality={60}
                                // priority
                            />
                        )}
                        {domToReact(domNode.children, options)}
                    </div>
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.attribs.class &&
                domNode.attribs['data-content-type'] == 'slider'
            ) {
                const isAutoplay =
                    domNode.attribs['data-autoplay'] == 'true' ? true : false;
                const settings = {
                    dots: true,
                    arrows: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                    autoplay: isAutoplay
                };
                return (
                    <div className={domNode.attribs.class}>
                        <SliderModule
                            settings={settings}
                            isMobileScrollBox={false}
                            adjustEdgesClass={'-mx-3'}
                        >
                            {domToReact(domNode.children, options)}
                        </SliderModule>
                    </div>
                );
            }

            if (domNode.type == 'tag' && domNode.name == 'globalsitemsg') {
                const handledMessages = domNode.attribs.messages;
                const decodedMessages =
                    handledMessages && isJsonString(handledMessages)
                        ? JSON.parse(handledMessages)
                        : null;

                const settings = {
                    dots: false,
                    arrows: false,
                    autoplay: true,
                    infinite: true,
                    speed: 500,
                    autoplaySpeed: 6000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: true,
                    pauseOnHover: false,
                    adaptiveHeight: true
                };

                return decodedMessages &&
                    Array.isArray(decodedMessages) &&
                    decodedMessages.length > 0 ? (
                    <div style={{ background: domNode.attribs.bgcolor }}>
                        {decodedMessages.length == 1 && decodedMessages[0] ? (
                            <div className="content-container flex justify-center items-center">
                                <p
                                    className="my-0 text-white py-2 text-center"
                                    style={{ color: domNode.attribs.textcolor }}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: decodedMessages[0]
                                        }}
                                    />
                                </p>
                            </div>
                        ) : (
                            <div>
                                <Slider {...settings}>
                                    {decodedMessages.map((sliderItem) => (
                                        <p
                                            key={Math.random()}
                                            className="my-0 text-white py-2 px-4 text-center"
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: sliderItem
                                                }}
                                                style={{
                                                    color: domNode.attribs
                                                        .textcolor
                                                }}
                                            />
                                        </p>
                                    ))}
                                </Slider>
                            </div>
                        )}
                    </div>
                ) : null;
            }

            if (
                domNode.type == 'tag' &&
                domNode.name === 'storecmsinfo' &&
                domNode.attribs.id
            ) {
                return (
                    <StoreCmsInfo
                        id={domNode.attribs.id}
                        type={domNode.attribs.type}
                    />
                );
            }
        }
    };

    // Even if empty, render a div with no content, for styling purposes.
    if (!html) {
        return <div />;
    }

    const unescapeHTML = (escapedHTML) => {
        return escapedHTML
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&');
    };
    return <div id="richContentBody">{parse(unescapeHTML(html), options)}</div>;
};
export default RichContent;
