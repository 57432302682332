import React, { useEffect, useState } from 'react';
import Checkbox from '../Checkbox/checkbox';
import CmsBlock from '../CmsBlock/cmsBlock';

const NewsletterFooterBlock = () => {
    const [policiesAreAccepted, setPoliciesAreAccepted] = useState(false);

    useEffect(() => {
        const signInElm = document.getElementById(
            'signIn'
        ) as HTMLButtonElement;

        const signInElmMobile = document.getElementById(
            'signInMobile'
        ) as HTMLButtonElement;
        if (signInElm && signInElmMobile) {
            if (!policiesAreAccepted) {
                signInElm.disabled = true;
                signInElmMobile.disabled = true;
            } else {
                signInElm.disabled = false;
                signInElmMobile.disabled = false;
            }
        }
    }, [policiesAreAccepted]); // eslint-disable-line

    return (
        <div className="flex items-center bg-[#F3F3F3] mt-12 py-12">
            <div className="content-container">
                {' '}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:px-12">
                    <div>
                        <h2>Jetzt anmelden und 5€ Gutschein sichern.</h2>
                        <div className="text-sm mb-3">
                            Opti Newsletter abonnieren und keine Angebote mehr
                            verpassen. Den Gutschein erhalten Sie per Mail für
                            Ihre erste Bestellung ab 100 Euro. Abmeldung
                            jederzeit möglich.
                        </div>
                    </div>
                    <div className="grid grid-cols-1 place-content-center">
                        <CmsBlock identifiers={['newsletter_sign_in_footer']} />
                        <Checkbox
                            field="registerFormVisibility"
                            label={
                                "Ja, ich möchte per Newsletter über Trends, Aktionen und Gutscheine informiert werden. Mehr zum <a href='/datenschutz'>Datenschutz</a> hier."
                            }
                            id="registerFormVisibility"
                            onClick={() =>
                                setPoliciesAreAccepted(!policiesAreAccepted)
                            }
                            skipParse={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsletterFooterBlock;
