import copyToClipboard from './copyToClipboard';

export const copyCode = (copyText, id) => {
    if (copyText) {
        const savedCode = copyText.textContent;
        savedCode && savedCode != 'Kopiert' ?
            sessionStorage.setItem(id, savedCode) : null

        copyText.addEventListener('click', async () => {
            copyText.disabled = true;
            copyText.textContent != 'Kopiert'
                ? copyToClipboard(copyText.textContent)
                : null;
            copyText.textContent = 'Kopiert';
            await setTimeout(() => {
                copyText.textContent = sessionStorage.getItem(id);
                copyText.disabled = false;
            }, 2000);
        });
    }
}