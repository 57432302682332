const checkSpedition = (deliveryType) => {
    const isSpedition = deliveryType == 28227 ||
        deliveryType == 28228 ||
        deliveryType == 987133 ||
        deliveryType == 987144
        ? true
        : false;

    const deliveryPrice = !deliveryType ? null : deliveryType == 28228 || deliveryType == 987133
        ? 19.9
        : deliveryType == 28227 ||
            deliveryType == 987144
            ? 69.9
            : 5.95;
    return {
        isSpedition,
        deliveryPrice
    }
};
export default checkSpedition;
