import { connect } from 'react-redux';

const WishlistCount = ({
    wishlistCount,
    isMobile
}: {
    wishlistCount: number;
    isMobile?: boolean;
}) => {
    return wishlistCount &&
        typeof wishlistCount == 'number' &&
        wishlistCount > 0 ? (
        <span
            className={`absolute ${
                isMobile ? 'top-3 right-0.5' : 'top-0 -right-1'
            } flex items-center justify-center h-4 w-4 bg-skin-red-accent rounded-full text-xs font-bold text-white`}
        >
            {wishlistCount}
        </span>
    ) : null;
};

const mapStateToProps = ({
    wishlist
}: {
    wishlist: { wishlistData: { wishlist: { items: [] } } };
}) => {
    const wishlistCount: number =
        wishlist?.wishlistData?.wishlist?.items?.length;
    return {
        wishlistCount
    };
};

export default connect(mapStateToProps)(WishlistCount);
