import React from 'react';

interface FieldProps {
    children: React.ReactNode;
    classes?: string;
}

const Field = (props: FieldProps) => {
    const { children, classes } = props;

    return <div className={`${classes ? classes : 'w-full'}`}>{children}</div>;
};

export default Field;
