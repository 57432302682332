import React, { useEffect, useState } from 'react';
import SearchIcon from './img/search_icon_white.svg';
import AutocompleteWindow from './autocompleteWindow';
import router from 'next/router';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../store/actions/app';
import { ChevronDownIcon } from '@heroicons/react/solid';
import AvailableFilialsInfobox from '../Cart/availableFilialsInfobox';
import GAutocomplete from '../GAutocompleteField';
import RenderMessages from '../RenderMessages';
import AvailableFilials from '../Cart/availableFilials';
import { useQuery } from '@apollo/client';
import PICKUP_LOCATION from '../../queries/pickupLocations.graphql';

const SearchBarDesktop = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [autocompleteData, setAutocompleteData] =
        useState<AutocompleteData | null>({
            products: [],
            textSuggestions: [],
            attributeSuggestions: { vendor: [], cat: [] }
        });
    const [cookies, setCookie, removeCookie] = useCookies(['search_history']);

    // navigate on submit
    const handleSubmit = (
        e: React.FormEvent<HTMLFormElement>,
        value: string
    ) => {
        e.preventDefault();

        router.push(`/suche?query=${value}`);

        if (cookies.search_history) {
            const searchHistoryList = cookies.search_history.split(',');

            if (searchHistoryList.length == 5) {
                searchHistoryList.shift();
                searchHistoryList.push(value);
            } else {
                !searchHistoryList.includes(value) &&
                    searchHistoryList.push(value);
            }

            setCookie('search_history', searchHistoryList.join(','), {
                path: '/',
                maxAge: 60 * 60 * 24 * 14 // lasts for 14 days
            });
        } else {
            setCookie('search_history', value, {
                path: '/',
                maxAge: 60 * 60 * 24 * 14
            });
        }

        handleClose();
    };

    const handleClose = () => {
        setIsOpen(false);
        document.body.classList.remove('disabled_scroll');
    };

    const inputText = () => {
        return router.query.query
            ? (router.query.query as string)
            : router.query.cat
            ? (router.query.cat as string)
            : 'Suchbegriff eingeben';
    };

    const pickupAndDelivery = useSelector(
        (state: any) => state.pickupAndDelivery
    );
    const pickupInfo = pickupAndDelivery?.pickupInfo;
    const storeName =
        pickupInfo?.charAt(0).toUpperCase() + pickupInfo?.slice(1);

    const { data } = useQuery(PICKUP_LOCATION);
    const stores = data?.pickupLocations?.items
        ? [...data?.pickupLocations?.items]
        : [];

    const onlyMegastores = stores.map((store: any) => {
        const name = store.name.toLowerCase();
        if (name.includes('megastore')) {
            return store;
        }
    });

    const handledStores = onlyMegastores.filter(
        (store: any) => store != undefined
    );

    const dispatch = useDispatch();
    const toggleInfobox = (payload: string) => dispatch(toggleDrawer(payload));

    //GA Location
    const cookies_policies = useSelector(
        (state: any) => state.app.cookies_policies
    );
    const isGMEnabled =
        cookies_policies && cookies_policies.s1104 ? true : false;

    const [inputValue, setInputValue] = useState<{
        value: string;
        searched: boolean;
    } | null>(null);
    const [myLatitude, setMyLatitude] = useState(
        window.myLat != undefined ? window.myLat : ''
    );
    const [myLongitude, setMyLongitude] = useState(
        window.myLong != undefined ? window.myLong : ''
    );

    const [geolocationEnabled, setGeolocationEnabled] = useState(false);
    const [nearestStore, setNearestStore] = useState(0);
    const [gFieldLat, setGFieldLat] = useState<string | null>(null);
    const [gFieldLng, setGFieldLng] = useState<string | null>(null);

    const [geolocationState, setGeolocationState] = useState<string | null>(
        null
    );

    const showPlaceDetails = (place: any) => {
        setNearestStore(0);
        if (place) {
            setGFieldLat(place.lat);
            setGFieldLng(place.lng);
        }
    };

    const showAllStores = () => {
        setGFieldLat(null);
        setGFieldLng(null);
        setNearestStore(0);
        setInputValue(null);
    };
    useEffect(() => {
        navigator.permissions &&
            navigator.permissions
                .query({ name: 'geolocation' })
                .then((PermissionStatus) => {
                    if (PermissionStatus.state == 'granted') {
                        getMyLocation();
                    }

                    setGeolocationState(PermissionStatus.state);
                });
    }, [setGeolocationState]);

    const getMyLocation = () => {
        const location = window.navigator && window.navigator.geolocation;
        const options = {
            enableHighAccuracy: false,
            timeout: 50000,
            maximumAge: 18
        };

        if (location) {
            location.getCurrentPosition(
                (position) => {
                    setMyLatitude(position.coords.latitude.toString());
                    setMyLongitude(position.coords.longitude.toString());
                    setGeolocationEnabled(true);
                    window.myLat = position.coords.latitude;
                    window.myLong = position.coords.longitude;
                },
                (error) => {
                    if (error) {
                        setGeolocationState('denied');
                    }
                },
                options
            );
        }
    };

    useEffect(() => {
        if (geolocationEnabled) {
            getMyLocation();
        }
    }, [geolocationEnabled]);

    return (
        <div className="flex w-full items-center justify-center">
            <div className="ml-auto">
                <div
                    className="flex cursor-pointer"
                    role="presentation"
                    onClick={() => {
                        setIsOpen(true);
                        document.body.classList.add('disabled_scroll');
                    }}
                >
                    <div className="flex items-center w-[500px] text-sm px-3.5 placeholder-current border border-solid border-skin-primary outline-none text-skin-muted xl:w-[600px]">
                        <span className="truncate w-full">{inputText()}</span>
                    </div>
                    <div className="px-3.5 py-2 border border-solid border-skin-red-accent bg-skin-red-accent">
                        <SearchIcon className="mt-[1px] h-5 w-6" />
                    </div>
                </div>
                <AutocompleteWindow
                    setAutocompleteData={setAutocompleteData}
                    handleSubmit={handleSubmit}
                    handleClose={handleClose}
                    isOpen={isOpen}
                    autocompleteData={autocompleteData}
                    searchHistory={
                        cookies.search_history
                            ? cookies.search_history.split(',')
                            : null
                    }
                    setCookie={setCookie}
                    removeCookie={removeCookie}
                />
            </div>
            <div className="ml-12 mr-10 h-full w-auto py-1 pl-2">
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        toggleInfobox('availableFilials');
                    }}
                    className="flex items-center justify-center whitespace-nowrap font-medium leading-4"
                >
                    <div className="flex flex-col text-left">
                        <h4 className="text-sm">Meine Filiale</h4>
                        <span className="overflow-hidden text-ellipsis whitespace-nowrap text-xs lg:max-w-[120px]">
                            {storeName ? storeName : 'Filiale wählen'}
                        </span>
                    </div>
                    <ChevronDownIcon className="ml-2 inline-block h-4 w-4" />
                </button>
            </div>
            <AvailableFilialsInfobox>
                <h2>Markt auswählen</h2>
                <p>
                    In welchem Opti-Wohnwelt Markt möchten Sie den Artikel
                    reservieren und abholen?
                </p>
                <div className="gAutocompleteField w-full lg:mb-4">
                    <GAutocomplete
                        onPlaceChanged={showPlaceDetails}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        showAllStores={showAllStores}
                        placeholder="PLZ / Ort eingeben"
                    />
                </div>
                {geolocationState == 'denied' ? (
                    <div className="flex w-full items-center justify-center">
                        <RenderMessages
                            isBox
                            message="Aktivieren Sie Ihren Standort, um Filialen in der Nähe angezeigt zu bekommen."
                            messageStatus="error"
                        />
                    </div>
                ) : null}
                <AvailableFilials
                    geolocationEnabled={geolocationEnabled && isGMEnabled}
                    gFieldLat={gFieldLat}
                    gFieldLng={gFieldLng}
                    stores={handledStores}
                    myLatitude={myLatitude}
                    myLongitude={myLongitude}
                    nearestStore={nearestStore}
                />
            </AvailableFilialsInfobox>
        </div>
    );
};

interface AutocompleteData {
    products?: [];
    textSuggestions?: [];
    attributeSuggestions?: { vendor: []; cat: [] };
}

export default SearchBarDesktop;
