import { ApolloProvider } from '@apollo/client';
import { useApollo } from '~/lib/apollo-client';
import { withCookies } from 'react-cookie';
import { compose } from 'redux';
import App from '~/components/App';
import 'reset-css';
import '~/styles/global.scss';
import { withPasswordProtect } from '@storyofams/next-password-protect';
import {
    FBPixelScript,
    FBPixelProvider
} from '@rivercode/facebook-conversion-api-nextjs/components';

function NextApp({ Component, pageProps, cookies }) {
    // console.log("localStorage; ", localStorage)
    const token = cookies.get('authToken');
    const apolloClient = useApollo(pageProps.initialApolloState, token);

    return (
        <ApolloProvider client={apolloClient}>
            <FBPixelScript />
            <FBPixelProvider>
                <App>
                    <Component {...pageProps} />
                </App>
            </FBPixelProvider>
        </ApolloProvider>
    );
}
export default process.env.PASSWORD_PROTECT
    ? withPasswordProtect(compose(withCookies)(NextApp), {
          // Options go here (optional)
          loginApiUrl: '/api/frontLogin'
      })
    : compose(withCookies)(NextApp);

//export default compose(withCookies)(NextApp);
