import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useCart } from '../../lib/useCart';

interface ProductUpdateProps {
    sku: string;
    productId: string;
    initialQuantity: string;
}

const ProductUpdate = (props: ProductUpdateProps) => {
    const { sku, productId, initialQuantity } = props;
    const quantity = initialQuantity ? initialQuantity : 1;

    const talonProps = useCart({
        quantity: quantity,
        sku: sku,
        productType: 'simple'
    });

    const {
        isAddingItem,
        updateCartLoading,
        handleUpdateCart,
        handleRemoveFromCart,
        updateCartError
    } = talonProps;

    if (updateCartError) {
        console.log('updateCartError: ', updateCartError.message);
    }

    const optionsItems = [];
    const qtyMaxValue = quantity <= 20 ? 20 : quantity;
    for (var i = 1; i <= qtyMaxValue; i++) {
        optionsItems.push(i);
    }

    return (
        <div className="flex-row flex">
            <div className="mb-2 relative block w-16 sm:mb-0">
                <svg
                    className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 412 232"
                >
                    <path
                        d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                        fill="#648299"
                        fillRule="nonzero"
                    />
                </svg>
                <select
                    onChange={(e) => {
                        handleUpdateCart(e.target.value, productId);
                    }}
                    defaultValue={quantity}
                    className="border border-skin-primary text-base font-bold text-gray-600 h-10 pl-3 pr-6 bg-white hover:border-gray-400 focus:outline-none appearance-none mr-4 cursor-pointer"
                >
                    {optionsItems.map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
            </div>
            <button
                className="absolute top-6 right-4"
                disabled={isAddingItem || updateCartLoading}
                type="button"
                onClick={() => handleRemoveFromCart(productId)}
            >
                <XIcon
                    className="block h-6 w-6 text-black font-bold"
                    aria-hidden="true"
                />
            </button>
        </div>
    );
};

export default ProductUpdate;
