// import { useState } from 'react';
import Link from 'next/link';
import React from 'react';
import GoToCheckouT from '../../images/go_to_checkout.svg';
import Button from '../Button';
// import MiniCartShipping from './miniCartShipping';

// type AppliedTaxes = {
//     amount: {
//         value: string;
//         currency: string;
//     };
//     label: string;
// };

// type Discounts = {
//     amount: {
//         value: string;
//         currency: string;
//     };
//     label: string;
// };

interface TotalsProps {
    cartId: string;
    coupons: {
        code: string;
    }[];
    prices: {
        grand_total: {
            value: string;
            currency: string;
        };
        applied_taxes: {
            amount: {
                value: string;
                currency: string;
            };
            label: string;
        };

        discounts: {
            amount: {
                value: string;
                currency: string;
            };
            label: string;
        };
        subtotal_excluding_tax: {
            value: string;
            currency: string;
        };
    };
    regularPricesSum: number;
    amountOffSum: number;
    toggleCart: Function;
}

const Totals = (props: TotalsProps) => {
    const { prices, toggleCart, regularPricesSum, amountOffSum, coupons } =
        props;

    // const [cartShippingPrice, setCartShippingPrice] = useState(0);

    return prices ? (
        <div className="border-t border-solid border-skin-primary p-4 lg:px-16 mt-6">
            <span className="font-bold text-center mb-2 lg:text-xl block">
                Kassenzettel
            </span>
            {regularPricesSum || regularPricesSum == 0 ? (
                <div
                    className={
                        amountOffSum || amountOffSum > 0
                            ? 'flex justify-between text-xl font-semibold lg:py-2'
                            : 'flex justify-between text-xl font-semibold lg:py-2 border-b border-solid border-skin-primary'
                    }
                >
                    <p className="m-0 lg:text-lg">Regulärer Preis:</p>

                    {regularPricesSum || regularPricesSum == 0 ? (
                        <p className="text-base m-0 lg:text-lg">
                            {regularPricesSum.toLocaleString('de-DE', {
                                style: 'currency',
                                currency: 'EUR'
                            })}
                        </p>
                    ) : null}
                </div>
            ) : null}
            {coupons && coupons.length > 0
                ? coupons.map((coupon) => (
                      <div
                          key={coupon.code}
                          className="flex justify-between text-xl font-semibold lg:pb-2 "
                      >
                          {amountOffSum || amountOffSum == 0 ? (
                              <span className="text-base m-0 lg:text-lg text-skin-green">
                                  <span>
                                      Eingelöster Gutschein:{' '}
                                      <span className="block">
                                          {'"' + coupon.code + '"'}
                                      </span>
                                  </span>
                              </span>
                          ) : null}

                          {prices?.discounts && Array.isArray(prices?.discounts)
                              ? prices?.discounts.map((discount) => {
                                    return (
                                        <p
                                            key={discount.amount.value}
                                            className="m-0 lg:text-lg"
                                        >
                                            -
                                            {discount.amount.value.toLocaleString(
                                                'de-DE',
                                                {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                }
                                            )}
                                        </p>
                                    );
                                })
                              : null}
                      </div>
                  ))
                : null}
            {amountOffSum || amountOffSum > 0 ? (
                <div className="flex justify-between text-xl font-semibold border-b border-solid border-skin-primary text-skin-red lg:pb-2">
                    <p className="m-0 lg:text-lg">Sie sparen:</p>

                    {amountOffSum || amountOffSum == 0 ? (
                        <p className="text-base m-0 lg:text-lg">
                            {amountOffSum.toLocaleString('de-DE', {
                                style: 'currency',
                                currency: 'EUR'
                            })}
                        </p>
                    ) : null}
                </div>
            ) : null}
            {prices?.grand_total.value ? (
                <div className="flex justify-between text-xl font-semibold lg:pt-2 lg:text-xl">
                    <p className="m-0 lg:text-lg">Zwischensumme:</p>

                    <p className="text-base m-0 lg:text-lg">
                        {Number(prices?.grand_total.value).toLocaleString(
                            'de-DE',
                            {
                                style: 'currency',
                                currency: prices?.grand_total.currency
                            }
                        )}
                    </p>
                </div>
            ) : null}

            {/* <div className="flex justify-between text-2xl font-normal lg:pt-2 lg:text-2xl">
                <p className="m-0 lg:text-lg">Versandkosten:</p>

                <p className="text-normal m-0 lg:text-lg">
                    {' '}
                    <MiniCartShipping
                        getCartShippingPrice={setCartShippingPrice}
                    />
                </p>
            </div> */}
            {/* <p className="text-center text-xl text-semibold">
                <span>Gesamtpreis</span>
                <span className="block text-sm mb-2 font-semibold text-skin-muted">
                    inkl. MwSt.
                </span>
                <span className="block text-2xl font-bold">
                    {Number(
                        prices?.grand_total.value + cartShippingPrice
                    ).toLocaleString('de-DE', {
                        style: 'currency',
                        currency: prices?.grand_total.currency
                    })}
                </span>
            </p> */}
            <div className="mt-6 grid grid-cols-1 lg:gap-4 lg:grid-cols-2 justify-between items-center">
                <Button
                    className="hidden lg:flex mb-4 lg:mb-0 min-h-[56px] px-6 text-base"
                    onClick={() => toggleCart('cart')}
                    isSecondary
                >
                    Weiter einkaufen
                </Button>

                <Link href={'/cart'} passHref>
                    <Button
                        className="px-6 text-base font-medium whitespace-nowrap"
                        onClick={() => toggleCart('cart')}
                    >
                        <GoToCheckouT className="w-8 h-10 mr-2" />
                        Zum Warenkorb
                    </Button>
                </Link>

                <Button
                    className="lg:hidden mt-4 lg:mb-0 min-h-[56px] px-6 text-base"
                    onClick={() => toggleCart('cart')}
                    isSecondary
                >
                    Weiter einkaufen
                </Button>
            </div>
        </div>
    ) : null;
};

export default Totals;
