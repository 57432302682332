export const addDeliveryInfo = (payload) => {
    return {
        type: 'ADD_DELIVERY_INFO',
        payload
    };
};
export const addPickUpInfo = (payload) => {
    return {
        type: 'ADD_PICKUP_INFO',
        payload
    };
};

export const setCartPickupAndDeliveryState = (payload) => {
    return {
        type: 'SET_CARTPICKUO_AND_DELIVERY_STATE',
        payload
    };
};