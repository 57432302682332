import { XIcon } from '@heroicons/react/outline';
import router from 'next/router';

interface RenderMessagesProps {
    message: string;
    messageStatus: 'info' | 'error' | 'success' | 'warning';
    isBox: boolean;
    closeIcon?: boolean;
    setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    goBackUrl?: string;
}

const RenderMessages = (props: RenderMessagesProps) => {
    const {
        message,
        messageStatus,
        isBox,
        closeIcon,
        setIsVisible,
        goBackUrl
    } = props;

    const messageBox =
        messageStatus === 'info' ? (
            <div
                className={`flex items-center ${
                    isBox ? 'bg-blue-100' : ''
                } p-4 mb-4 text-lg text-blue-700`}
                role="alert"
            >
                <svg
                    className="w-5 h-5 inline mr-3 shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                    ></path>
                </svg>
                {message}
                {closeIcon && setIsVisible && (
                    <XIcon
                        className="ml-4 h-6 w-6 cursor-pointer shrink-0"
                        onClick={() => {
                            if (goBackUrl) {
                                setIsVisible(false);
                                router.push(goBackUrl);
                            } else {
                                setIsVisible(false);
                            }
                        }}
                    />
                )}
            </div>
        ) : messageStatus === 'error' ? (
            <div
                className={`flex items-center ${
                    isBox ? 'bg-red-100' : ''
                } p-4 mb-4 text-lg text-red-700`}
                role="alert"
            >
                <svg
                    className="w-5 h-5 inline mr-3 shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                    ></path>
                </svg>
                {message}
                {closeIcon && setIsVisible && (
                    <XIcon
                        className="ml-4 h-6 w-6 cursor-pointer shrink-0"
                        onClick={() => {
                            if (goBackUrl) {
                                setIsVisible(false);
                                router.push(goBackUrl);
                            } else {
                                setIsVisible(false);
                            }
                        }}
                    />
                )}
            </div>
        ) : messageStatus === 'success' ? (
            <div
                className={`flex items-center ${
                    isBox ? 'bg-green-100' : ''
                } p-4 mb-4 text-lg text-green-700`}
                role="alert"
            >
                <svg
                    className="w-5 h-5 inline mr-3 shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                    ></path>
                </svg>
                {message}
                {closeIcon && setIsVisible && (
                    <XIcon
                        className="ml-4 h-6 w-6 cursor-pointer shrink-0"
                        onClick={() => {
                            if (goBackUrl) {
                                setIsVisible(false);
                                router.push(goBackUrl);
                            } else {
                                setIsVisible(false);
                            }
                        }}
                    />
                )}
            </div>
        ) : messageStatus === 'warning' ? (
            <div
                className={`flex items-center ${
                    isBox ? 'bg-yellow-100' : ''
                } p-4 mb-4 text-lg text-yellow-700`}
                role="alert"
            >
                <svg
                    className="w-5 h-5 inline mr-3 shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                    ></path>
                </svg>
                {message}
                {closeIcon && setIsVisible && (
                    <XIcon
                        className="ml-4 h-6 w-6 cursor-pointer shrink-0"
                        onClick={() => {
                            if (goBackUrl) {
                                setIsVisible(false);
                                router.push(goBackUrl);
                            } else {
                                setIsVisible(false);
                            }
                        }}
                    />
                )}
            </div>
        ) : null;

    return <div className="max-w-lg mt-5">{messageBox}</div>;
};

export default RenderMessages;
