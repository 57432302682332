import React, { Fragment, useEffect } from 'react';
import { Select, FieldApi, FieldState, useFormApi } from 'informed';
import { Message } from '../Field';
import { ChevronDownIcon } from '@heroicons/react/solid';

interface TextInputProps {
    id: string;
    field: string;
    isRequiredLabel?: boolean;
    // placeholder: string;
    additionalclasses?: string;
    fieldApi?: FieldApi;
    fieldState?: FieldState<string>;
    customLabel: string;
    initialValue?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.ChangeEventHandler<HTMLInputElement>;
    children: JSX.Element | JSX.Element[];
    submitonchange?: boolean;
    className?: string;
    disabled?: boolean;
    noIcon?: boolean;
}

const SelectInput = (props: TextInputProps) => {
    const {
        field,
        id,
        isRequiredLabel,
        initialValue,
        // placeholder,
        additionalclasses,
        fieldState,
        customLabel,
        onChange,
        onBlur,
        children,
        submitonchange,
        className,
        disabled,
        noIcon
    } = props;
    const defaultStyles =
        'text-lg md:text-sm relative w-full border border-skin-primary placeholder-gray-400 focus:outline-none py-2 px-2 leading-6';
    const requiredSymbol = isRequiredLabel ? (
        <span
            className={
                'flex-inline items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 top-[-2px] relative'
            }
        >
            *
        </span>
    ) : null;

    const formApi = useFormApi();

    useEffect(() => {
        if (initialValue) {
            formApi.setValue(field, initialValue);
        }
    }, [initialValue]);

    return (
        <Fragment>
            <div className={className ? className : 'mb-4 w-full'}>
                <div className="relative">
                    <Select
                        initialValue={initialValue}
                        className={additionalclasses + ' peer ' + defaultStyles}
                        id={id}
                        field={field}
                        name={field}
                        onBlur={onBlur}
                        onChange={
                            submitonchange
                                ? () => {
                                      formApi.submitForm();
                                      onChange;
                                  }
                                : onChange
                        }
                        disabled={disabled}
                    >
                        {children}
                    </Select>
                    <label
                        htmlFor={field}
                        className={
                            'absolute left-2 transition-all -top-2 text-xs pointer-events-none bg-white px-2 text-gray-400'
                        }
                    >
                        {customLabel}
                        {requiredSymbol}
                    </label>
                    {!noIcon ? (
                        <ChevronDownIcon className="h-6 w-6 absolute top-2/4 -translate-y-2/4 right-1 pointer-events-none" />
                    ) : null}
                </div>
                {fieldState && fieldState.error && (
                    <Message fieldState={fieldState} />
                )}
            </div>
        </Fragment>
    );
};

export default SelectInput;
