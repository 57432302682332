'use client';
import { useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
    createCartState,
    createCartDetails,
    createCart,
    isUpdatingItemInCart,
    isAddingItemInCart,
    isRemovingItemInCart,
    resetCartState
} from '../store/actions/cart';
import {
    setAvailableShippingMethodsState,
    setUpdateShippingMethods
} from '../store/actions/availableShippingMethods';
import APPLY_COUPON_CODE from '../queries/applyCouponToCart.graphql';
import ADD_SIMPLE_PRODUCT_TO_CART from '../queries/addSimpleProductToCart.graphql';
import UPDATE_CART from '../queries/updateItemInCart.graphql';
import REMOVE_ITEM_CART from '../queries/removeItemFromCart.graphql';
import CREATE_CART_MUTATION from '../queries/createCart.graphql';
import GET_CART_DETAILS from '../queries/getCartDetails.graphql';
import SET_ADDRESSES_ON_CART from '../queries/setAddressesOnCart.graphql';
import SET_ADDRESSES_ON_CART_LOGGED_IN from '../queries/setAddressesOnCartLoggedIn.graphql';
import SET_ADDRESSES_ON_CART_LOGGED_IN_COLLECT from '../queries/setAddressesOnCartLoggedInCollect.graphql';
import GET_AVAILABLE_SHIPPING_METHODS from '../queries/getAvailableShippingMethods.graphql';
import SET_SHIPPING_METHOD_ON_CART from '../queries/setShippingMethodOnCart.graphql';
import SET_GUEST_EMAIL_ON_CART from '../queries/setGuestEmailOnCart.graphql';
import PLACE_ORDER from '../queries/placeOrder.graphql';
import { trackCustomEvent } from './gtm/trackCustomEvent';
import { cheapestShippingMethod } from '../util/cheapestShippingMethod';
import { useRouter } from 'next/router';
import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';

export const useCart = (props) => {
    const router = useRouter();
    const {
        quantity,
        sku,
        productType,
        mainDataLayer,
        customOptions,
        coupon,
        clicked,
        setMutationError
    } = props;
    const handledCustomOptions = [];
    customOptions && Array.isArray(customOptions)
        ? customOptions.map((option) => {
              handledCustomOptions.push({
                  ...option,
                  value_string: JSON.stringify(option.value_string)
              });
          })
        : null;

    const trackCartActions = (action) => {
        trackCustomEvent(action, mainDataLayer);
    };

    const trackUpdateCartAction = (action, mainDataLayerUpdate) => {
        trackCustomEvent(action, mainDataLayerUpdate);
    };

    const metaConsent = useSelector(
        (state) => state?.app?.cookies_policies?.s7
    );

    const trackShippingDataLayer = (cart) => {
        const dataLayer = {
            currency: cart?.details?.prices?.subtotal_including_tax?.currency,
            value: cart?.details?.prices?.subtotal_including_tax?.value,
            items: []
        };

        if (cart?.details?.shipping_addresses?.length > 0) {
            dataLayer.shipping_tier =
                cart?.details?.shipping_addresses[0]?.selected_shipping_method?.method_code;
        }
        if (cart?.details?.applied_coupons?.length > 0) {
            dataLayer.coupon = cart?.details?.applied_coupons[0]?.code;
        }

        cart?.details?.items.map((itm) => {
            dataLayer.items.push({
                item_id: itm.product.sku,
                item_name: itm.product.name,
                currency:
                    itm.product.price_range.minimum_price.final_price.currency,
                price: itm.product.price_range.minimum_price.final_price.value,
                discount: itm.product.price_range.minimum_price.discount
                    .amount_off
                    ? itm.product.price_range.minimum_price.discount.amount_off
                    : 0,
                quantity: itm.quantity
            });
        });

        return dataLayer;
    };

    const trackAddPaymentInfo = (paymentType, cart) => {
        //Track GA Event
        const dataLayer = {
            currency: cart?.details?.prices?.subtotal_including_tax?.currency,
            value: cart?.details?.prices?.subtotal_including_tax?.value,
            payment_type: paymentType,
            items: []
        };

        if (cart?.details?.applied_coupons?.length > 0) {
            dataLayer.coupon = cart?.details?.applied_coupons[0]?.code;
        }

        cart?.details?.items.map((itm) => {
            dataLayer.items.push({
                item_id: itm.product.sku,
                item_name: itm.product.name,
                currency:
                    itm.product.price_range.minimum_price.final_price.currency,
                price: itm.product.price_range.minimum_price.final_price.value,
                discount: itm.product.price_range.minimum_price.discount
                    .amount_off
                    ? itm.product.price_range.minimum_price.discount.amount_off
                    : 0,
                quantity: itm.quantity
            });
        });
        trackCustomEvent('add_payment_info', dataLayer);

        // Track Meta event
        if (metaConsent) {
            const mainDataLayerFB = {
                eventName: 'AddPaymentInfo',
                currency:
                    cart?.details?.prices?.subtotal_including_tax?.currency,
                value: cart?.details?.prices?.subtotal_including_tax?.value,
                content_ids: [],
                contents: [],
                num_items: 0
            };
            let numItems = 0;
            cart?.details?.items.map((item) => {
                mainDataLayerFB.content_ids.push(item.product.sku);
                mainDataLayerFB.contents.push({
                    id: item?.product?.sku,
                    quantity: item?.quantity
                });
                numItems += item?.quantity;
            });
            mainDataLayerFB.num_items = numItems;
            fbEvent(mainDataLayerFB);
        }
    };

    const trackPurchase = (transactionId, cart) => {
        const dataLayer = {
            currency: cart?.details?.prices?.subtotal_including_tax?.currency,
            value: cart?.details?.prices?.subtotal_including_tax?.value,
            transaction_id: transactionId,
            items: []
        };

        if (cart?.details?.prices?.applied_taxes?.length > 0) {
            dataLayer.tax =
                cart?.details?.prices?.applied_taxes[0]?.amount?.value;
        }

        if (cart?.details?.shipping_addresses?.length > 0) {
            dataLayer.shipping =
                cart?.details?.shipping_addresses[0]?.selected_shipping_method?.amount?.value;
        }

        cart?.details?.items.map((itm) => {
            dataLayer.items.push({
                item_id: itm.product.sku,
                item_name: itm.product.name,
                currency:
                    itm.product.price_range.minimum_price.final_price.currency,
                price: itm.product.price_range.minimum_price.final_price.value,
                discount: itm.product.price_range.minimum_price.discount
                    .amount_off
                    ? itm.product.price_range.minimum_price.discount.amount_off
                    : 0,
                quantity: itm.quantity
            });
        });
        trackCustomEvent('purchase', dataLayer);

        //Meta tracking
        if (metaConsent) {
            const mainDataLayerFB = {
                eventName: 'Purchase',
                currency:
                    cart?.details?.prices?.subtotal_including_tax?.currency,
                value: cart?.details?.prices?.subtotal_including_tax?.value,
                content_ids: [],
                contents: [],
                num_items: 0,
                name: transactionId,
                content_type: 'product'
            };
            let numItems = 0;
            cart?.details?.items.map((item) => {
                mainDataLayerFB.content_ids.push(item.product.sku);
                mainDataLayerFB.contents.push({
                    id: item?.product?.sku,
                    quantity: item?.quantity
                });
                numItems += item?.quantity;
            });
            mainDataLayerFB.num_items = numItems;
            fbEvent(mainDataLayerFB);
        }
    };

    const cart = useSelector((state) => state.cart);

    const addToCartQuery =
        productType == 'simple'
            ? ADD_SIMPLE_PRODUCT_TO_CART
            : ADD_SIMPLE_PRODUCT_TO_CART;

    const dispatch = useDispatch();
    const updatingShippingMethods = useSelector(
        (state) => state.availableShippingMethods.updateShippingMethods
    );

    // Get available shipping methods here, because onCompleted has a bug and returns old values
    useEffect(() => {
        if (cart?.cartId) {
            handleGetAvailableShippingMethods();
            dispatch(setUpdateShippingMethods(false));
        }
    }, [cart?.details?.prices?.subtotal_excluding_tax?.value]);

    const [setGuestEmailOnCart, {}] = useMutation(SET_GUEST_EMAIL_ON_CART, {
        onCompleted: async (setGuestEmailOnCartData) => {
            console.log('1. Guest e-mail set.');
            console.log('Cart state: ', cart);
            await dispatch(
                createCartDetails({
                    ...cart.details,
                    email: setGuestEmailOnCartData?.setGuestEmailOnCart?.cart
                        ?.email
                })
            );
            await dispatch(isAddingItemInCart(false));
        },
        onError: (setGuestEmailOnCartError) => {
            console.log(setGuestEmailOnCartError);
            setMutationError &&
                setMutationError({
                    headline: 'Error email already exists',
                    description:
                        'The email you entered is already in use. Please try another one.'
                });
        }
    });

    const [placeOrder, {}] = useMutation(PLACE_ORDER, {
        onCompleted: async (placeOrderData) => {
            console.log('Order Placed.');
            await redirectTo(
                router,
                '/checkout/order/' +
                    placeOrderData?.placeOrder?.order?.order_number
            );
        },
        onError: (placeOrderError) => {
            console.log(placeOrderError.message);
            setMutationError &&
                setMutationError({
                    headline: 'Error placing order',
                    description: 'Failed to place order.'
                });
        }
    });

    const handlePlaceOrder = async () => {
        if (cart.cartId) {
            console.log('Placing Order.');

            await placeOrder({
                variables: {
                    cart_id: cart.cartId
                }
            });
        }
    };

    const [setShippingMethodOnCart, {}] = useMutation(
        SET_SHIPPING_METHOD_ON_CART,
        {
            onCompleted: async (setShippingMethodOnCartData) => {
                console.log('Shipping method set.');
                console.log('Cart state: ', cart);
                await dispatch(
                    createCartDetails({
                        ...cart.details,
                        shipping_addresses:
                            setShippingMethodOnCartData
                                ?.setShippingMethodsOnCart?.cart
                                ?.shipping_addresses,
                        prices: setShippingMethodOnCartData
                            ?.setShippingMethodsOnCart?.cart?.prices
                    })
                );
                //await dispatch(isAddingItemInCart(false));
            },
            onError: (setShippingMethodOnCartError) => {
                dispatch(
                    createCartState({
                        ...cart,
                        addItemError: setShippingMethodOnCartError?.message,
                        isAddingItem: false
                    }),
                    console.log(
                        'Failed to set shipping method on cart.:',
                        setShippingMethodOnCartError?.message
                    ),
                    dispatch(isAddingItemInCart(false))
                );
                setMutationError &&
                    setMutationError({
                        headline: 'Error setting shipping method',
                        description: 'Failed to set shipping method on cart.'
                    });
            }
        }
    );

    const [
        addToCart,
        {
            error: addToCartError,
            loading: addToCartLoading,
            data: addToCartData
        }
    ] = useMutation(addToCartQuery, {
        onCompleted: async (addToCartData) => {
            await dispatch(createCartState({ ...cart, cartId: cart?.cartId }));
            await dispatch(
                createCartDetails({
                    ...cart.details,
                    items: addToCartData?.addSimpleProductsToCart?.cart?.items,
                    prices: addToCartData?.addSimpleProductsToCart?.cart?.prices
                })
            );
            await dispatch(isAddingItemInCart(false));
            await getAvailableShippingMethods({
                variables: {
                    cart_id: cart.cartId
                },
                fetchPolicy: 'cache-and-network'
            });
            if (clicked) {
                let handledCoupons = coupon;
                const couponsApplied = cart?.details?.applied_coupons;
                const excludedCouponIfExists = couponsApplied?.filter((f) => {
                    const allCodes = f.code?.split(';');
                    return !allCodes?.includes(coupon);
                });

                if (
                    excludedCouponIfExists &&
                    excludedCouponIfExists.length > 0
                ) {
                    excludedCouponIfExists.map((item) => {
                        handledCoupons += ';' + item.code;
                    });
                }

                excludedCouponIfExists && excludedCouponIfExists.length === 0
                    ? null
                    : applyCouponCode({
                          variables: {
                              cart_id: cart.cartId,
                              coupon_code: handledCoupons
                          }
                      });
            }
            await router.push('/cart');
        },
        onError: (addToCartError) => {
            dispatch(
                createCartState({
                    ...cart,
                    addItemError: addToCartError?.message,
                    isAddingItem: false
                }),
                dispatch(isAddingItemInCart(false))
            );
        }
    });

    const [applyCouponCode, {}] = useMutation(APPLY_COUPON_CODE, {
        onCompleted: (data) => {
            dispatch(createCartDetails(data.applyCouponToCart.cart));
        },
        onError: () => {}
    });

    const handleAddToCart = async () => {
        await dispatch(isAddingItemInCart(true));
        if (!cart.cartId) {
            await createCartMutation();
        } else {
            if (cart.cartId) {
                await addToCart({
                    variables: {
                        quantity: quantity,
                        cartId: cart.cartId,
                        sku: sku,
                        customizable_options: handledCustomOptions
                            ? handledCustomOptions
                            : []
                    }
                });
                await getAvailableShippingMethods({
                    variables: {
                        cart_id: cart.cartId
                    },
                    fetchPolicy: 'cache-and-network'
                });
            }
        }

        await trackCartActions('add_to_cart');
    };

    const [createCartMutation, {}] = useMutation(CREATE_CART_MUTATION, {
        onCompleted: async (createCartMutationData) => {
            await dispatch(
                createCart({
                    cartId: createCartMutationData?.cartId,
                    addItemError: createCartMutationData?.addItemError
                })
            );
            if (
                createCartMutationData &&
                createCartMutationData?.cart?.items?.length > 0
            ) {
                await dispatch(createCartDetails(createCartMutationData?.cart));
            }
            quantity && sku
                ? await addToCart({
                      variables: {
                          quantity: quantity,
                          cartId: createCartMutationData.cartId,
                          sku: sku,
                          customizable_options: handledCustomOptions
                              ? handledCustomOptions
                              : []
                      }
                  })
                : null;
        },
        onError: (createCartMutationError) => {
            console.log('error: ', createCartMutationError);
        }
    });

    const [
        updateCart,
        {
            error: updateCartError,
            loading: updateCartLoading,
            data: updateCartData
        }
    ] = useMutation(UPDATE_CART, {
        onCompleted: async (updateCartData) => {
            await dispatch(
                createCartState({
                    ...cart,
                    isUpdatingItem: false,
                    updateItemError: cart?.updateItemError?.filter(
                        (f) => f.sku != sku
                    )
                })
            );
            await dispatch(
                createCartDetails({
                    ...cart.details,
                    items: updateCartData?.updateCartItems?.cart?.items,
                    prices: updateCartData?.updateCartItems?.cart?.prices
                })
            );

            await dispatch(isUpdatingItemInCart(false));
        },
        onError: async (updateCartError) => {
            await dispatch(
                createCartState({
                    ...cart,
                    updateItemError: [
                        ...cart.updateItemError,
                        { message: updateCartError?.message, sku }
                    ]
                })
            );
            await dispatch(isUpdatingItemInCart(false));
        }
    });

    const handleUpdateCart = async (
        updateQty,
        productId,
        mainDataLayerUpdate,
        diffQty
    ) => {
        const action = diffQty > 0 ? 'add_to_cart' : 'remove_from_cart';
        await dispatch(isUpdatingItemInCart(true));
        await updateCart({
            variables: {
                cartId: cart.cartId,
                itemId: Number(productId),
                quantity: updateQty,
                customizable_options: handledCustomOptions
                    ? handledCustomOptions
                    : []
            }
        });

        await trackUpdateCartAction(action, mainDataLayerUpdate);
    };

    const [
        removeFromCart,
        {
            error: removeFromCartError,
            loading: removeFromCartLoading,
            data: removeFromCartData
        }
    ] = useMutation(REMOVE_ITEM_CART, {
        onCompleted: async (removeFromCartData) => {
            await dispatch(createCartState({ ...cart, isAddingItem: false }));
            await dispatch(
                createCartDetails({
                    ...cart.details,
                    items: removeFromCartData?.removeItemFromCart?.cart?.items,
                    prices: removeFromCartData?.removeItemFromCart?.cart?.prices
                })
            );
            await dispatch(isRemovingItemInCart(false));
            await dispatch(setUpdateShippingMethods(true));
        },
        onError: (removeFromCartError) => {
            dispatch(
                createCartState({
                    ...cart,
                    removeItemError: removeFromCartError?.message
                })
            );
        }
    });

    const handleRemoveFromCart = async (productId) => {
        await dispatch(isRemovingItemInCart(true));
        await removeFromCart({
            variables: {
                cartId: cart.cartId,
                itemId: Number(productId)
            }
        });

        await trackCartActions('remove_from_cart');
    };

    const handleResetCart = () => {
        dispatch(resetCartState());
    };

    const [
        getCartDetails,
        { error: cartDetailsError, loading: cartDetailsLoading }
    ] = useLazyQuery(GET_CART_DETAILS, {
        onCompleted: (cartDetailsData) => {
            cartDetailsData?.cart?.items?.length > 0
                ? dispatch(createCartDetails(cartDetailsData.cart))
                : null;
        },
        onError: (cartDetailsError) => {
            if (cartDetailsError && cart?.cartId) {
                handleResetCart();
            }
        }
    });

    const handleGetCartDetails = async (cartId) => {
        if (cartId) {
            await getCartDetails({
                variables: {
                    cartId: cartId
                },
                context: {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            'signin_token'
                        )}`
                    }
                }
            });
        }
    };

    const [getAvailableShippingMethods, {}] = useLazyQuery(
        GET_AVAILABLE_SHIPPING_METHODS,
        {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            variables: {
                cart_id: cart.cartId
            },
            onCompleted: async (availableShippingMethodsData) => {
                console.log('3. Available shipping methods fetched.');

                await dispatch(
                    setAvailableShippingMethodsState(
                        availableShippingMethodsData?.availableShippingMethods
                            ?.allMethods
                    )
                );
                await dispatch(setUpdateShippingMethods(false));
            },
            onError: (availableShippingMethodsError) => {
                if (availableShippingMethodsError) {
                    //handleResetCart();
                    console.log('error: ', availableShippingMethodsError);
                }
            }
        }
    );

    const handleGetAvailableShippingMethods = async (cartId) => {
        if (cartId || cart.cartId) {
            const handledCartId = cartId ? cartId : cart.cartId;
            await dispatch(setUpdateShippingMethods(true));
            await getAvailableShippingMethods({
                variables: {
                    cart_id: handledCartId
                },
                fetchPolicy: 'cache-and-network'
            });
        }
    };

    const [
        getAndSetAvailableShippingMethods,
        { data: getAndSetAvailableShippingMethodsData }
    ] = useLazyQuery(GET_AVAILABLE_SHIPPING_METHODS, {
        onCompleted: async () => {
            console.log(
                'Available shipping methods fetched.',
                getAndSetAvailableShippingMethodsData
            );
            await dispatch(
                setAvailableShippingMethodsState(
                    getAndSetAvailableShippingMethodsData
                        ?.availableShippingMethods.allMethods
                )
            );
            if (getAndSetAvailableShippingMethodsData) {
                const selectedMethod = await cheapestShippingMethod(
                    getAndSetAvailableShippingMethodsData
                        ?.availableShippingMethods.allMethods
                );
                await dispatch(setUpdateShippingMethods(false));
                const methodCode = selectedMethod?.code;
                const carrierCode =
                    methodCode != 'paket' ||
                    methodCode != 'paket1' ||
                    methodCode != 'spedition1' ||
                    methodCode != 'spedition2' ||
                    methodCode != 'spedition2_special' ||
                    methodCode != 'free'
                        ? 'owsh1'
                        : 'instore';

                await setShippingMethodOnCart({
                    variables: {
                        carrier_code: carrierCode,
                        method_code: methodCode,
                        cart_id: cart.cartId
                    }
                });
            } else {
                console.log('OOPS No available shipping methods.');
            }

            //await dispatch(isAddingItemInCart(false));
        },
        onError: (getAndSetAvailableShippingMethodsError) => {
            if (getAndSetAvailableShippingMethodsError && cart?.cartId) {
                //handleResetCart();
                console.log('error: ', getAndSetAvailableShippingMethodsError);
            }
            console.log('error: ', getAndSetAvailableShippingMethodsError);
            setMutationError &&
                setMutationError({
                    headline: 'Error fetching shipping methods',
                    description: 'Failed to fetch available shipping methods.'
                });
        }
    });

    const handleGetAndSetAvailableShippingMethods = async (cartId) => {
        if (cartId || cart.cartId) {
            const handledCartId = cartId ? cartId : cart.cartId;
            await dispatch(setUpdateShippingMethods(true));
            await getAndSetAvailableShippingMethods({
                variables: {
                    cart_id: handledCartId
                },
                fetchPolicy: 'cache-and-network'
            });
        }
    };

    const [setAddressesOnCart, {}] = useMutation(SET_ADDRESSES_ON_CART, {
        onCompleted: async (setAddressesOnCartData) => {
            console.log('2. Addresses are set.');
            console.log('Cart state: ', cart);
            await dispatch(
                createCartDetails({
                    ...cart.details,
                    shipping_addresses:
                        setAddressesOnCartData?.setShippingAddressesOnCart?.cart
                            ?.shipping_addresses,
                    available_payment_methods:
                        setAddressesOnCartData?.setBillingAddressOnCart?.cart
                            ?.available_payment_methods,
                    billing_address:
                        setAddressesOnCartData?.setBillingAddressOnCart?.cart
                            ?.billing_address
                })
            );

            await dispatch(isAddingItemInCart(false));
            await trackCustomEvent(
                'add_shipping_info',
                trackShippingDataLayer(cart)
            );
        },
        onError: (setAddressesOnCartError) => {
            dispatch(
                createCartState({
                    ...cart,
                    addItemError: setAddressesOnCartError?.message,
                    isAddingItem: false
                }),
                dispatch(isAddingItemInCart(false))
            );
            setMutationError &&
                setMutationError({
                    headline: 'Error setting addresses',
                    description: 'Failed to set addresses on cart.'
                });
        }
    });

    const [setAddressesOnCartLoggedIn, {}] = useMutation(
        SET_ADDRESSES_ON_CART_LOGGED_IN,
        {
            onCompleted: async (setAddressesOnCartLoggedInData) => {
                console.log('2. Logged in addresses are set.');
                console.log('Cart state: ', cart);
                await dispatch(
                    createCartDetails({
                        ...cart.details,
                        shipping_addresses:
                            setAddressesOnCartLoggedInData
                                ?.setShippingAddressesOnCart?.cart
                                ?.shipping_addresses,
                        available_payment_methods:
                            setAddressesOnCartLoggedInData
                                ?.setBillingAddressOnCart?.cart
                                ?.available_payment_methods,
                        billing_address:
                            setAddressesOnCartLoggedInData
                                ?.setBillingAddressOnCart?.cart?.billing_address
                    })
                );
                await dispatch(isAddingItemInCart(false));
                await trackCustomEvent(
                    'add_shipping_info',
                    trackShippingDataLayer(cart)
                );
            },
            onError: (setAddressesOnCartLoggedInError) => {
                dispatch(
                    createCartState({
                        ...cart,
                        addItemError: setAddressesOnCartLoggedInError?.message,
                        isAddingItem: false
                    }),
                    dispatch(isAddingItemInCart(false))
                );
                setMutationError &&
                    setMutationError({
                        headline: 'Error setting addresses',
                        description: 'Failed to set addresses on cart.'
                    });
            }
        }
    );

    const [setAddressesOnCartLoggedInCollect, {}] = useMutation(
        SET_ADDRESSES_ON_CART_LOGGED_IN_COLLECT,
        {
            onCompleted: async (setAddressesOnCartLoggedInCollectData) => {
                console.log('2. Logged in collect addresses are set.');
                console.log('Cart state: ', cart);
                await dispatch(
                    createCartDetails({
                        ...cart.details,
                        shipping_addresses:
                            setAddressesOnCartLoggedInCollectData
                                ?.setShippingAddressesOnCart?.cart
                                ?.shipping_addresses,
                        available_payment_methods:
                            setAddressesOnCartLoggedInCollectData
                                ?.setBillingAddressOnCart?.cart
                                ?.available_payment_methods,
                        billing_address:
                            setAddressesOnCartLoggedInCollectData
                                ?.setBillingAddressOnCart?.cart?.billing_address
                    })
                );

                await dispatch(isAddingItemInCart(false));
                await trackCustomEvent(
                    'add_shipping_info',
                    trackShippingDataLayer(cart)
                );
            },
            onError: (setAddressesOnCartLoggedInCollectError) => {
                dispatch(
                    createCartState({
                        ...cart,
                        addItemError:
                            setAddressesOnCartLoggedInCollectError?.message,
                        isAddingItem: false
                    }),
                    dispatch(isAddingItemInCart(false))
                );
                setMutationError &&
                    setMutationError({
                        headline: 'Error setting addresses',
                        description: 'Failed to set addresses on cart.'
                    });
            }
        }
    );

    const handleSetAddressesOnCartLoggedIn = async (addressesFormValues) => {
        console.log('Values: ', addressesFormValues);
        await dispatch(isAddingItemInCart(true));
        if (!cart.cartId) {
            await createCartMutation();
        } else {
            if (cart.cartId) {
                await setAddressesOnCartLoggedIn({
                    variables: {
                        cartId: cart.cartId,
                        pickupLocationCode:
                            addressesFormValues.pickup_location_code,
                        shippingCustomerAddressId:
                            addressesFormValues.shippingCustomerAddressId,
                        billingCustomerAddressId:
                            addressesFormValues.billingCustomerAddressId
                    }
                });
            }
            if (addressesFormValues?.pickup_location_code?.length > 0) {
                await setShippingMethodOnCart({
                    variables: {
                        carrier_code: 'instore',
                        method_code: 'pickup',
                        cart_id: cart.cartId
                    }
                });
            } else {
                await handleGetAndSetAvailableShippingMethods();
            }

            await dispatch(isAddingItemInCart(false));
        }
    };

    const handleSetAddressesOnCartLoggedInCollect = async (
        addressesFormValues
    ) => {
        const streetNumber = addressesFormValues.street_number.replaceAll(
            ' ',
            ''
        );
        const signedIn = addressesFormValues.is_logged_in;

        await dispatch(isAddingItemInCart(true));
        if (!cart.cartId) {
            await createCartMutation();
        } else {
            if (cart.cartId) {
                if (!signedIn) {
                    await setGuestEmailOnCart({
                        variables: {
                            cartId: cart.cartId,
                            email: addressesFormValues.email
                        }
                    });
                }
                await setAddressesOnCartLoggedInCollect({
                    variables: {
                        cartId: cart.cartId,
                        shippingPostcode: addressesFormValues.postcode,
                        shippingFirstname: addressesFormValues.firstname,
                        shippingLastname: addressesFormValues.lastname,
                        shippingStreet:
                            addressesFormValues.street + ' ' + streetNumber,
                        shippingCity: addressesFormValues.city,
                        shippingCountryCode: 'DE',
                        shippingTelephone: addressesFormValues.phone,
                        shippingCompany: addressesFormValues.company,
                        pickupLocationCode:
                            addressesFormValues.pickup_location_code,
                        billingCustomerAddressId:
                            addressesFormValues.billingCustomerAddressId
                    }
                });
            }
            if (addressesFormValues?.pickup_location_code?.length > 0) {
                await setShippingMethodOnCart({
                    variables: {
                        carrier_code: 'instore',
                        method_code: 'pickup',
                        cart_id: cart.cartId
                    }
                });
            } else {
                await handleGetAndSetAvailableShippingMethods();
            }

            await dispatch(isAddingItemInCart(false));
        }
    };

    const handleSetAddressesOnCart = async (addressesFormValues) => {
        const billingAddressSameAsShipping =
            addressesFormValues.same_as_shipping;
        const signedIn = addressesFormValues.is_logged_in;
        const streetNumber = addressesFormValues.street_number.replaceAll(
            ' ',
            ''
        );
        const streetNumberBilling = billingAddressSameAsShipping
            ? ''
            : addressesFormValues?.street_number_billing?.replaceAll(' ', '');
        await dispatch(isAddingItemInCart(true));
        if (!cart.cartId) {
            await createCartMutation();
        } else {
            if (cart.cartId) {
                if (!signedIn) {
                    await setGuestEmailOnCart({
                        variables: {
                            cartId: cart.cartId,
                            email: addressesFormValues.email
                        }
                    });
                }
                await setAddressesOnCart({
                    variables: {
                        cartId: cart.cartId,
                        shippingPostcode: addressesFormValues.postcode,
                        shippingFirstname: addressesFormValues.firstname,
                        shippingLastname: addressesFormValues.lastname,
                        shippingStreet:
                            addressesFormValues.street + ' ' + streetNumber,
                        shippingCity: addressesFormValues.city,
                        shippingCountryCode: 'DE',
                        shippingTelephone: addressesFormValues.phone
                            ? addressesFormValues.phone
                            : '',
                        shippingCompany: addressesFormValues.company
                            ? addressesFormValues.company
                            : ' ',
                        pickupLocationCode:
                            addressesFormValues.pickup_location_code,
                        billingPostcode: billingAddressSameAsShipping
                            ? addressesFormValues.postcode
                            : addressesFormValues.postcode_billing,
                        billingFirstname: billingAddressSameAsShipping
                            ? addressesFormValues.firstname
                            : addressesFormValues.firstname_billing,
                        billingLastname: billingAddressSameAsShipping
                            ? addressesFormValues.lastname
                            : addressesFormValues.lastname_billing,
                        billingStreet: billingAddressSameAsShipping
                            ? addressesFormValues.street + ' ' + streetNumber
                            : addressesFormValues.street_billing +
                              ' ' +
                              streetNumberBilling,
                        billingCity: billingAddressSameAsShipping
                            ? addressesFormValues.city
                            : addressesFormValues.city_billing,
                        billingCountryCode: 'DE',
                        billingTelephone:
                            billingAddressSameAsShipping &&
                            addressesFormValues.phone
                                ? addressesFormValues.phone
                                : addressesFormValues.phone_billing
                                ? addressesFormValues.phone_billing
                                : '',
                        billingCompany: addressesFormValues.company_billing
                            ? addressesFormValues.company_billing
                            : ' ',
                        billingSameAsShipping: billingAddressSameAsShipping
                    }
                });
            }
            if (addressesFormValues?.pickup_location_code?.length > 0) {
                await setShippingMethodOnCart({
                    variables: {
                        carrier_code: 'instore',
                        method_code: 'pickup',
                        cart_id: cart.cartId
                    }
                });
            } else {
                await handleGetAndSetAvailableShippingMethods();
            }

            await dispatch(isAddingItemInCart(false));
        }
    };

    return {
        cartDetailsLoading,
        cartDetailsError,
        addToCartError,
        addToCartLoading,
        addToCartData,
        updateCartError,
        updateCartLoading,
        updateCartData,
        removeFromCartError,
        removeFromCartLoading,
        removeFromCartData,
        checkoutStep: cart.checkoutStep,
        isRemovingItem: cart.isRemovingItem,
        isAddingItem: cart.isAddingItem,
        isUpdatingItem: cart.isUpdatingItem,
        isUpdatingShippingMethods:
            updatingShippingMethods.updateShippingMethods,
        isLoading: cart.isLoading,

        handleAddToCart,
        handleResetCart,
        handleSetAddressesOnCart,
        handleSetAddressesOnCartLoggedIn,
        handleSetAddressesOnCartLoggedInCollect,
        getAvailableShippingMethods,
        handleGetAvailableShippingMethods,
        handleUpdateCart,
        handleRemoveFromCart,
        createCartMutation,
        handleGetCartDetails,
        handlePlaceOrder,
        trackAddPaymentInfo,
        trackPurchase
    };
};
