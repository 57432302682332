import React, { MouseEventHandler, ForwardedRef } from 'react';

export const Button = React.forwardRef(
    (props: ButtonProps, ref: ForwardedRef<HTMLAnchorElement>) => {
        const {
            isNextLink,
            isSecondary,
            className: classes,
            style,
            ...rest
        } = props;

        const buttonClass = `flex items-center justify-center p-2 text-sm text-white hover:bg-skin-primary-hover hover:text-white focus:outline-none disabled:opacity-50 disabled:hover:bg-skin-red-accent ${
            isSecondary
                ? 'border border-solid border-skin-primary text-black font-medium hover:text-white'
                : 'bg-skin-red-accent text-white'
        } ${classes}`;

        return !isNextLink ? (
            <button className={buttonClass} {...rest} />
        ) : (
            <a ref={ref} className={buttonClass} {...rest} />
        );
    }
);

Button.displayName = 'Button';

interface ButtonProps {
    type?: 'submit' | 'reset' | 'button';
    onClick?: MouseEventHandler;
    className?: string;
    disabled?: boolean;
    isNextLink?: boolean;
    isSecondary?: boolean;
    children: React.ReactNode;
    style?: {};
}
