import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng
} from 'react-google-places-autocomplete';

interface GAutocompleteTypes {
    onPlaceChanged: Function;
    setAddressKey?: Function;
    setInputValue: Function;
    inputValue: {
        value: string;
        searched: boolean;
    } | null;
    showAllStores: () => void;
    placeholder?: string;
}

const GAutocomplete = (props: GAutocompleteTypes) => {
    const {
        onPlaceChanged,
        setAddressKey,
        setInputValue,
        inputValue,
        showAllStores,
        placeholder
    } = props;
    const [value, setValue]: any = useState('');

    useEffect(() => {
        setInputValue({ value, searched: false });
        if (value) {
            geocodeByPlaceId(value.value.place_id)
                .then((results) => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    onPlaceChanged({ lat, lng });
                });
            setAddressKey && setAddressKey(value.value.place_id);
        } else {
            showAllStores();
        }
    }, [value]); //eslint-disable-line

    return (
        <GooglePlacesAutocomplete
            apiKey="AIzaSyBF75dDrl-MXxM0jjNHdBMbCCqCfPHpWZk"
            apiOptions={{ language: 'de', region: 'de' }}
            autocompletionRequest={{
                componentRestrictions: {
                    country: ['de']
                }
            }}
            selectProps={{
                value: inputValue?.value ? inputValue?.value : '',
                onChange: (val: string) => setValue(val),
                isClearable: true,
                id: 'autocomplete',
                placeholder: placeholder ? placeholder : '',
                noOptionsMessage: () => {
                    return 'Keine Treffer gefunden.';
                },
                loadingMessage: () => {
                    return 'Es lädt.';
                }
            }}
        />
    );
};

export default GAutocomplete;
