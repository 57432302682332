import { connect } from 'react-redux';

interface CartProductCountPropTypes {
    cart?: {
        details: {
            items: { quantity: string }[];
        };
    };
    isMobile?: boolean;
}

const CartProductCount = (props: CartProductCountPropTypes) => {
    const { cart, isMobile } = props;

    let count: number = 0;
    if (cart?.details?.items) {
        {
            cart.details.items.map(
                (item) => (count = Number(count + item.quantity))
            );
        }
    }
    // <span className="minicart-counter">{count}</span>
    return count && typeof count === 'number' && count > 0 ? (
        <span
            className={`absolute ${
                isMobile ? 'top-3 -right-0.5' : '-top-1 -right-2'
            } flex items-center justify-center h-4 w-4 bg-skin-red-accent rounded-full text-xs font-bold text-white`}
        >
            {count}
        </span>
    ) : null;
};

const mapStateToProps = ({ cart }: { cart: object }) => {
    return {
        cart: cart
    };
};

export default connect(mapStateToProps, null)(CartProductCount);
