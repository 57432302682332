const initialState = {
    drawer: null,
    overlay: false,
    searchOpen: false,
    cookies_policies: null,
}

const app = (state = initialState, { type, payload }) => {
    switch (type) {
        case "TOGGLE_DRAWER":
            return {
                ...state,
                drawer: state.drawer ? null : payload,
                overlay: !state.overlay
            }
        case "TOGGLE_SEARCH":
            return {
                ...state,
                searchOpen: !state.searchOpen
            };
        case "SET_COOKIES_POLICIES":
            return {
                ...state,
                cookies_policies: payload
            };
        case "SET_INITIAL_COOKIES_POLICIES":
            return {
                ...state,
                cookies_policies: payload
            };
        default:
            return state
    }
}

export default app;