import React, { useState } from 'react';
import Link from 'next/link';
import SearchBarDesktop from '../SearchBarDesktop/searchBarDesktop';
import SearchBarMobile from '../SearchBarMobile/searchBarMobile';
import UserIcon from '../../images/user_icon.svg';
import UserIconMobile from '../../images/user_icon_mobile.svg';
import HeartIcon from '../../images/heart.svg';
import CartIcon from '../../images/cart_icon.svg';
import CartIconMobile from '../../images/cart_icon_mobile.svg';
import { MenuIcon } from '@heroicons/react/outline';
import LocationMarkerIcon from '../../images/locationMarker.svg';
import Logo from '../../images/logo.svg';
import { toggleDrawer } from '../../store/actions/app';
import CartProductCount from './CartProductCount';
import WishlistCount from './WishlistCount';
import { useSignOut } from '../../talons/SignOut/useSignOut';
import { connect } from 'react-redux';
import SIGN_OUT from '../../queries/signOut.graphql';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';
import LogoutIconMobile from '/images/logout.svg';
import { useRouter } from 'next/router';
import LoginPopup from './LoginPopup';

const Header = (props: NavigationPropTypes) => {
    const {
        categories,
        categoryUrlSuffix,
        // toggleCart,
        toggleNavi,
        isSignedIn,
        drawer,
        loadingNavi
    } = props;

    const [loginPopupOpen, setLoginPopupOpen] = useState(false);

    const router = useRouter();
    const talons = useSignOut({
        signOutMutation: SIGN_OUT
    });
    const url = router.asPath;
    const isCheckoutPage =
        url?.startsWith('/checkout') && !url?.startsWith('/checkout/paypal/');
    const isCheckoutOrderPage = url?.startsWith('/checkout/order/');
    const { handledSignOut } = talons;

    const featuredCategories = [5, 6];
    const staticItems = {
        pages: []
    };

    const isOpen = drawer == 'navi';

    return (
        <>
            {isCheckoutPage && !isCheckoutOrderPage ? null : (
                <div className="bg-white">
                    {/* Mobile menu */}

                    <MobileNavigation
                        isOpen={isOpen}
                        categories={categories}
                        featuredCategories={featuredCategories}
                        staticItems={staticItems}
                        toggleNavi={toggleNavi}
                        categoryUrlSuffix={categoryUrlSuffix}
                        isSignedIn={isSignedIn}
                        handledSignOut={handledSignOut}
                    />

                    <header className="relative bg-white">
                        <div className="border-solid border-slate-200">
                            <div>
                                <div
                                    aria-label="Top"
                                    className="content-container top-0 fixed w-full z-30 bg-white border-b border-solid border-skin-primary lg:hidden"
                                >
                                    {/* Mobile Links */}
                                    <div className="flex justify-between align-center lg:hidden h-[53.5px]">
                                        <div className="flex justify-between items-center align-center lg:hidden">
                                            <div className="block">
                                                <Link href="/" passHref>
                                                    <a aria-label="Opti-Megastore.de">
                                                        <Logo
                                                            className={
                                                                'h-7 xs:h-8'
                                                            }
                                                        />
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex justify-between align-center -mx-2">
                                            <Link href="/login" passHref>
                                                <a className="px-2  text-gray-400 flex flex-col justify-center">
                                                    <UserIconMobile
                                                        className="mx-auto font-normal h-5"
                                                        aria-hidden="true"
                                                    />
                                                </a>
                                            </Link>
                                            {isSignedIn ? (
                                                <span className="px-2  text-gray-400 flex flex-col justify-center">
                                                    <LogoutIconMobile
                                                        className="h-5 w-5"
                                                        arian-hidden="true"
                                                        onClick={handledSignOut}
                                                    />
                                                </span>
                                            ) : null}
                                            <Link href="/wishlist" passHref>
                                                <a className="px-2  text-gray-400 flex flex-col justify-center relative">
                                                    <HeartIcon
                                                        className="mx-auto font-normal h-7"
                                                        aria-hidden="true"
                                                    />
                                                    <WishlistCount
                                                        isMobile={true}
                                                    />
                                                </a>
                                            </Link>
                                            <Link
                                                href="/moebeldiscounter"
                                                passHref
                                            >
                                                <a className="px-2  text-gray-400 flex flex-col justify-center relative">
                                                    <LocationMarkerIcon className="mx-auto font-normal h-5" />
                                                </a>
                                            </Link>
                                            <button
                                                aria-label="Warenkorb"
                                                className="px-2  text-gray-400 flex flex-col justify-center relative"
                                                onClick={async () => {
                                                    router.asPath != '/cart'
                                                        ? router.push('cart')
                                                        : null;
                                                }}
                                            >
                                                <CartIconMobile
                                                    className="mx-auto font-normal h-5"
                                                    aria-hidden="true"
                                                />
                                                <CartProductCount
                                                    isMobile={true}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-container border-t border-solid border-skin-primary lg:border-none px-0 lg:px-5 xl:px-12 mt-[52.5px] lg:mt-0">
                                    <div className="border-b border-solid border-skin-primary lg:hidden">
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-2 flex items-center justify-center">
                                                <button
                                                    aria-label="Menü"
                                                    type="button"
                                                    className=" text-gray-400 content-center"
                                                    onClick={() => toggleNavi()}
                                                >
                                                    <MenuIcon
                                                        className="w-8 h-8 text-[#191919]"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                            <div className="col-span-10 border-l border-solid border-skin-primary px-4">
                                                <SearchBarMobile />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Mobile links */}

                                    {/* Browser links */}
                                    <div className="hidden lg:block">
                                        <div className="flex items-center">
                                            {/* Browser logo */}
                                            <div className="ml-4 flex lg:ml-0 hidden lg:block">
                                                <Link href="/" passHref>
                                                    <a aria-label="Opti-Megastore.de">
                                                        <Logo className="lg:h-14 my-5 cursor-pointer" />
                                                    </a>
                                                </Link>
                                            </div>
                                            {/* End Browser logo */}
                                            <div className="sm:hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                                                <SearchBarDesktop />
                                            </div>
                                            <div className="flex items-center space-x-6">
                                                <div
                                                    className="relative flex"
                                                    onMouseEnter={() =>
                                                        setLoginPopupOpen(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        setLoginPopupOpen(false)
                                                    }
                                                    onClick={() => {
                                                        isSignedIn
                                                            ? router.push(
                                                                  '/account'
                                                              )
                                                            : router.push(
                                                                  '/login'
                                                              );
                                                    }}
                                                >
                                                    <div className="flex cursor-pointer">
                                                        {isSignedIn && (
                                                            <span className="relative top-1 mr-2 text-lg">
                                                                Hallo!
                                                            </span>
                                                        )}
                                                        <UserIcon
                                                            className="h-6 w-6 cursor-pointer hover:text-gray-500"
                                                            aria-hidden="true"
                                                        />
                                                    </div>

                                                    <LoginPopup
                                                        isSignedIn={isSignedIn}
                                                        handledSignOut={
                                                            handledSignOut
                                                        }
                                                        loginPopupOpen={
                                                            loginPopupOpen
                                                        }
                                                        setLoginPopupOpen={
                                                            setLoginPopupOpen
                                                        }
                                                    />
                                                </div>

                                                <Link href="/wishlist">
                                                    <a>
                                                        <div className="relative cursor-pointer">
                                                            <WishlistCount />
                                                            <HeartIcon
                                                                className="h-8 w-8 cursor-pointer hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    </a>
                                                </Link>
                                                <Link href="/moebeldiscounter">
                                                    <a>
                                                        <span>
                                                            <LocationMarkerIcon className="h-6 w-6 cursor-pointer hover:text-gray-500" />
                                                        </span>
                                                    </a>
                                                </Link>
                                                <Link href="/cart">
                                                    <a className="relative cursor-pointer">
                                                        <CartProductCount />
                                                        <CartIcon
                                                            className="h-6 w-6 cursor-pointer hover:text-gray-500"
                                                            aria-hidden="true"
                                                        />
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Browser Links */}
                                </div>
                            </div>
                        </div>
                        {/* Flyout menus */}
                        <div className="bg-skin-red-accent">
                            <nav className="flex items-center content-container relative bg-skin-red-accent">
                                <DesktopNavigation
                                    isOpen={isOpen}
                                    categories={categories}
                                    featuredCategories={featuredCategories}
                                    staticItems={staticItems}
                                    toggleNavi={toggleNavi}
                                    categoryUrlSuffix={categoryUrlSuffix}
                                    loading={loadingNavi}
                                />
                            </nav>
                        </div>
                    </header>
                </div>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        // toggleCart: () => dispatch(toggleDrawer('cart')),
        toggleNavi: () => dispatch(toggleDrawer('navi'))
    };
};

const mapStateToProps = ({
    user: { isSignedIn },
    app: { drawer }
}: mapStateToPropsType) => {
    return {
        isSignedIn,
        drawer
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

interface NavigationPropTypes {
    categories: object;
    loadingNavi: boolean;
    categoryUrlSuffix: string;
    // toggleCart: Function;
    toggleNavi: Function;
    isSignedIn: boolean;
    drawer: string;
    toggleMobileSearch: Function;
    storeConfig: Object;
}

type mapStateToPropsType = {
    user: {
        isSignedIn: boolean;
    };
    app: {
        drawer: string;
    };
};
